import React from 'react'
import { AirnowTheme, AppStyles } from '../theme'
import Header from './Header'
import CssBaseline from '@material-ui/core/CssBaseline';
import Error from './Error'
import Notification from './Notification'

import DesktopNav from './DesktopNav'
import Nav from './Nav'
import ProfileMenu from '../profile/ProfileMenu'

export default class App extends React.Component {

  render() {
    let navSection;
    if (this.props.location.pathname === '/passwordreset' || this.props.location.pathname === '/signin') {
      //Do nothing
    }
    else
      navSection =
        <div>
          <DesktopNav />
          <Nav />
          <ProfileMenu />
        </div>

    return (
      <AirnowTheme>
        <div style={AppStyles.container} >
          <CssBaseline />
          <Header location={this.props.location} />
          {navSection}
          <main style={AppStyles.content}>
            {this.props.main}
            {this.props.children}
            <div style={AppStyles.end} />
          </main>
          <Error />
          <Notification />
        </div>
      </AirnowTheme>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////