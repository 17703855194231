import React from 'react'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

export default class extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      stateSearch: props.search,
      timeout: null,
    }
  }

  render() {
    // const { stateSearch } = this.state
    const { search, onChange, onSubmit } = this.props

    return (
      <Paper elevation={4}>
        <form onSubmit={onSubmit}>
          <TextField
            style={{ width: '99%' }}
            placeholder={'Search (e.g. hanko sail fi cargo fsc>0.1 >20.9.2016 <21.9.2016T20:00 =20.9.2016 1.5.2016...30.8.2016 -bypass ~fjard sample fuelsample -scrubber)'}
            value={search}
            onChange={(event) => {
              onChange(event.target.value)
            }}
          />
          <input type='submit' style={{ display: 'none' }} />
        </form>
      </Paper>
    )
  }
}
