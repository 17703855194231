import React from 'react'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { browserHistory } from 'react-router'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Toolbar from '../core/Toolbar'

import { CookieDisclaimer } from '../home/CookieDisclaimer'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'

//////////////////////////////////////////////////////////////////////////////

const onNavigate = pathname => browserHistory.push(pathname)

export class Admin extends React.Component {
  render() {
    return (
      <div>
        <CookieDisclaimer />
        <Toolbar title='Admin' />
        <Paper elevation={4}>
          <List >
            <ListItem button onClick={() => onNavigate('/admin/users')}>
              <ListItemIcon>{<AccountBoxIcon />}</ListItemIcon>
              <ListItemText primary={'Users'}/>
            </ListItem>
          </List>
        </Paper>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)

//////////////////////////////////////////////////////////////////////////////
