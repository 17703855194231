const notificationState = { text: '' }

export default (state = notificationState, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return { text: action.text || '' }
    case 'HIDE_NOTIFICATION':
      return { text: '' }
    default:
      return state
  }
}
