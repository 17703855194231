import React from 'react'

import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

import ForwardIcon from '@material-ui/icons/Forward'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import CollapsePaper from '../core/CollapsePaper'

export default class ThenSendBlock extends React.Component {
  constructor(props) {
    super(props)
    this.updating = false
    this.state = {
      send: this.props.trigger.action.send,
    }
  }
  componentDidUpdate() {
    if (this.updating) {
      return
    }
    const { onChange, trigger } = this.props
    const conditionType = Object.keys(trigger.condition).filter(n => n !== 'enabled' && n !== 'includeDuplicates')[0]
    let send = this.state.send
    switch (conditionType) {
      case 'match': {
        break
      }
      case 'time': {
        send = send.filter(n => n.split(':')[0] === 'mailto')
        this.setState({ send })
        break
      }
      case 'firstDayOfMonth': {
        send = send.filter(n => n.split(':')[0] === 'mailto')
        this.setState({ send })
        break
      }
      default: {
        break;
      }
    }

    this.updating = true
    onChange(send)
    setTimeout(() => this.updating = false, 0)
  }
  render() {
    // const condition = this.props.trigger.condition
    // const conditionType = Object.keys(condition).filter(n => n !== 'enabled' && n !== 'includeDuplicates')[0]

    const { send } = this.state

    const icon = <ForwardIcon />
    const text = send.length > 0
      ? <span><span>Then send to </span>{send.map(n => n.split(':')[1] || '...').join(' / ')}</span>
      : <span><span>Then send to </span>...</span>

    return (
      <CollapsePaper
        icon={icon}
        text={text}>
        {send.map((n, i) => {
          const type = n.split(':')[0]
          const value = n.split(':')[1]
          return (
            <form key={i} onSubmit={(event) => event.preventDefault()}>
              <IconButton disabled={true}>
                {type === 'tel' ? <PhoneIcon /> : <EmailIcon />}
              </IconButton>
              <TextField
                id={type + i}
                value={value}
                onChange={(ev) => {
                  send[i] = type + ':' + ev.target.value
                  this.setState({ send })
                }}
                type={type}
              />
              <IconButton onClick={() => {
                send.splice(i, 1)
                this.setState({ send })
              }}>
                <ClearIcon />
              </IconButton>
            </form>
          )
        })}
        <br />
        <Button variant="contained" startIcon={<AddIcon />} color="primary" disabled='true' onClick={() => {
          this.setState({ send: send.concat('tel:+358') })
        }} >
          {"Add Phone Number"}
        </Button>
        <Button variant="contained" startIcon={<AddIcon />} color="primary" onClick={() => {
          this.setState({ send: send.concat('mailto:') })
        }} >
          {"Add E-Mail Address"}
        </Button>
      </CollapsePaper>
    )
  }
}
