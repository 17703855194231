import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone'
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles';

import MaintenanceEditHeader from './MaintenanceEditHeader'

import Toolbar from '../core/Toolbar'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotification } from '../app/AppActions'
import { closeBackDialog, openBackDialog } from './MaintenanceActions'
import { browserHistory } from 'react-router'
import { maintenance } from '../api'

//////////////////////////////////////////////////////////////////////////////

moment.locale("en")
moment.updateLocale('en', {
  week: { dow: 1, doy: 0 }
});

function convertToDate(date) {
  const res = new Date(moment.tz(date, "Europe/Helsinki").format())
  return res
}

function convertFromDate(date, orig) {
  const res1 = moment.tz(date.toISOString(), "Europe/Helsinki").format().substr(0, 10)
  const res2 = moment.tz(orig.toISOString(), "Europe/Helsinki").format().substr(11)
  return res1 + 'T' + res2
}

function convertFromTime(time, orig) {
  const res1 = moment.tz(orig.toISOString(), "Europe/Helsinki").format().substr(0, 10)
  const res2 = moment.tz(time.toISOString(), "Europe/Helsinki").format().substr(11)
  return res1 + 'T' + res2
}

const backDialog = (backDialogOpen, onConfirmGoBack, onCloseBackDialog) => {
  return (
    <Dialog open={backDialogOpen} onClose={onCloseBackDialog}>
      <DialogTitle>{'Unsaved changes'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          All unsaved changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseBackDialog}>Cancel</Button>
        <Button onClick={() => onConfirmGoBack()}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

class MaintenanceEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startedAt: convertFromDate(new Date(), new Date()),
      endedAt: convertFromDate(new Date(), new Date()),
      title: '',
      description: '',
      tags: [],
    }
  }
  componentDidMount() {
    this.props.onSetRecord(this.props.params.id)
      .then(record => this.setState(record))
  }

  render() {
    const { id } = this.props.params
    const { backDialogOpen, onConfirmGoBack, onCloseBackDialog, onGoBack, classes } = this.props
    const { modified, startedAt, endedAt, title, description, tags } = this.state

    const titleText = id == null ? 'Create maintenance record' : 'Edit maintenance record'

    return (
      <div>
        <Toolbar title={titleText} onGoBack={() => onGoBack(modified)}>
          <MaintenanceEditHeader id={id} record={this.state} />
        </Toolbar>
        {backDialog(backDialogOpen, onConfirmGoBack, onCloseBackDialog)}
        <Paper elevation={4} >
          <List subheader={<ListSubheader>{"Maintenance record"}</ListSubheader>}>
            {<form onSubmit={event => event.preventDefault()}>
              <div className={classes.textFieldWrapper}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <DatePicker
                    id='startedAtDate'
                    className={classes.datePicker}
                    views={["year", "month", "date"]}
                    showTodayButton
                    fullWidth={true}
                    label='Started At'
                    format="DD.MM.YYYY"
                    minDate={convertToDate('2016-06-01')}
                    maxDate={new Date()}
                    value={convertToDate(startedAt)}
                    onChange={(date) => {
                      this.setState({ startedAt: convertFromDate(date, convertToDate(startedAt)) })
                    }}
                  />
                  <TimePicker
                    ampm={false}
                    label=" "
                    value={convertToDate(startedAt)}
                    className={classes.timePicker}
                    onChange={(time) => this.setState({
                      startedAt: convertFromTime(time, convertToDate(startedAt))
                    })} />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.textFieldWrapper}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <DatePicker
                    id='endedAtDate'
                    className={classes.datePicker}
                    views={["year", "month", "date"]}
                    showTodayButton
                    label='Ended At'
                    format="DD.MM.YYYY"
                    minDate={convertToDate('2016-06-01')}
                    maxDate={new Date()}
                    value={convertToDate(endedAt)}
                    onChange={(date) => {
                      this.setState({ endedAt: convertFromDate(date, convertToDate(endedAt)) })
                    }}
                  />
                  <TimePicker
                    ampm={false}
                    label=" "
                    value={convertToDate(endedAt)}
                    className={classes.timePicker}
                    onChange={(time) => this.setState({
                      endedAt: convertFromTime(time, convertToDate(endedAt))
                    })} />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.textFieldWrapper}>
                <TextField
                  id='title'
                  label='Title'
                  fullWidth={true}
                  value={title}
                  onChange={event => {
                    this.setState({ title: event.target.value, modified: true })
                  }}
                  type='text'
                />
              </div>
              <div className={classes.textFieldWrapper}>
                <TextField
                  id='description'
                  label='Description'
                  multiline
                  rowsMax={3}
                  fullWidth={true}
                  value={description}
                  onChange={event => {
                    this.setState({ description: event.target.value, modified: true })
                  }}
                  type='text'
                />
              </div>
              <div className={classes.textFieldWrapper}>
                <TextField
                  id='tags'
                  label='Tags'
                  fullWidth={true}
                  value={tags.join(' ')}
                  onChange={event => {
                    this.setState({ tags: event.target.value.split(' '), modified: true })
                  }}
                  type='text'
                />
              </div>
            </form>}
          </List>
        </Paper>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    backDialogOpen: state.maintenance.backDialogOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetRecord: id => {
      if (id == null) {
        let record = {
          startedAt: convertFromDate(new Date(), new Date()),
          endedAt: convertFromDate(new Date(), new Date()),
          title: '',
          description: '',
          tags: [],
        }
        return Promise.resolve(record)
      } else {
        return maintenance.get(id)
          .catch(err => dispatch(showNotification(err.message)))
      }
    },
    onConfirmGoBack: () => {
      dispatch(closeBackDialog())
      browserHistory.push('/maintenance')
    },
    onCloseBackDialog: () => {
      dispatch(closeBackDialog())
    },
    onGoBack: (modified) => {
      if (modified) {
        dispatch(openBackDialog())
      } else {
        browserHistory.push('/maintenance')
      }
    },
  }
}

const styles = theme => ({
  textFieldWrapper: theme.textFieldWrapper,
  datePicker: {
    width: theme.spacing(12) //'7rem' 
  },
  timePicker: {
    marginLeft: theme.spacing(2), // '1rem',
    width: theme.spacing(8) // '4rem'
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MaintenanceEdit))

//////////////////////////////////////////////////////////////////////////////
