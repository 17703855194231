import React from 'react'

import startCase from 'lodash.startcase'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'

import Toolbar from '../core/Toolbar'

import AdminUserEditHeader from './AdminUserEditHeader'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotification } from '../app/AppActions'
import { setEditUser, closeBackDialog, openBackDialog } from './AdminActions'
import { browserHistory } from 'react-router'
import { users, triggers } from '../api'

//////////////////////////////////////////////////////////////////////////////

const Organizations = [
  {
    value: 'KINE',
    label: 'KINE'
  },
  {
    value: 'Public',
    label: 'Public'
  }
]

const roleItems = [
  {
    name: 'user',
    description: 'View reports, Manage triggers',
  },
  {
    name: 'data-writer',
    description: 'Write raw data',
  },
  {
    name: 'report-writer',
    description: 'Write reports and samples',
  },
  {
    name: 'data-reader',
    description: 'Read raw data and reports'
  },
  {
    name: 'technician',
    description: 'Manage maintenance records',
  },
  {
    name: 'admin',
    description: 'Manage users',
  },
  {
    name: 'superadmin',
    description: 'All capabilities',
  },
]

const KINEonlyRoles = roleItems.filter(r => r.name !== 'user')
  .map(r => r.name)

const backDialog = (backDialogOpen, onConfirmGoBack, onCloseBackDialog) => {
  return (
    <Dialog open={backDialogOpen} onClose={onCloseBackDialog}>
      <DialogTitle>{'Unsaved changes'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          All unsaved changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseBackDialog}>Cancel</Button>
        <Button onClick={() => onConfirmGoBack()} >OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export class UsersEdit extends React.Component {

  componentDidMount() {
    this.props.onSetUser(this.props.params.id, this.props.user)
  }

  GeneralSection = (user, editUser, classes, onUpdateUser) => {
    const { email, name, organization } = editUser
    return (
      <form onSubmit={event => event.preventDefault()}>
        <Typography variant={'subtitle1'} >
          <List ></List>
        </Typography>
        <div className={classes.textFieldWrapper}>
          <TextField
            id='email'
            disabled={editUser._id != null || editUser.oauth}
            label='E-mail'
            fullWidth={true}
            value={email}
            onChange={e => {
              editUser.email = e.target.value;
              editUser.modified = true;
              onUpdateUser(editUser);
            }}
            type='email'
          />
        </div>
        <div className={classes.textFieldWrapper} >
          <TextField
            id='name'
            disabled={editUser.oauth}
            label='Name'
            fullWidth={true}
            defaultValue={name}
            onChange={e => {
              editUser.name = e.target.value;
              editUser.modified = true;
              onUpdateUser(editUser);
            }}
            type='text'
          />
        </div>
        <div
          className={classes.textFieldWrapper}
        >
          <TextField
            id="organization"
            disabled={((user.roles && !user.roles.includes('superadmin')) || editUser.roles.includes('superadmin')) || editUser.oauth}
            select
            label="Organization"
            fullWidth={true}
            value={organization}
            type='text'
            onChange={(value, child) => {
              editUser.organization = child.key;
              editUser.modified = true;
              onUpdateUser(editUser);
            }}
          >
            {Organizations.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </form>
    )
  }

  roles = (user, editUser) => {
    let items = roleItems.slice()
    if (user.roles) {
      if (!user.roles.includes('superadmin')) {
        items = items.filter(item => item.name !== 'superadmin')
      } else if (user.roles.includes('superadmin') && user._id === editUser._id) {
        items = items.filter(item => item.name === 'superadmin')
      }
    }

    if (user.organization !== 'KINE' || editUser.organization === 'Public') {
      items = items.filter(item => !KINEonlyRoles.includes(item.name))
    }

    if (editUser.organization === 'Public') {
      editUser.roles = editUser.roles.filter(role => !KINEonlyRoles.includes(role))
    }

    return (
      <List subheader={<ListSubheader>Roles</ListSubheader>}>
        {items.map(role => {
          const disabled = user.email === editUser.email && user.roles && user.roles.includes('superadmin') && role.name === 'superadmin'

          let checkBox = <FormControl disabled={disabled}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={editUser.roles.includes(role.name)}
                  value={role.name}
                  onChange={value => {
                    const roles = editUser.roles.slice()
                    if (value.target.checked) {
                      roles.push(role.name)
                    } else {
                      roles.splice(roles.indexOf(role.name), 1);
                    }
                    editUser.roles = roles
                    editUser.modified = true
                    this.forceUpdate();
                  }}
                  label={startCase(role.name)}
                />}
            />
          </FormControl>

          return (
            <ListItem key={role.name} >
              <ListItemIcon>{checkBox}</ListItemIcon>
              <ListItemText primary={startCase(role.name)} secondary={role.description} />
            </ListItem>
          )
        })}
      </List>
    )
  }


  render() {
    const { user, editUser, backDialogOpen, onConfirmGoBack, onCloseBackDialog, onGoBack, onUpdateUser, classes } = this.props

    if (user.email == null || editUser.email == null) {
      return (
        <div />
      )
    }

    var toolBarTitle = editUser._id == null ? 'Create user' : 'Edit user'
    return (
      <div>
        <Toolbar title={toolBarTitle}
          onGoBack={() => onGoBack(editUser.modified)}>
          {backDialog(backDialogOpen, onConfirmGoBack, onCloseBackDialog)}
          <AdminUserEditHeader />
        </Toolbar>
        <Paper elevation={4}>
          {this.GeneralSection(user, editUser, classes, onUpdateUser)}
          <Divider />
          {this.roles(user, editUser)}
          <Divider />
        </Paper>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////


const mapStateToProps = (state) => {
  return {
    user: state.user,
    editUser: state.admin.editUser,
    backDialogOpen: state.admin.backDialogOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUser: (id) => {
      users.get()
        .then(result => {
          const user = result.user;
          dispatch(setEditUser({}))

          if (id == null) {
            setTimeout(() => {
              let newUser = {
                email: '',
                password: Math.random().toString(36).slice(2),
                roles: ['user'],
                name: '',
                organization: user.organization,
                showExtraInfo: false,
                triggers: [],
              }
              dispatch(setEditUser(newUser))
            }, 0)
          } else {
            users.get(id)
              .then(editUser => {
                triggers.query({ userId: id })
                  .then(triggerResult => {
                    editUser.triggers = triggerResult.data
                    dispatch(setEditUser(editUser))
                  })
                  .catch(err => dispatch(showNotification(err.message)))
              })
              .catch(err => dispatch(showNotification(err.message)))
          }

        })
        .catch(err => dispatch(showNotification(err.message)))
    },
    onUpdateUser: (user) => {
      dispatch(setEditUser(user))
    },
    onConfirmGoBack: () => {
      dispatch(closeBackDialog())
      browserHistory.push('/admin/users')
    },
    onCloseBackDialog: () => {
      dispatch(closeBackDialog())
    },
    onGoBack: (modified) => {
      if (modified) {

        dispatch(openBackDialog())
      } else {
        browserHistory.push('/admin/users')
      }
    },
  }
}

const styles = theme => ({
  textFieldWrapper: theme.textFieldWrapper
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersEdit))

//////////////////////////////////////////////////////////////////////////////
