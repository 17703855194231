const reportsState = {
  total: 0,
  limit: 0,
  skip: 0,
  data: [],
  stats: {
    total: 0,
    bypass: 0,
    duplicate: 0,
    measured: 0,
    invoiced: 0,
    undetermined: 0
  },
  query: {
    $sort: { time: -1 },
  },
  filters: [],
  sort: {
    field: 'time',
    direction: -1,
  },
  report: null,
  editReport: null,
  save: {
    saveInput: '',
    saveDialogOpen: false,
  },
  showSearches: false,
  searches: [],
  searchId: null,
  searchDelete: {
    deleteId: null,
    deleteName: '',
  },
  history: null,
  reportSamples: []
}

export default (state = reportsState, action) => {
  switch (action.type) {
    case 'SET_REPORTS':
      return Object.assign({}, state, action.reports);
    case 'SET_QUERY':
      return Object.assign({}, state, { query: action.query })
    case 'SET_FILTERS':
      return Object.assign({}, state, { filters: action.filters });
    case 'SET_SORT':
      return Object.assign({}, state, { sort: action.sort });
    case 'SET_REPORT':
      return Object.assign({}, state, { report: action.report });
    case 'OPEN_SAVE_DIALOG':
      return Object.assign({}, state, Object.assign(state.save, { saveInput: '', saveDialogOpen: true }));
    case 'CLOSE_SAVE_DIALOG':
      return Object.assign({}, state, Object.assign(state.save, { saveDialogOpen: false }));
    case 'SET_SHOW_SEARCHES':
      return Object.assign({}, state, { showSearches: action.showSearches });
    case 'CHANGE_SAVE_INPUT':
      return Object.assign({}, state, Object.assign(state.save, { saveInput: action.saveInput }));
    case 'SET_SEARCHES':
      return Object.assign({}, state, { searches: action.searches });
    case 'SELECT_SEARCH':
      return Object.assign({}, state, { searchId: action.searchId });
    case 'SET_SEARCH_DELETE':
      return Object.assign({}, state, Object.assign(state.searchDelete, { deleteId: action.deleteId, deleteName: action.deleteName }));
    case 'SET_HISTORY':
      return Object.assign({}, state, { history: action.history })
    case 'SET_REPORT_SAMPLES':
      return Object.assign({}, state, { reportSamples: action.samples })
    default:
      return state;
  }
}
