import startCase from 'lodash.startcase'

import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import Toolbar from '../core/Toolbar'

import AddIcon from '@material-ui/icons/Add'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'

import base64 from '../core/base64'
import transformKey from '../core/transformKey'

import { CookieDisclaimer } from '../home/CookieDisclaimer'

//////////////////////////////////////////////////////////////////////////////

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { showNotification } from '../app/AppActions'
import { setTriggers } from './TriggerActions'
import { triggers } from '../api'

//////////////////////////////////////////////////////////////////////////////

export const getTriggerType = (trigger) => {
  const type = Object.keys(trigger.condition).filter(n => n !== 'enabled' && n !== 'includeDuplicates')[0]
  if (type) return type;
  return "match";
}


function formatQuery(query) {
  const filters = Object.keys(query).filter(key => !key.startsWith('$') && key !== "mmsi").map(key => {
    const item = query[key]
    
    let parts = []

    if (item.$in) {
      parts.push(<span>{(item.$in.length > 1 ? <span>in </span> : <span />)}{item.$in.join(' / ')}</span>)
    }
    if (item.$nin) {
      parts.push(<span>{(item.$nin.length > 1 ? <span>not in </span> : <span>not </span>)}{item.$nin.join(' / ')}</span>)
    }
    if (item.$lt) {
      parts.push(<span><span>less than </span>{item.$lt}</span>)
    }
    if (item.$gt) {
      parts.push(<span><span>greater than </span>{item.$gt}</span>)
    }

    return <span>{transformKey(key)}<span> is </span>{parts.length > 1 ? parts.reduce((x, y) => <span>{x}<span> and </span>{y}</span>) : parts[0]}</span>
  })
  return <span>{filters.length === 0 ? '...' : filters.reduce((x, y) => <span>{x}<span> and </span>{y}</span>)}</span>
}

const triggerList = (triggers, onSelectTrigger, user) => {
  return (
    <List >
      {triggers.length === 0 ? <ListItem disabled={true} ><ListItemText primary='No triggers.'/></ListItem> : null}
      {triggers.map(trigger => {
        let leftIcon, primaryText

        const filters = JSON.parse(base64.decode(trigger.action.filter))
        const excludedCount = filters.mmsi ? filters.mmsi.$nin.length : 0
        const excludedText = excludedCount === 0 ? "" :
                            excludedCount < 2 ? " excluding 1 vessel " : ` excluding ${excludedCount} vessels `

        //includeDuplicates is a misnomer:
        //duplicates are always included, but this governs the option about adding the marking 'duplicate' or 'valid' on each entry
        const textIncludeDuplicates = user.organization === 'KINE' ? (trigger.condition.includeDuplicates ? "(mark duplicates) " : "") : "";
        let textIncludeDuplicatesAndEnabled = textIncludeDuplicates + excludedText + (trigger.condition.enabled ? "[ENABLED]" : "[DISABLED]");
        
        const type = getTriggerType(trigger)
        switch (type) {
          case 'match': {
            leftIcon = <TrackChangesIcon />
            const filter = JSON.parse(base64.decode(trigger.action.filter))
            primaryText = <span><span>If </span>{formatQuery(filter)} {textIncludeDuplicatesAndEnabled}</span>
            
            break;
          }
          case 'time': {
            const weekdays = []
            
            const days = trigger.condition.time && trigger.condition.time.days ? trigger.condition.time.days : []
            if (days.includes(1)) {
              weekdays.push('Mon')
            }
            if (days.includes(2)) {
              weekdays.push('Tue')
            }
            if (days.includes(3)) {
              weekdays.push('Wed')
            }
            if (days.includes(4)) {
              weekdays.push('Thu')
            }
            if (days.includes(5)) {
              weekdays.push('Fri')
            }
            if (days.includes(6)) {
              weekdays.push('Sat')
            }
            if (days.includes(0)) {
              weekdays.push('Sun')
            }
            
            const hours = trigger.condition.time.hours
            
            leftIcon = <AccessTimeIcon />
            primaryText = <span><span>If </span>Weekday<span>{weekdays.length > 1 ? ' is in ' : ' is '}</span>{weekdays.length > 0 ? weekdays.join(' / ') : '...'}<span> and </span>Time<span> is </span>{hours} {textIncludeDuplicatesAndEnabled}</span>
            break
          }
          case 'firstDayOfMonth': {
            const hours = trigger.condition.firstDayOfMonth.hours
            
            leftIcon = <AccessTimeIcon />
            primaryText = <span><span>If </span>First Day Of Month<span> and </span>Time<span> is </span>{hours} {textIncludeDuplicatesAndEnabled}</span>
            break
          }
          default:
            break;
        }
        
        let take = <span><span>TAKE </span>{startCase(trigger.action.take)}</span>
        let send = trigger.action.send.length > 0
          ? <span><span> AND SEND TO </span>{trigger.action.send.map(n => n.split(':')[1]).join(' / ')}</span>
          : null
        
        
        return (
          <ListItem
            button
            key={trigger._id}
            onClick={() => onSelectTrigger(trigger)}
          >
            <ListItemIcon>{leftIcon}</ListItemIcon>
            <ListItemText
              primary={<small>{primaryText}{(trigger.user ? ' / ' + (trigger.user.name || trigger.user.google.fullName) : '')}</small>}
              secondary={<small>THEN {take}{send}</small>}
              />
          </ListItem>
        )
      })}
    </List>
  )
}

class Trigger extends React.Component {
  constructor(props) {
    super(props)
    this.page = () => {
      const { query } = this.props.location
      return query && query.page ? parseInt(query.page) : 1
    }
  }
  componentDidMount() {
    this.props.onFetchTriggers(this.page())
  }
  render() {
    const { triggers, onSelectTrigger, onAddTrigger, user } = this.props

    return (
      <div>
        <CookieDisclaimer />
        <Toolbar title='Triggers'>
          <Button onClick={onAddTrigger} startIcon={<AddIcon />}>
            Add trigger
          </Button>
        </Toolbar>
        <Paper elevation={4}>
          {triggerList(triggers.data, onSelectTrigger, user)}
        </Paper>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    triggers: state.trigger.triggers,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectTrigger: (trigger) => {
      browserHistory.push('/triggers/' + trigger._id)
    },
    onFetchTriggers: (page) => {
      triggers.list(page)
        .then(result => dispatch(setTriggers(result)))
        .catch(err => dispatch(showNotification(err.message)))
    },
    onAddTrigger: () => {
      browserHistory.push('/triggers/new')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trigger)

//////////////////////////////////////////////////////////////////////////////
