import React from 'react'
import Paper from '@material-ui/core/Paper'
import Pager from './TablePager'
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableHeader from './TableHeader'
import TableBody from './TableBody'

import { withStyles } from '@material-ui/core/styles'


class CustomTable extends React.Component {

  render() {
    const { columns, data, page, total, limit, hidePager, onChangePage, onSelect, depth, disabledColumns, size, classes } = this.props
    const tableCellClassName = size === "custom" ?  classes.customTableCell : null;

    return (
      <Paper elevation={depth}>
        {hidePager == null ? <span><Pager page={page} total={total} limit={limit} onChangePage={onChangePage} /><Divider /></span> : <span />}
        <div style={{ overflow: "auto"}}>
          <Table stickyHeader={false} style={{tableLayout: 'auto'}} size={size === "medium" ? size : "small"}>
            {TableHeader({ columns, tableCellClassName })}
            {TableBody({ columns, data, onSelect, disabledColumns, tableCellClassName })}
          </Table>
        </div>
      </Paper>
    )
  }
}

const styles = theme => ({
    customTableCell: {
      lineHeight: "2rem",
      [theme.breakpoints.down('xs')]: {
        paddingRight: "0rem"
      }
    }
})

export default withStyles(styles)(CustomTable)