import React from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core//Divider'
import Button from '@material-ui/core/Button'
import ContentAdd from '@material-ui/icons/Add'
import ContentSort from '@material-ui/icons/Sort'
import ContentClear from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Done'
import { withStyles } from '@material-ui/core/styles';

import FilterItem from './FilterItem'
import { $lt, $gt } from './FilterOperators'
import operators from './FilterOperators'

const numericTypes = ['date', 'time', 'number']

function isFieldDisabled(field, items) {
  const a = numericTypes.includes(field.type)
  const b = items.filter(item => item.field.key === field.key).length >= 2
  return a && b
}

function firstAvailableField(fields, items) {
  return fields.filter(field => !isFieldDisabled(field, items))[0]
}

function isOperatorAvailable(operator, field, items) {
  if (!operator.types.includes(field.type)) {
    return false
  }
  if (numericTypes.includes(field.type)) {
    return items.filter(item => item.field.key === field.key && item.operator.key === operator.key).length === 0
  }
  return true
}

function firstAvailableOperator(field, items) {
  return operators.filter(operator => isOperatorAvailable(operator, field, items))[0]
}

function cloneItems(items) {
  return items.map(item => Object.assign({}, item))
}

function getDefaultValue(field) {
  return field.type === 'date' ? new Date().toISOString().substr(0, 10) : field.values.length > 0 ? field.values[0] : ''
}

function fieldEquals(a, b) { return a.key === b.key }
function operatorEquals(a, b) { return a.key === b.key }
function itemEquals(a, b) { return a === b }

class Filter extends React.Component {

  render() {

    const { fields, onChange, onApplyFilters, classes } = this.props
    const { filters, sort, search, filtersChanged } = this.props

    const controlledApplying = Boolean(this.props.controlledApplying)

    let sortComponent
    if (sort) {
      sortComponent =
        <Button
          startIcon={<ContentSort className={`${classes.sort} ${sort === 1 ? "" : classes.sortDesc}`}/>}
          color='primary'
          onClick={() => {
            const selectedSort = sort === 1 ? -1 : 1
            onChange(filters, selectedSort, search)
          }}>
          {`Sort By Time: ${sort === 1 ? 'Ascending' : 'Descending'}`}
        </Button>
    }

    return (
      <Paper elevation={4} >
        <Button
          startIcon={<ContentAdd />}
          color='primary'
          disabled={filters.length >= 8}
          onClick={() => {
            const newItems = cloneItems(filters)
            const field = firstAvailableField(fields,filters)
            newItems.push({ field, operator: firstAvailableOperator(field, filters), value: getDefaultValue(field) })
            onChange(newItems, sort, search)
          }} >
          {'Add Filter'}
        </Button>
        <Button
            disabled={filters.length === 0 && search === ""}
            startIcon={<ContentClear />}
            color='primary'
            onClick={() => {onChange([], sort, "")}}
          >Clear filters
          </Button>

        {sortComponent}

        <Divider />

        {filters.map((item, i) => {
          return (
            <FilterItem
              key={i}
              fields={fields.map(field => {
                return Object.assign({}, field, { disabled: isFieldDisabled(field, filters) })
              })}
              operators={operators.filter(operator => operator.types.includes(item.field.type)).map(operator => {
                if (operatorEquals(operator, $lt)) {
                  return Object.assign({}, operator, {
                    disabled: operatorEquals(item.operator, $gt)
                      && filters.filter(l => fieldEquals(l.field, item.field)
                        && operatorEquals(l.operator, $lt)).length > 0,
                  })
                }
                if (operatorEquals(operator, $gt)) {
                  return Object.assign({}, operator, {
                    disabled: operatorEquals(item.operator, $lt)
                      && filters.filter(l => fieldEquals(l.field, item.field)
                        && operatorEquals(l.operator, $gt)).length > 0,
                  })
                }
                return operator
              })}
              field={item.field}
              operator={item.operator}
              value={item.value}
              onChangeField={m => {
                const newItems = cloneItems(filters)
                const newItem = newItems[i]
                newItem.field = m.field != null ? m.field : newItem.field
                const operator = isOperatorAvailable(m.operator, newItem.field, newItems)
                  ? m.operator
                  : operators.filter(l => isOperatorAvailable(l, newItem.field, newItems.filter(k => !itemEquals(newItem, k))))[0]
                newItem.operator = operator != null ? operator : newItem.operator
                newItem.value = getDefaultValue(newItem.field)
                onChange(newItems, sort, search)
              }}
              onChangeOperator={m => {
                const newItems = cloneItems(filters)
                const newItem = newItems[i]
                const operator = isOperatorAvailable(m.operator, newItem.field, newItems)
                  ? m.operator
                  : operators.filter(l => isOperatorAvailable(l, newItem.field, newItems.filter(k => !itemEquals(newItem, k))))[0]
                newItem.operator = operator != null ? operator : newItem.operator
                onChange(newItems, sort, search)
              }}
              onChangeValue={m => {
                const newItems = cloneItems(filters)
                const newItem = newItems[i]
                newItem.value = m.value != null ? m.value : getDefaultValue(newItem.field)
                onChange(newItems, sort, search)
              }}
              onRemove={() => {
                const newItems = cloneItems(filters)
                newItems.splice(i, 1)
                onChange(newItems, sort, search)
              }}
            />
          )
        })}
        <Divider />
        {controlledApplying &&
          <Button
            className={classes.applyButton}
            disabled={!filtersChanged}
            startIcon={<DoneIcon />}
            variant={filtersChanged ? "contained" : "text"}
            color='primary'
            onClick={() => {
              onApplyFilters(filters, sort, search)
            }}
          >Apply filters
          </Button>}
      </Paper>
    )
  }
}

const styles = theme => ({
  applyButton: {
    margin: theme.spacing(1)*1.5
  },
  sort: {
    transform: 'scaleY(-1)',
    transition: 'all 200ms ease'
  },
  sortDesc: {
    transform: 'scaleY(1)',
  }
})

export default withStyles(styles)(Filter)