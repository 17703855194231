import startCase from 'lodash.startcase'

import React from 'react'

import Button from '@material-ui/core/Button'
import ForwardIcon from '@material-ui/icons/Forward'

import CollapsePaper from '../core/CollapsePaper'

export default class ThenTakeBlock extends React.Component {
  constructor(props) {
    super(props)
    this.updating = false
    this.state = {
      takeType: this.props.trigger.action.take,
    }
  }
  componentDidUpdate() {
    if (this.updating) {
      return
    }
    const { onChange, trigger } = this.props
    const conditionType = Object.keys(trigger.condition).filter(n => n !== 'enabled' && n !== 'includeDuplicates')[0]
    let takeType = this.state.takeType
    switch (conditionType) {
      case 'match': {
        if (this.state.takeType !== 'last-match') {
          takeType = 'last-match'
          this.setState({ takeType })
        }
        break
      }
      case 'time': {
        if (this.state.takeType !== 'all' && this.state.takeType !== 'last-week' && this.state.takeType !== 'last-day') {
          takeType = 'last-day'
          this.setState({ takeType })
        }
        break
      }
      case 'firstDayOfMonth': {
        if (this.state.takeType !== 'all' && this.state.takeType !== 'last-month') {
          takeType = 'last-month'
          this.setState({ takeType })
        }
        break
      }
      default: {
        break;
      }
    }

    this.updating = true
    onChange(takeType)
    setTimeout(() => this.updating = false, 0)
  }
  render() {
    const { takeType } = this.state

    const condition = this.props.trigger.condition
    const conditionType = Object.keys(condition).filter(n => n !== 'enabled' && n !== 'includeDuplicates')[0]

    const icon = <ForwardIcon />
    const text = <span><span>Then take </span>{startCase(takeType)}</span>

    return (
      <CollapsePaper
        icon={icon}
        text={text}
      >
        <Button variant="contained" color={takeType === 'last-day' ? 'primary' : 'default'} disabled={conditionType === 'match' || conditionType === 'firstDayOfMonth'} onClick={
          () => {
            const type = 'last-day'
            this.setState({ takeType: type })
          }
        } >
          Last Day
            </Button>
        <Button variant="contained" color={takeType === 'last-week' ? 'primary' : 'default'} disabled={conditionType === 'match' || conditionType === 'firstDayOfMonth'} onClick={
          () => {
            const type = 'last-week'
            this.setState({ takeType: type })
          }
        } >
          Last Week
              </Button>
        <Button variant="contained" color={takeType === 'last-month' ? 'primary' : 'default'} disabled={conditionType === 'match' || conditionType === 'time'} onClick={
          () => {
            const type = 'last-month'
            this.setState({ takeType: type })
          }
        } >
          Last Month
              </Button>
        <Button variant="contained" color={takeType === 'all' ? 'primary' : 'default'} disabled={conditionType === 'match'} onClick={
          () => {
            const type = 'all'
            this.setState({ takeType: type })
          }
        } >
          All
              </Button>
      </CollapsePaper>
    )
  }
}
