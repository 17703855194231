import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

export default (props) => {
  const { backDialogOpen, onConfirmGoBack, onCloseBackDialog } = props
  var { title, contentText, confirmButtonText } = props
  if (!title) title = "Unsaved changes"
  if (!contentText) contentText = "All unsaved changes will be lost."
  if (!confirmButtonText) confirmButtonText = "OK"
  
    return (
      <Dialog open={backDialogOpen} onClose={onCloseBackDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseBackDialog}>Cancel</Button>
          <Button onClick={() => onConfirmGoBack()}>{confirmButtonText}</Button>
        </DialogActions>
      </Dialog>
    )
  }