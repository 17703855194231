import React from 'react'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider'

import ActionAccountBox from '@material-ui/icons/AccountBox';
import ActionExitToApp from '@material-ui/icons/ExitToApp';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { closeProfileMenu, showNotification, deauthUser } from '../app/AppActions'
import { users } from '../api'

//////////////////////////////////////////////////////////////////////////////

export const ProfileMenu = ({ user, profileOpen, onCloseProfile, anchorEl, onNavigate, onSignOut }) => {

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={profileOpen}
            onClose={onCloseProfile}
        >
            <MenuItem disabled={true} style={{opacity: 1}}>
                <ListItemText primary={"Signed in as:"} />
            </MenuItem>
            <MenuItem disabled={true} style={{opacity: 1}}>
                <ListItemText primary={user.name} secondary={user.email} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                onCloseProfile()
                onNavigate('/profile')
            }}>
                <ListItemIcon><ActionAccountBox /></ListItemIcon>
                <ListItemText primary={"Profile"} />
            </MenuItem>
            <MenuItem onClick={() => {
                onCloseProfile()
                onSignOut()
            }}>
                <ListItemIcon><ActionExitToApp /></ListItemIcon>
                <ListItemText primary='Sign Out' />
            </MenuItem>
        </Menu>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        profileOpen: state.profileMenu.open,
        anchorEl: state.profileMenu.anchor
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseProfile: () => {
            dispatch(closeProfileMenu())
        },
        onNavigate: pathname => {
            browserHistory.push(pathname)
          },
          onSignOut: () => {
            const timeout = setTimeout(() => {
              dispatch(showNotification('Connection timeout during sign out.'))
            }, 5000)
            users.signOut()
              .then(() => {
                clearTimeout(timeout)
                dispatch(showNotification('Signed out.'))
                dispatch(deauthUser())
                // eslint-disable-next-line no-restricted-globals
                browserHistory.push('/signin');
              })
              .catch(err => {
                clearTimeout(timeout)
                dispatch(showNotification(err.message))
              })
          },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)

//////////////////////////////////////////////////////////////////////////////