import React from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnUtils from '@date-io/date-fns'
import { withStyles } from '@material-ui/core/styles'
import { getTriggerType } from './Trigger'

import AddIcon from '@material-ui/icons/Add'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'

import moment from 'moment-timezone'

import transformKey from '../core/transformKey'
import CollapsePaper from '../core/CollapsePaper'

function formatQuery(query) {
  const filters = Object.keys(query).filter(key => !key.startsWith('$') && key !== "mmsi").map(key => {
    const item = query[key]

    let parts = []

    if (item.$in) {
      parts.push(<span>{(item.$in.length > 1 ? <span>in </span> : <span />)}{item.$in.join(' / ')}</span>)
    }
    if (item.$nin) {
      parts.push(<span>{(item.$nin.length > 1 ? <span>not in </span> : <span>not </span>)}{item.$nin.join(' / ')}</span>)
    }
    if (item.$lt) {
      parts.push(<span><span>less than </span>{item.$lt}</span>)
    }
    if (item.$gt) {
      parts.push(<span><span>greater than </span>{item.$gt}</span>)
    }

    return <span>{transformKey(key)}<span> is </span>{parts.length > 1 ? parts.reduce((x, y) => <span>{x}<span> and </span>{y}</span>) : parts[0]}</span>
  })
  return <span>{filters.length === 0 ? '...' : filters.reduce((x, y) => <span>{x}<span> and </span>{y}</span>)}</span>
}

function convertToDate(time) {
  return new Date(moment.tz("2016-07-01T" + time, "Europe/Helsinki").format())
}

function convertFromDate(datetime) {
  return moment.tz(datetime.toISOString(), "Europe/Helsinki").format().substr(11, 5)
}

class IfBlock extends React.Component {
  constructor(props) {
    super(props)
    const condition = this.props.trigger.condition
    this.state = {
      enabled: condition.enabled == null ? true : condition.enabled,
      //includeDuplicates is a misnomer:
      //duplicates are always included, but this governs the option about adding the marking 'duplicate' or 'valid' on each entry
      includeDuplicates: condition.includeDuplicates == null ? false : condition.includeDuplicates,
      type: getTriggerType(this.props.trigger),
      match: {
      },
      time: {
        days: [1],
        hours: '09:00',
      },
      firstDayOfMonth: {
        hours: '09:00',
      },
    }

    switch (this.state.type) {
      case 'match':
        this.state.match = this.props.trigger.action.filter
        break
      case 'time':
        this.state.time.days = condition.time.days
        this.state.time.hours = condition.time.hours
        break
      case 'firstDayOfMonth':
        this.state.firstDayOfMonth.hours = condition.firstDayOfMonth.hours
        break
      default:
        break
    }
  }
  render() {
    const { enabled, includeDuplicates, type, time, firstDayOfMonth } = this.state
    const { trigger, onChange, user, classes } = this.props

    var match = trigger.action.filter || {};

    let icon = <AddIcon />
    let text = <span />
    let body = <div />
    let HelperText


    const excludedCount = trigger.action.filter.mmsi ? trigger.action.filter.mmsi.$nin.length : 0
    const excludedText = excludedCount === 0 ? "" :
      excludedCount < 2 ? " excluding 1 vessel " : ` excluding ${excludedCount} vessels `

    const textIncludeDuplicatesAndEnabled = (includeDuplicates ? "(mark duplicates) " : "") + excludedText + (enabled ? "[ENABLED]" : "[DISABLED]");
    const duplicateToggle =
      <Switch
        value={('Mark duplicates: ' + (includeDuplicates ? 'Yes' : 'No'))}
        checked={includeDuplicates}
        color="primary"
        onChange={(v) => {
          const c = { enabled: enabled, includeDuplicates: !includeDuplicates }
          if (type === 'match') {
            c.match = match
          }
          if (type === 'time') {
            c.time = time
          }
          if (type === 'firstDayOfMonth') {
            c.firstDayOfMonth = firstDayOfMonth
          }
          onChange(c)
          this.setState({ includeDuplicates: !includeDuplicates })
        }}
      />;
    const includeDuplicatesToggle =
      <FormGroup>
        <FormControlLabel control={duplicateToggle} label={('MARK DUPLICATES: ' + (includeDuplicates ? 'YES' : 'NO'))} />
      </FormGroup>
    const enabledToggle =
      <Switch
        value={('Enabled: ' + (enabled ? 'Yes' : 'No'))}
        checked={enabled}
        color="primary"
        onChange={() => {
          const c = { enabled: !enabled, includeDuplicates: includeDuplicates }
          if (type === 'match') {
            c.match = match
          }
          if (type === 'time') {
            c.time = time
          }
          if (type === 'firstDayOfMonth') {
            c.firstDayOfMonth = firstDayOfMonth
          }
          onChange(c)
          this.setState({ enabled: !enabled })
        }}
      />


    switch (type) {
      case 'match': {
        HelperText = "Trigger is sent when a new report with matching filter criteria is created."

        icon = <TrackChangesIcon />
        text = <span><span>If </span>{formatQuery(match)} {textIncludeDuplicatesAndEnabled}</span>
        break
      }
      case 'time': {
        text = []

        let { days, hours } = time

        if (!days) days = []

        if (days.includes(1)) {
          text.push('Mon')
        }
        if (days.includes(2)) {
          text.push('Tue')
        }
        if (days.includes(3)) {
          text.push('Wed')
        }
        if (days.includes(4)) {
          text.push('Thu')
        }
        if (days.includes(5)) {
          text.push('Fri')
        }
        if (days.includes(6)) {
          text.push('Sat')
        }
        if (days.includes(0)) {
          text.push('Sun')
        }

        icon = <AccessTimeIcon />
        HelperText = `Trigger is sent every ${text.length !== 0 ? text.join(" and ") : "..."} at: ${hours}`
        text = <span><span>If </span>Weekday<span>{text.length > 1 ? ' is in ' : ' is '}</span>{text.length > 0 ? text.join(' / ') : '...'}<span> and </span>Time<span> is </span>{hours} {textIncludeDuplicatesAndEnabled}</span>
        body =
          <Paper elevation={4}>
            <Grid container >
              <Grid item>
                <ToggleButtonGroup value={days} >
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((n, i) => {
                    const d = (i + 1) % 7
                    return (
                      <ToggleButton
                        key={n}
                        value={d}
                        selected={days.includes(d)}
                        // variant="contained"
                        onClick={() => {
                          const condition = { enabled, includeDuplicates, time: { days: !days.includes(d) ? days.concat(d) : days.filter(s => s !== d), hours } }
                          onChange(condition)
                          this.setState(condition)
                        }}
                      >{n}</ToggleButton >
                    )
                  })}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnUtils}>
              <TimePicker
                ampm={false}
                value={convertToDate(hours)}
                onChange={(time) => {
                  const condition = { enabled, includeDuplicates, time: { days, hours: convertFromDate(time) } }
                  onChange(condition)
                  this.setState(condition)
                }}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        break
      }
      case 'firstDayOfMonth': {
        const { hours } = firstDayOfMonth
        HelperText = `Trigger is sent every first day of month at: ${hours}`

        icon = <AccessTimeIcon />
        text = <span><span>If </span>First Day Of Month<span> and </span>Time<span> is </span>{hours} {textIncludeDuplicatesAndEnabled}</span>
        body =
          <Paper elevation={4} >
            <MuiPickersUtilsProvider utils={DateFnUtils}>
              <TimePicker
                ampm={false}
                value={convertToDate(hours)}
                onChange={(time) => {
                  const condition = { firstDayOfMonth: { hours: convertFromDate(time) } }
                  onChange(condition)
                  this.setState(condition)
                }}
              />
            </MuiPickersUtilsProvider>
          </Paper>
        break
      }
      default:
        break
    }

    return (
      <CollapsePaper
        icon={icon}
        text={text}>
        <FormGroup>
          <FormControlLabel control={enabledToggle} label={('ENABLED: ' + (enabled ? 'YES' : 'NO'))} />
        </FormGroup>
        {user.organization === 'KINE' ? includeDuplicatesToggle : null}
        <Button variant="contained" color={type === 'match' ? "primary" : "default"} onClick={() => {
          onChange({ enabled, includeDuplicates, match }, 'last-match')
          this.setState({ type: 'match' })
        }} >Match</Button>
        <Button variant="contained" color={type === 'time' ? "primary" : "default"} onClick={() => {
          onChange({ enabled, includeDuplicates, time })
          this.setState({ type: 'time' })
        }} >Weekday</Button>
        <Button variant="contained" color={type === 'firstDayOfMonth' ? "primary" : "default"} onClick={() => {
          onChange({ enabled, includeDuplicates, firstDayOfMonth })
          this.setState({ type: 'firstDayOfMonth' })
        }} >First Day of Month</Button>
        <br />
        <Typography variant="body1" className={classes.helperText}>{HelperText}</Typography>
        {body}
      </CollapsePaper>
    )
  }
}

const styles = theme => ({
  helperText: {
    margin: theme.spacing(1)
  }
})


export default withStyles(styles)(IfBlock)