export const setDeleteDialogOpen = value => {
    return { type: "SET_DELETE_DIALOG_OPEN", value }
}

export const setBackDialogOpen = value => {
    return { type: 'SET_SAMPLE_EDIT_BACK_DIALOG_OPEN', value }
}

export const setSamples = samples => {
    return { type: "SET_SAMPLES", samples }
}

export const setEditSample = sample => {
    return { type: "SET_EDIT_SAMPLE", sample }
}

export const setEditDialogOpen = value => {
    return { type: "SET_SAMPLE_EDIT_DIALOG_OPEN", value }
}

export const setShip = (ship) => {
    return { type: "SET_SHIP", ship}
}

export const setNavigateBack = back => {
    return { type: "SET_BACK_NAVIGATION", back}
}