const triggerState = {
  deleteDialogOpen: false,
  backDialogOpen: false,
  editTrigger: {},
  triggers: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
}

export default (state = triggerState, action) => {
  switch (action.type) {
    case 'OPEN_TRIGGER_DELETE_DIALOG':
      return Object.assign({}, state, { deleteDialogOpen: true })
    case 'CLOSE_TRIGGER_DELETE_DIALOG':
      return Object.assign({}, state, { deleteDialogOpen: false })
    case 'OPEN_TRIGGER_BACK_DIALOG':
      return Object.assign({}, state, { backDialogOpen: true })
    case 'CLOSE_TRIGGER_BACK_DIALOG':
      return Object.assign({}, state, { backDialogOpen: false })
    case 'SET_EDIT_TRIGGER':
      return Object.assign({}, state, { editTrigger: Object.assign({}, action.trigger) })
    case 'SET_TRIGGERS':
      return Object.assign({}, state, { triggers: action.triggers })
    default:
      return state
  } 
}
