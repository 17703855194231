import React from 'react'

import { Typography } from '@material-ui/core'

import { CookieDisclaimer } from '../home/CookieDisclaimer'

import { connect } from 'react-redux'

const styles = {
  logoContainer: {
    margin: '2rem',
    display: 'flex',
  },
  logo: {
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '15rem'
  },
}

export class Home extends React.Component {
  render() {
    return (
      <div>
        <CookieDisclaimer />
        <div style={{ height: '4rem' }} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography style={{ marginBottom: '18px', marginLeft: '-40px' }} variant="h4" color="black">WELCOME TO</Typography>
          <div style={styles.logoContainer}>
            <img src='/assets/airnow_b2.png' style={styles.logo} alt=" " />
          </div>
          {/* <Summary />
        <ChangeLog /> */}
        </div>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

//////////////////////////////////////////////////////////////////////////////
