const sampleState = {
    deleteDialogOpen: false,
    backDialogOpen: false,
    editDialogOpen: false,
    editSample: {},
    selectedShip: null,
    samples: {
        total: 0,
        limit: 0,
        skip: 0,
        data: [],
    },
    backNavigation: null,
}

export default (state = sampleState, action) => {
    switch (action.type) {
        case 'SET_SAMPLE_EDIT_BACK_DIALOG_OPEN':
            return Object.assign({}, state, { backDialogOpen: action.value })
        case 'SET_SAMPLE_EDIT_DIALOG_OPEN':
            return Object.assign({}, state, { editDialogOpen: action.value })
        case 'SET_DELETE_DIALOG_OPEN':
            return Object.assign({}, state, { deleteDialogOpen: action.value })
        case 'SET_EDIT_SAMPLE':
            return Object.assign({}, state, { editSample: Object.assign({}, action.sample), editDialogOpen: true })
        case 'SET_SAMPLES':
            return Object.assign({}, state, { samples: action.samples })
        case 'SET_SHIP':
            return Object.assign({}, state, { selectedShip: action.ship })
        case 'SET_BACK_NAVIGATION':
            return Object.assign({}, state, { backNavigation: action.back })
        default:
            return state
    }
}