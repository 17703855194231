import startCase from 'lodash.startcase'

export default key => {
  return startCase(key
    .replace('fscUndetermined', 'Unclear FSC')
    .replace('fsc','FSC')
    .replace('imo','IMO')
    .replace('etaDate', 'ETA')
    .replace('mmsi','MMSI')
    .replace('V2', '')
    .replace('fmi', '')
    .replace('reliabilityCategory', 'reliability')
    .replace('$samples', 'Sample'))
}
