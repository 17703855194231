import React from 'react'

import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import PasswordChange from './PasswordChange'
import RequestReset from './RequestReset'
import Toolbar from '../core/Toolbar'

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { showNotification } from '../app/AppActions'
import { users } from '../api'


export class PasswordReset extends React.Component {
  constructor(props) {
    super(props)
    const query = this.props.location.query || {}
    this.state = {
      id: query.id || '',
      token: query.token || '',
    }
  }
  render() {
    const { id, token } = this.state
    const { onRequestReset, onPasswordChange, classes } = this.props

    let form
    if (id === '' || token === '') {
      form = <RequestReset onRequestReset={onRequestReset} />
    } else {
      form = <PasswordChange id={id} token={token} onPasswordChange={onPasswordChange} />
    }

    return (
      <div>
        <Toolbar title="Sign In" onGoBack={() => {
          browserHistory.push('/signin')
        }}/>
        <div className={classes.container}>
          <Paper elevation={4} className={classes.paper}>
            {form}
          </Paper>
        </div>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPasswordChange: (id, token, newPassword, passwordConfirmation) => {
      if (newPassword.length === 0) {
        dispatch(showNotification('New password is required'))
        return Promise.resolve(null)
      }
      if (passwordConfirmation.length === 0) {
        dispatch(showNotification('Password confirmation is required'))
        return Promise.resolve(null)
      }
      if (newPassword !== passwordConfirmation) {
        dispatch(showNotification('New password and password confirmation do not match'))
        return Promise.resolve(null)
      }
      return users.resetPassword(id, token, newPassword)
        .then(() => {
          dispatch(showNotification('Password changed'))
          setTimeout(() => browserHistory.push('/signin'), 50)
        })
        .catch(err => {
          dispatch(showNotification(err.message))
        })
    },
    onRequestReset: (email) => {
      if (email.length === 0) {
        dispatch(showNotification('E-mail is required'))
        return Promise.resolve(null)
      }
      return users.requestPasswordReset(email)
        .then(() => {
          dispatch(showNotification('Password reset requested'))
          setTimeout(() => browserHistory.push('/signin'), 50)
        })
        .catch(err => {
          dispatch(showNotification(err.message))
        })
    },
  }
}

const styles = theme => ({
  container: theme.containers.twoPaperFlex,
  paper: theme.containers.flexPaper,
  bottomDivider: theme.app.bottomDivider
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordReset))

//////////////////////////////////////////////////////////////////////////////
