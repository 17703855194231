import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ContentClear from '@material-ui/icons/Clear'
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'

import transformKey from '../transformKey'
import { Input, Divider } from '@material-ui/core'

moment.locale("en")
moment.updateLocale('en', {
  week: { dow: 1, doy: 0 }
});

function convertToDate(date) {
  const res = new Date(moment.tz(date + "T00:00", "Europe/Helsinki").format())
  return res
}

function convertFromDate(datetime) {
  const res = moment.tz(datetime.toISOString(), "Europe/Helsinki").format().substr(0, 10)
  return res
}

const FilterItem = ({ fields, operators, field, operator, value, onChangeField, onChangeOperator, onChangeValue, onRemove, classes }) => {
  const fieldFromKey = (key) => {
    return fields.filter(f => f.key === key)[0]
  }

  let valueComponent
  if (field.type === 'date') {
    const maxDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24)
    valueComponent =
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          format="DD.MM.YYYY"
          minDate={convertToDate('2016-06-01')}
          views={["year", "month", "date"]}
          maxDate={maxDate}
          showTodayButton
          value={convertToDate(value)}
          TextFieldComponent={(props) => (
            <Input
              onClick={props.onClick}
              value={props.value}
              className={classes.datePicker}
            />
          )}
          onChange={(date) => {
            onChangeValue({ field, operator, value: convertFromDate(date) })
          }}
        />
      </MuiPickersUtilsProvider>
  } else if (field.values.length > 0) {
    valueComponent =
      <TextField
        select
        value={value}
        onChange={(event, index) => {
          onChangeValue({ field, operator, value: field.values[index.key] })
        }}>
        >
        {field.values.map((m, j) => {
          const arr = [
            <MenuItem key={j} value={m} disabled={m.disabled}>
              {<small>{m}</small>}
            </MenuItem>
          ]
          if (m === "Any Fuel") {
            arr.push(<Divider key={m}/>)
          }
          return arr;
        })}
      </TextField>
  } else {
    valueComponent =
      <TextField
        fullWidth={true}
        inputStyle={field.onValidate(value) ? {} : { color: 'red', fontWeight: 'bold' }}
        underlineStyle={field.onValidate(value) ? {} : { borderColor: 'red' }}
        underlineFocusStyle={field.onValidate(value) ? {} : { borderColor: 'red' }}
        value={value}
        onChange={(event) => {
          onChangeValue({ field, operator, value: event.target.value })
        }}
      />
  }

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <span >
        <IconButton onClick={onRemove}>
          <ContentClear style={styles.smallIcon} />
        </IconButton>
        <span >
          <TextField
            select
            value={field.key}
            maxheight={300}
            onChange={(event, index) => {
              onChangeField({ field: fieldFromKey(event.target.value), operator, value })
            }}>
            {fields.map((m, j) => (
              <MenuItem key={m.key} value={m.key} disabled={m.disabled} >
                {<small
                // style={{whiteSpace: 'nowrap'}}
                >{transformKey(m.key) + (m.unit ? ` (${m.unit})` : '')}</small>}
              </MenuItem>
            ))}
          </TextField>
        </span>
        <span >
          {operators.map((n, i) =>
            <Button
              key={i}
              variant="contained"
              color={operator.key === n.key ? 'primary' : 'default'}
              disabled={n.disabled}
              className={classes.operatorButton}
              onClick={() => {
                onChangeOperator({ field, operator: n, value })
              }}>
              {n.label}
            </Button>
          )}
        </span>
        <span >
          {valueComponent}
        </span>
      </span>
    </form>
  )
}

const styles = theme => ({
  datePicker: {
    width: '5.8rem',
    fontSize: '13.333px',
    margin: '0.35rem'
  },
  smallIcon: {
    width: 20,
    height: 20,
  },
  operatorButton: {
    height: '2rem',
    width: '0.5rem',
    minWidth: '0.5rem',
    marginRight: '0.14rem',
    margin: '0px'
  }
})

export default withStyles(styles)(FilterItem)