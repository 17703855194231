import React from 'react';

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import { withStyles } from '@material-ui/core/styles'

const Help = ({ classes }) => {
    return (<div>
        <Paper elevation={4} className={classes.paper}>
            <div className={classes.root}>
                <Typography variant="h2" component="h1">AirNow-käyttöohje</Typography>
                <Typography variant="subtitle1">
                    AirNow on KINEn tarjoama web-palvelu päästömittaustulosten esittämiseen ja niiden raportointiin.
                </Typography>

                <Typography variant="h4" component="h2">Kirjautumissivu (Login)</Typography>
                <img src="/assets/help/home.jpg" className={classes.img} alt="Kirjautumissivu" />
                <Typography variant="h6" component="h3">Sisäänkirjautuminen</Typography>
                <Typography variant="body1">
                    Kirjautumissivun kautta palveluun voi kirjautua tarkoitusta varten luodulla käyttäjätunnuksella ja salasanalla. Google-kirjautuminen on vain KINEn sisäiseen käyttöön.
                    Täydennä E-mail -kenttään sähköpostiosoitteesi, Password -kenttään käyttäjätiliin liitetty salasana. Kirjaudu lopulta sisään painamalla <b>Sign in</b> -painiketta.
                </Typography>
                <Typography variant="h6" component="h3">Kirjautumisongelmat</Typography>
                <Typography variant="body1">
                    Mikäli sinulla ei ole käyttäjätunnusta, ole yhteydessä organisaatiosi Airnow-käyttäjätunnuksista vastaavaan järjestelmänvalvojaan.
                    Mikäli taas olet unohtanut salasanasi, voit palauttaa sen avaamalla salasanan palautusdialogin painamalla kolmea päällekkäistä pistettä Sign In -osion oikeassa yläkulmassa ja klikkaamalla sen jälkeen aukeavaa <b>Reset password</b> -painiketta.
                </Typography>

                <Typography variant="h4" component="h2">Käyttäjätiedot (Profile)</Typography>
                <Typography variant="h6" component="h3">Käyttäjätilin tiedot</Typography>
                <Typography variant="body1">General -osio sisältää yleistietoja käyttäjätilistäsi. Nämä tiedot ovat käyttäjänimenä toimiva tiliin liitetty sähköpostiosoite, käyttäjän nimi sekä käyttäjän organisaatio.
                Huomaa, että käyttäjätietoja ei voi vaihtaa profiilisivun kautta, vaan kaikissa muutoksissa on otettava yhteyttä organisaation käyttäjätilien järjestelmänvalvojaan.
                </Typography>
                <Typography variant="h6" component="h3">Salasanan vaihtaminen</Typography>
                <Typography variant="body1">Password -osion kautta voit vaihtaa käyttäjätilin salasanan. Vaihtaaksesi salasanasi, syötä ensin vanha salasanasi Old password -kenttään, jonka jälkeen syötä uusi salasanasi New password- ja Password confirmation -kenttiin. Paina lopuksi <b>Change password</b> -painiketta.
                </Typography>

                <Typography variant="h4" component="h2">Raportit (Reports)</Typography>
                <img src="/assets/help/reports.jpg" className={classes.img} alt="Raporttisivu" />
                <Typography variant="h6" component="h3">Raporttinäkymä</Typography>
                <Typography variant="body1">Raporttinäkymän kautta voit tarkastella AirNow-palvelun raportoimia päästömittaustuloksia. Esitettyjen raporttien aikajärjestystä voi muuttaa nousevan ja laskevan välillä painamalla <b>Sort By Time</b> -painiketta.
                Listan yläpuolisesta kuvaajasta voit tarkastella kullakin hetkellä listauksessa esitettyjen mittausten FSC-pitoisuutta. Kukin pylväs merkitsee yhtä listassa esitettyä mittaustapahtumaa.  Mittaukset, joiden FSC-pitoisuus on yli sallitun rajan (0,1%) esitetään kuvaajassa punaisella. Pitämällä hiirtä palkkien päällä saat näkyviin tarkempia tietoja mittauksesta, johon kyseinen palkki on liitetty.
                </Typography>
                <Typography variant="body1">Sivun pohjalla oleva listaus esittää kulloistenkin suodatusehtojen mukaiset mittausraportit. Oletuksena vain {">"}0,05% raportit
                näkyvät listauksessa. Rajauksen voi poistaa normaaliin tapaan sivulta nähdäkseen kaikki raportit. Listaus esittää kerrallaan enintään 20 mittaustulosta. Mittaustuloksia voi selata vaihtamalla esitettävää sivua listauksen oikean ja vasemman yläkulman nuolipainikkeilla.
                </Typography>
                <Typography variant="h6" component="h3">Yksittäisten raporttien tarkastelu</Typography>
                <Typography variant="body1">Raportteja voi tutkia tarkemmin klikkaamalla listassa esitettyjä yksittäisiä raportteja. Tämä avaa mittaustapahtuman tiedot ruudulle omaan ponnahdusikkunaan. Ponnahdusikkuna sisältää tarkempia tietoja mittaustapahtumasta, esittää mitta-aseman ja mitatun aluksen sijainnin kartalla. Lisäksi ikkuna esittää historiallisia vertailutietoja aluksesta, mikäli siitä on tehty aiempia mittauksia. Ponnahdusikkunan pohjalle on listattuna aluksen aiemmat mittausraportit
                </Typography>
                <Typography variant="h6" component="h3">Raporttien suodattaminen</Typography>
                <img src="/assets/help/filters.jpg" className={classes.imgfloat} alt="Suodatinvalikko" />
                <Typography variant="body1">
                    Listassa ja graafissa esitettäviä raportteja voi säädellä asettamalla haulle suodattimia. Suodattimen lisääminen tapahtuu painamalla <b>Add Filter</b> -painiketta.
                    Suodatettavaa arvoa voi vaihtaa suodattimen pudotusvalikosta. Suodatuksen ehtoja voi säädellä arvosta riippuen. Mahdollisia suodatusehtoja ovat esimerkiksi mittauksen ajankohta, mittausaseman sijainti ja mitattu polttoaineen rikkipitoisuus.
                    Valittuja suodattimia voi poistaa painamalla suodattimen vasemmalla puolella olevaa ruksia.
                </Typography>
                <Typography variant="h6" component="h3">Vapaa tekstihaku</Typography>
                <Typography variant="body1">
                    Vapaan tekstihaun avulla voi hakea tietyn aluksen raportit ja säädellä muita suodatusehtoja. Aluksen suodatus tapahtuu kirjoittamalla Search -kenttään aluksen nimi, IMO-numero tai MMSI-tunnus.
                    Mitta-aseman perusteella voi suodattaa kirjoittamalla kenttään pelkästään aseman nimen.
                    Tietyn lipun alla olevat alukset voi hakea maatunnuksella.
                    Raportit, joihin on liitetty polttoainenäyte, voi suodattaa hakuehdolla "sample".
                    Tarkemmin voi suodattaa tietyn tyyppiset polttoainenäytteet avainsanoilla: "fuel", "scrubber", "laboratory", "handheld", "independent".
                    Hakusana "bypass" vastaa suodatinta bypass=true.
                    Lisäämällä yksisanaisen haun eteen miinusmerkin (-) voi suodattaa pois hakua vastaavat raportit, esim: "‑bypass" vastaa suodatinta bypass=false.
                </Typography>
                <Typography variant="body1">
                    Tekstihaku on vaihtoehtoinen tapa lisätä muita suodattimia. Suodatettava arvo ja suodatusehto erotetaan yhtäsuuruusmerkillä, esim: "destination=turku".
                    Poissuodatuksen voi tehdä != operaattorilla.
                    Useamman suodatusehdon lista erotetaan pilkulla.
                    Lukuarvoisten arvojen ja mittausajankohdan suodatuksessa käytetään merkkejä =, {">"} ja {"<"}.
                    Useampia suodattimia voi kirjoittaa peräkkäin vapaasti. Alla on esimerkkejä hakukentän käytöstä.
                </Typography>

                <dl className={classes.dl}>
                    <dt><b>bypass</b></dt>
                    <dd>Vain ohitukset</dd>

                    <dt><b>-bypass</b></dt>
                    <dd>Vain onnistuneet mittaukset</dd>

                    <dt><b>hanko</b></dt>
                    <dd>Kaikki raportit asemalta Hanko</dd>

                    <dt><b>fi</b> tai <b>FI</b></dt>
                    <dd>Kaikki alukset joiden maatunnus on "FI"</dd>

                    <dt><b>nationality=fi,se,no</b></dt>
                    <dd>Kaikki alukset joiden maatunnus on "FI", "SE" tai "NO"</dd>
                    <dt><b>sea</b> tai <b>SEA</b></dt>
                    <dd>Kaikki raportit joissa aluksen nimessä on "SEA"</dd>

                    <dt><b>sea wind</b> tai <b>SEA WIND</b></dt>
                    <dd>Kaikki raportit joissa aluksen nimessä on "SEA" ja "WIND"</dd>

                    <dt><b>{">"}20.9.2020 fsc{">"}0.1</b></dt>
                    <dd>Kaikki raportit päivämäärän jälkeen, joissa FSC on yli 0.1%</dd>
                </dl>

                <Typography variant="h6" component="h3">Raporttien lataaminen</Typography>
                <Typography variant="body1">Raporttinäkymän kautta voit halutessasi ladata esitetyn datan mukaisia koosteita käyttäjän haluamassa tiedostomuodossa. Mahdolliset tiedostomuodot ovat CSV-, JSON-, XML- ja PDF-tiedostot. Koosteiden lataaminen tapahtuu painamalla <b>Download</b> -painiketta ja valitsemalla sitten haluttu tiedostomuoto.
                </Typography>
                <Typography variant="h4" component="h2">Näytteet (Samples)</Typography>
                <img src="/assets/help/samples.png" className={classes.img} alt="Samples-sivu" />

                <Typography variant="body1">
                    Samples-sivun kautta voi tarkastella, muokata ja lisätä polttoainenäytteitä. Näytenäkymään voi siirtyä navigointivalikosta tai suoraan raporteista.
                    Raportista siirryttäessä alus on valittuna automaattisesti.
                </Typography>
                <Typography variant="h6" component="h3">Näytteiden lisääminen ja muokkaaminen</Typography>
                <Typography variant="body1">
                    Valitse alus jolle näyte lisätään. Aluksia voi hakea Search Vessel -kentässä IMO-numeron, MMSI-tunnuksen tai nimen avulla.
                    Kun alus on valittu, näytetään sen tiedot ja kaikki alukselle lisätyt näytteet. Uuden näytten lisääminen tapahtuu painamalla <b>Add Sample</b> -painiketta.
                    Aukeavaan valikkoon lisätään näytteen tiedot. Muokkaaminen ja poistaminen tapahtuu taulukon <b>Edit</b> ja <b>Delete</b> -kuvakkeita painamalla.
                </Typography>
                <Typography variant="h4" component="h2">Liipaisimet (Triggers)</Typography>
                <img src="/assets/help/triggers.jpg" className={classes.img} alt="Liipaisinsivu" />
                <Typography variant="body1">
                    Liipaisimien avulla voi saada ennalta asetettujen sääntöjen mukaisia koosteita tai ilmoituksia mittaustapahtumista valitsemaansa sähköpostiosoitteeseen tai matkapuhelimeensa tekstiviestitse.
                    Liipaisimia voi käyttää esimerkiksi päivittäisten mittauskoosteiden lähettämiseen tai hälytyksiin, mikäli mittauksessa havaittu polttoaineen rikkipitoisuus ylittää sallitut rajat.
                </Typography>
                <Typography variant="h6" component="h3">Uuden liipaisimen lisääminen</Typography>
                <img src="/assets/help/create_trigger.jpg" className={classes.img} alt="Uuden liipaisimen lisääminen" />

                <Typography variant="body1"> Uuden liipaisimen luonti tapahtuu painamalla <b>Add trigger</b> -painiketta. Aukeavaan valikkoon voit täyttää haluamasi ehdot liipaisimelle. Match -välilehden alta voit luoda liipaisimen, joka laukeaa aina tiettyjen mittaustuloksen ehtojen täyttyessä. Weekday -välilehden alta voi asettaa liipaisimen lähettämään viestejä haluttuun aikaan tiettyinä viikonpäivinä. First day of month -välilehden kautta voi asettaa liipaisimen lähettämään kuukausittaisia raportteja tehdyistä mittaustuloksista.
                </Typography>
                <Typography variant="h6" component="h3">Liipaisimien muokkaaminen ja poistaminen</Typography>
                <Typography variant="body1">Jo käytössä olevaa liipaisinta voi muokata tai sen voi poistaa painamalla Triggers -sivun esittämässä liipaisinluettelossa olevaa yksittäistä liipaisinta. Aukeava valikko sisältää mahdollisuuden muokata liipaisimen asetuksia tai poistaa liipaisin kokonaan.
               </Typography>

                <Typography variant="h4" component="h2">Järjestelmänvalvojan hallintasivu (Admin)</Typography>
                <Typography variant="body1">Järjestelmänvalvojan käyttäjäoikeudet omaavat käyttäjät voivat Admin -sivun kautta hallita organisaationsa muita käyttäjätilejä.
                </Typography>
                <Typography variant="h6" component="h3">Käyttäjätilin lisääminen</Typography>
                <Typography variant="body1">
                    Uuden käyttäjän lisääminen järjestelmään tapahtuu käyttäjähallinnan kautta painamalla Admin -sivun General -valikossa Users -listaelementtiä.
                    Avautuva sivu sisältää luettelon kaikista organisaatioon kuuluvista käyttäjätileistä sekä niiden käyttäjäoikeuksista.
                    Uuden käyttäjän lisääminen tapahtuu painamalla <b>Add user</b> -painiketta, joka avaa uuden sivun käyttäjätilin luomiseen.
                    Sivun kenttiin täydennetään uuden käyttäjän tiedot, sähköpostiosoite, nimi sekä käyttäjälle myönnettävät käyttäjäoikeudet.
                    Käyttäjän luominen viimeistellään painamalla <b>Create</b> -painiketta.
                </Typography>
                <Typography variant="h6" component="h3">Käyttäjätilien muokkaaminen ja poisto</Typography>
                <Typography variant="body1">Olemassa olevien käyttäjätilien muokkaaminen tapahtuu painamalla Users -listalta halutun käyttäjätilin listaelementtiä, joka avaa käyttäjätilin tiedot sisältävän dialogi-ikkunan.
                </Typography>
                <Typography variant="h6" component="h3">Käyttäjätietojen muokkaaminen</Typography>
                <Typography variant="body1">Sivun kautta järjestelmänvalvoja voi annettuja kenttiä täydentämällä vaihtaa käyttäjätiliin liitetyn henkilön nimeä sekä lisätä tai poistaa tilille myönnettyjä käyttäjäoikeuksia.
                </Typography>
                <Typography variant="h6" component="h3">Käyttäjätilin poistaminen</Typography>
                <Typography variant="body1">Käyttäjätili voidaan poistaa painamalla <b>Delete</b> -painiketta.
                </Typography>
                <Typography variant="h6" component="h3">Salasanan palauttaminen</Typography>
                <Typography variant="body1">Käyttäjätiliin liitetty salasana voidaan palauttaa painamalla <b>Reset password</b> -painiketta. Salasanan palauttaminen lähettää käyttäjätiliin liitettyyn sähköpostiosoitteeseen sähköpostin, joka sisältää ohjeet uuden salasanan asettamiseksi.
                </Typography>
                <Typography variant="h6" component="h3">Käyttäjäoikeudet</Typography>
                <Typography variant="body1">Palvelun tarjoamat mahdolliset käyttäjäoikeudet ovat user ja admin. User-tilit ovat tavanomaisia käyttäjätilejä, joilla on oikeus tarkastella mittausraportteja sekä asettaa käyttöönsä liipaisimia. Admin-tilit omaavat järjestelmänvalvojan oikeudet ja voivat luoda, poistaa ja hallita käyttäjätilejä.
                </Typography>

                <Typography variant="h4" component="h2">Tekninen tuki</Typography>
                <Typography variant="body1">Virhe- sekä ongelmatilanteissa ole yhteydessä palvelun tekniseen tukeen sähköpostitse osoitteeseen <Link href="mailto:airnow-support@kine.fi">airnow-support@kine.fi</Link>. Sisällytä viestiin mahdollinen käyttäjätunnuksesi, lyhyt selostus havaitsemastasi ongelmasta ja sen tarkasta ilmenemisajankohdasta. Tukipalvelusta ollaan sinuun yhteydessä, kun viestisi on vastaanotettu sekä uudelleen ongelman ratkettua tai tarvittavista lisätiedoista.
                </Typography>
            </div >
        </Paper>
    </div>);
}
const styles = theme => ({
    root: {
        '& p': {
            margin: '1.5em 1.5em 1.5em 0',
            lineHeight: 1.5
        },
        '& h2': {
            margin: '1em 2em 1em 0',
            lineHeight: 2,
            clear: 'both'
        },
        '& h3': {
            margin: '1em 1.25em 1em 0',
            lineHeight: 1.25
        }
    },
    dl: {
        fontSize: '1rem',
        margin: '1.5em',
        lineHeight: 1.5
    },
    paper: {
        padding: '4em 6em',
        display: "flex",
        maxWidth: '1100px',
        paddingBottom: "40px"
    },
    img: {
        maxWidth: '90%',
        maxHeight: '32em'
    },
    imgfloat: {
        float: 'right',
        maxWidth: '38%',
        maxHeight: '100%',
        padding: '1em'
    }
})

export default withStyles(styles)(Help);