import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import { withStyles } from '@material-ui/core/styles'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import ActionLock from '@material-ui/icons/Lock'

import Toolbar from '../core/Toolbar'

import { CookieDisclaimer } from '../home/CookieDisclaimer'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { showNotification, showError, authUser } from '../app/AppActions'
import { users } from '../api'

//////////////////////////////////////////////////////////////////////////////

class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: 0,
      anchorEl: null
    }
  }


  render() {

    const { onSignIn, onSignInGoogle, onResetPassword, classes } = this.props
    const { error, anchorEl } = this.state
    const open = Boolean(anchorEl)

    const submitHandler = event => {
      event.preventDefault()
      onSignIn(this.state.email, this.state.password)
        .then(error => {
          if (error) {
            this.setState({ error: error })
          }
        })
    }
    const googleHandler = event => {
      event.preventDefault()
      onSignInGoogle()
    }

    const handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleClose = () => {
      this.setState({ anchorEl: null });
    };

    const iconButtonElement = (
      <IconButton onClick={handleClick} style={{ zIndex: '2' }} >
        <MoreVertIcon />
      </IconButton>
    )
    const rightIconMenu = (
      <div style={{ float: 'right' }}>
        {iconButtonElement}
        <Menu anchorEl={this.state.anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}>
          <MenuItem onClick={onResetPassword}>Reset password</MenuItem>
        </Menu>
      </div>
    )
    return (
      <div>
         <CookieDisclaimer />
        <Toolbar />
        <div className={classes.container} >
          <Paper elevation={4} className={classes.paper} >
            <div className={classes.logoContainer} >
              <img src='/assets/airnow_b2.png' alt=" "
                className={classes.signInLogo} />
            </div>
          </Paper>
          <Paper elevation={4} className={classes.paper}>
            {rightIconMenu}
            <List subheader={<ListSubheader>Sign In</ListSubheader>}>
              <form onSubmit={submitHandler} style={{ marginTop: '-24px' }}>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    id='email'
                    label='E-mail'
                    fullWidth={true}
                    onChange={e => this.setState({ email: e.target.value })}
                    type='email'
                    name='email'
                    error={!!error}
                    helperText={error === 1 || error === 3 ? 'E-mail is required' : error === 4 ? 'E-mail does not contain "@" symbol' : null}
                  />
                </div>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    id='password'
                    label='Password'
                    fullWidth={true}
                    onChange={e => this.setState({ password: e.target.value })}
                    type='password'
                    name='password'
                    error={!!error}
                    helperText={error === 2 || error === 3 ? 'Password is required' : null}
                  />
                </div>
                <div className={classes.bottomDivider} />
                <div className={classes.textFieldWrapper}>
                  <Button variant="contained" color="primary" startIcon={<ActionLock />} onClick={submitHandler} >Sign in</Button>
                  <Button startIcon={<img src='/assets/google.svg' alt=" " />} onClick={googleHandler}>Sign in with Google</Button>
                </div>
                <div className={classes.bottomDivider} />
                <input type='submit' style={{ display: 'none' }} />
              </form>
            </List>
          </Paper>
        </div>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (email, password) => {
      if (email.length === 0 && password.length === 0) {
        dispatch(showNotification('E-mail and password cannot be empty'))
        return Promise.resolve(3)
      }
      if (email.length === 0) {
        dispatch(showNotification('E-mail cannot be empty'))
        return Promise.resolve(1)
      }
      if (email.indexOf('@') === -1) {
        dispatch(showNotification('E-mail does not contain "@" symbol'))
        return Promise.resolve(4)
      }
      if (password.length === 0) {
        dispatch(showNotification('Password cannot be empty'))
        return Promise.resolve(2)
      }
      dispatch(showNotification('Signing in...'))
      const timeout = setTimeout(() => {
        dispatch(showNotification('Connection timeout during sign in.'))
      }, 5000)
      return users.signIn(email, password)
        .then(result => {
          clearTimeout(timeout)
          dispatch(showNotification('Signed in.'));
          dispatch(authUser(result.user));
          browserHistory.push('/home')
        }).catch(err => {
          // console.error('ERR', err);
          clearTimeout(timeout)
          if (err.name === 'MongoError') {
            dispatch(showNotification('Server error. Contact "admin@kine.fi" if problem persists.'))
          } else if (err.name === 'NotAuthenticated') {
            dispatch(showNotification('Unable to sign in. Invalid e-mail or password.'))
          } else {
            dispatch(showError('Error: ' + err.name + err.message))
          }
          browserHistory.push('/')
          setTimeout(() => browserHistory.push('/signin'), 10)
        })
        .then(() => null)
    },
    onSignInGoogle: () => {
      dispatch(showNotification('Signing in...'))
      return users.signInGoogle()
    },
    onResetPassword: () => {
      browserHistory.push('/passwordreset')
    },
  }
}

const styles = theme => ({
  textFieldWrapper: theme.textFieldWrapper,
  container: theme.containers.twoPaperFlex,
  logoContainer: theme.containers.logo,
  signInLogo: theme.icons.signInLogo,
  paper: theme.containers.flexPaper,
  bottomDivider: theme.app.bottomDivider
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn))

//////////////////////////////////////////////////////////////////////////////
