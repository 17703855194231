export const setReports = reports => {
  return { type: 'SET_REPORTS', reports }
}

export const setQuery = query => {
  return { type: 'SET_QUERY', query }
}

export const setFilters = filters => {
  return { type: 'SET_FILTERS', filters }
}

export const setSort = sort => {
  return { type: 'SET_SORT', sort }
}

export const setReport = report => {
  return { type: 'SET_REPORT', report }
}

export const openSaveDialog = () => {
  return { type: "OPEN_SAVE_DIALOG" };
}

export const closeSaveDialog = () => {
  return { type: "CLOSE_SAVE_DIALOG" };
}

export const setShowSearches = showSearches => {
  return { type: "SET_SHOW_SEARCHES", showSearches };
}

export const changeSaveInput = saveInput => {
  return { type: "CHANGE_SAVE_INPUT", saveInput };
}

export const setSearches = searches => {
  return { type: "SET_SEARCHES", searches };
}

export const selectSearch = searchId => {
  return { type: "SELECT_SEARCH", searchId };
}

export const setSearchDelete = (deleteId, deleteName) => {
  return { type: "SET_SEARCH_DELETE", deleteId, deleteName };
}

export const setHistory = (history) => {
  return { type: "SET_HISTORY", history }
}

export const setReportSamples = (samples) => {
  return { type: "SET_REPORT_SAMPLES", samples }
}