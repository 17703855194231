const summaryState = {
    stationStats: [],
    invoiced: {
        data: [],
        live: false
    }
}

export default (state = summaryState, action) => {
    switch (action.type) {
        case 'SET_SUMMARY':
            return Object.assign({}, state, { stationStats: action.summary })
        case 'SET_INVOICED':
            return Object.assign({}, state, { invoiced: action.result })
        default:
            return state;
    }
}