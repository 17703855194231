import React from 'react'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';

import ActionLock from '@material-ui/icons/Lock'

class PasswordChange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    }
  }

  render() {
    const { oldPassword, newPassword, passwordConfirmation } = this.state
    const { onPasswordChange, classes } = this.props

    const submitHandler = event => {
      event.preventDefault();
      onPasswordChange(this.state.oldPassword, this.state.newPassword, this.state.passwordConfirmation)
        .then(() => this.setState({
          oldPassword: '',
          newPassword: '',
          passwordConfirmation: '',
        }))
    }

    return (
      <List subheader={<ListSubheader>Password Change</ListSubheader>}>
        <form onSubmit={submitHandler}>
          <div className={classes.textFieldWrapper}>
            <TextField
              id='oldPassword'
              label='Old password'
              fullWidth
              value={oldPassword}
              onChange={(event, value) => this.setState({ oldPassword: event.target.value })}
              type='password'
            />
            <TextField
              id='newPassword'
              label='New password'
              fullWidth={true}
              value={newPassword}
              onChange={(event, value) => this.setState({ newPassword: event.target.value })}
              type='password'
            />
            <TextField
              id='passwordConfirmation'
              label='Password confirmation'
              fullWidth={true}
              value={passwordConfirmation}
              onChange={(event, value) => this.setState({ passwordConfirmation: event.target.value })}
              type='password'
            />
          </div>
          <div className={classes.bottomDivider} />
          <div className={classes.textFieldWrapper}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ActionLock />}
              onClick={submitHandler}>
              Change password
            </Button>
          </div>
          <div className={classes.bottomDivider} />
          <input type='submit' style={{ display: 'none' }} />
        </form>
      </List>
    )
  }
}

const styles = theme => ({
  textFieldWrapper: theme.textFieldWrapper,
  bottomDivider: theme.app.bottomDivider
})

export default withStyles(styles)(PasswordChange)
