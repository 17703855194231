export const openDeleteDialog = () => {
  return { type: 'OPEN_TRIGGER_DELETE_DIALOG' }
}

export const closeDeleteDialog = () => {
  return { type: 'CLOSE_TRIGGER_DELETE_DIALOG' }
}

export const openBackDialog = () => {
  return { type: 'OPEN_TRIGGER_BACK_DIALOG' }
}

export const closeBackDialog = () => {
  return { type: 'CLOSE_TRIGGER_BACK_DIALOG' }
}

export const setEditTrigger = trigger => {
  return { type: 'SET_EDIT_TRIGGER', trigger }
}

export const setTriggers = triggers => {
  return { type: 'SET_TRIGGERS', triggers }
}
