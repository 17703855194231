import startCase from 'lodash.startcase'

import React from 'react'

import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import Table from '../core/Table'
import Toolbar from '../core/Toolbar'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { setUsers } from './AdminActions'
import { showNotification } from '../app/AppActions'
import { users } from '../api'

//////////////////////////////////////////////////////////////////////////////


export class AdminUsers extends React.Component {
  constructor(props) {
    super(props)
    this.page = () => {
      const { query } = this.props.location
      return query && query.page ? parseInt(query.page) : 1
    }
  }
  componentDidMount() {
    try {
      this.props.onFetchUsers(this.page(), this.props.user)
    }
    catch (err) {
      console.error('ERROR', err);
    }
  }
  render() {
    const { users, onFetchUsers, onSelectUser, onAddUser, onGoBack } = this.props
    const columns = ['E-mail', 'Name', 'Organization', 'Roles']
    const data = users.data.map(user => {
      return {
        _id: user._id,
        'E-mail': user.google
          ? <span >
            {user.email}&nbsp;<img src='/assets/google.svg' alt=" " />
          </span>
          : user.email,
        'Name': user.google ? user.google.displayName : user.name,
        'Organization': user.organization,
        'Roles': user.roles.map(startCase).join(', '),
      }
    })
    const page = this.page()
    const total = users.total
    const limit = users.limit
    const onChangePage = onFetchUsers
    const onSelect = onSelectUser
    return (
      <div>
        <Toolbar title='Users' onGoBack={onGoBack}>
          <Button startIcon={<AddIcon />} onClick={onAddUser} >Add user</Button>
        </Toolbar>
        <Table columns={columns} data={data} page={page} total={total} depth={4} limit={limit} onChangePage={onChangePage} onSelect={onSelect} size={'medium'} />
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    users: state.admin.users,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectUser: (user) => {
      browserHistory.push('/admin/users/' + user._id)
    },
    onFetchUsers: (page) => {
      browserHistory.push({
        pathname: '/admin/users',
        query: { page }
      })
      users.list(page)
        .then(result => {
          dispatch(setUsers(result))
        })
        .catch(err => dispatch(showNotification(err.message)))
    },
    onAddUser: () => {
      browserHistory.push('/admin/users/new')
    },
    onGoBack: () => {
      browserHistory.push('/admin')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)

//////////////////////////////////////////////////////////////////////////////
