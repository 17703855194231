import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { closeDrawer, openDrawer, openProfileMenu, closeProfileMenu } from './AppActions'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    margin: 0
  },
  logo: {
    height: '2.0rem',
    marginLeft: '0.4rem'
  },
  tablet: theme.tablet,
  desktop: theme.desktop,
  profile: {
    color: "#fff",
  },
  profileDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  profileMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
}));


export const Header = ({ drawerOpen, user, onOpenDrawer, onCloseDrawer, location, profileOpen, onOpenProfile, onCloseProfile }) => {
  let appbarDesktop, appbar, mobileNavIcon, profileButton
  const classes = useStyles();

  if (location.pathname === '/passwordreset' || location.pathname === '/signin') {
    //do nothing
  }
  else {
    mobileNavIcon =
      <IconButton edge="start" style={{ color: 'white' }} onClick={drawerOpen ? onCloseDrawer : onOpenDrawer} >
        <MenuIcon />
      </IconButton>

    profileButton = (
      user._id != null &&
      <div>
        <Button
          className={`${classes.profile} ${classes.profileDesktop}`}
          size="large"
          onClick={profileOpen ? onCloseProfile : onOpenProfile}
          endIcon={<AccountCircleIcon fontSize={"large"} />}
        >
          {user.name}
        </Button>
        <IconButton
          className={`${classes.profile} ${classes.profileMobile}`}
          onClick={profileOpen ? onCloseProfile : onOpenProfile}>
          <AccountCircleIcon fontSize={"large"} />
        </IconButton>
      </div>
    )
  }

  appbarDesktop = (
    <AppBar position="fixed" className={`${classes.appBar} ${classes.desktop}`}>
      <Toolbar>
        <img src='/assets/airnow_w1.png' className={classes.logo} alt=" " />
        <div className={classes.grow} />
        {profileButton}
      </Toolbar>
    </AppBar>
  )

  appbar = (
    <AppBar position="fixed" className={`${classes.appBar} ${classes.tablet}`}>
      <Toolbar>
        {mobileNavIcon}
        <img src='/assets/airnow_w1.png' className={classes.logo} alt=" "/>
        <div className={classes.grow} />
        {profileButton}
      </Toolbar>
    </AppBar>
  )

  return (
    <header>
      {appbarDesktop}
      {appbar}
    </header>
  )
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.drawer.open,
    user: state.user,
    profileOpen: state.profileMenu.open
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseDrawer: () => {
      dispatch(closeDrawer())
    },
    onOpenDrawer: () => {
      dispatch(openDrawer())
    },
    onOpenProfile: (event) => {
      dispatch(openProfileMenu(event.currentTarget))
    },
    onCloseProfile: () => {
      dispatch(closeProfileMenu())
    },
    onNavigate: pathname => {
      browserHistory.push(pathname)
      dispatch(closeDrawer())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

//////////////////////////////////////////////////////////////////////////////
