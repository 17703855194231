import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { hideNotification } from './AppActions'

//////////////////////////////////////////////////////////////////////////////

export const Notification = ({ text, onHide }) => (
  <Snackbar
    open={text.length > 0}
    message={text}
    autoHideDuration={4000}
    onClose={onHide}
  />
)


const mapStateToProps = (state) => {
  return {
    text: state.notification.text,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideNotification()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)

//////////////////////////////////////////////////////////////////////////////
