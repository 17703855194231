import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import GetAppIcon from '@material-ui/icons/GetApp'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import RefreshIcon from '@material-ui/icons/Refresh'
import SaveIcon from '@material-ui/icons/Save'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotification } from '../app/AppActions'
import { openSaveDialog, closeSaveDialog, changeSaveInput, setSearches, selectSearch } from './ReportActions'
import { reports, searches } from '../api'
import { Menu } from '@material-ui/core'

//////////////////////////////////////////////////////////////////////////////

const capitalize = require('lodash.capitalize')

const visibleFields = [
  'station',
  'bypass',
  'fscUndetermined',
  'time',
  'fsc',
  'uncertainty',
  'uncertaintyV2',
  'so2Background',
  'co2Background',
  'noxBackground',
  'so2Area', 
  'co2Area',
  'noxArea',
  'fmiWindSpeed',
  'fmiWindDirection',
  'mmsi',
  'imo',
  'name',
  'destination',
  'etaDate',
  'nationality',
  'length',
  'beam',
  'longitude',
  'latitude',
  'shipType', // Not in current production version (CSV fromat)
  'distance',
  'notes',
  'reliabilityCategory',
]
const maxReports = 7500;

const DownloadOptions = [
  { value: 'csv', label: "CSV" },
  { value: 'json', label: "JSON" },
  { value: 'xml', label: "XML" },
  { value: 'pdf', label: "PDF" }
]

const saveDialog = (saveDialogOpen, savedQuery, userId, onSaveSearch, onCloseSaveDialog, saveInput, onChangeSaveInput) => {
  return (
    <Dialog open={saveDialogOpen} onClose={onCloseSaveDialog}>
      <DialogTitle>{'Enter search save name'}</DialogTitle>
      <DialogContent>
        <TextField
          id='saveName'
          disabled={false}
          label='Search name'
          defaultValue={saveInput}
          onChange={event => onChangeSaveInput(event.target.value)}
          type='text'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseSaveDialog}>Cancel</Button>
        <Button onClick={() => { onSaveSearch(saveInput, savedQuery, userId) }}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
};

class ReportHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      anchorEl: null
    }
    this.handleOnOpenReuqest = event => {
      this.setState({ anchorEl: event.currentTarget })
    }
    this.handleOnCloseRequest = () => {
      this.setState({ anchorEl: null })
    }
  }
  componentDidMount() {
    this.props.onLoadSearches(this.props.user._id);
  }
  render() {
    const { user, reports, onDownload, onRefresh, classes } = this.props;
    var { query } = this.props;
    const { onOpenSaveDialog, onSaveSearch, onCloseSaveDialog, onChangeSaveInput } = this.props;
    const { saveDialogOpen, saveInput } = this.props.save;
    const fields = visibleFields.concat(user.organization === 'KINE' ? ['invoiced'] : []);

    const refreshButton = (
      <Button
        startIcon={<RefreshIcon />}
        onClick={onRefresh}
      >
        Refresh
      </Button>
    )

    const downloadButton = (
      <React.Fragment>
        <Button startIcon={<GetAppIcon />} onClick={this.handleOnOpenReuqest}>
          Download
      </Button>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleOnCloseRequest}
        >
          {DownloadOptions.map(option => (
            <MenuItem
              className={classes.download}
              key={option.value}
              value={option.label}
              onClick={() => {
                this.handleOnCloseRequest()
                onDownload(option.value, fields, query, reports.total, user)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )

    const saveButton = (
      <Button
        startIcon={<SaveIcon />}
        onClick={onOpenSaveDialog}
      >
        Save search
      </Button>
    )

    let search = this.props.reports.searches.find(n => n._id === this.props.reports.searchId);
    let searchName = search ? search.name : null;

    const loadButton = (
      <Button
        startIcon={<FolderOpenIcon />}
        onClick={() => this.props.onOpenSearches()}
      >
        {searchName ? 'Selected: "' + searchName + '"' : 'Select search'}
      </Button>
    )

    const refreshButton2 = (
      <IconButton
        onClick={onRefresh}
      >
        <RefreshIcon />
      </IconButton>
    )

    const downloadButton2 = (
      <React.Fragment>
        <IconButton onClick={this.handleOnOpenReuqest}>
          <GetAppIcon />
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleOnCloseRequest}
        >
          {DownloadOptions.map(option => (
            <MenuItem
              className={classes.download}
              key={option.value}
              value={option.label}
              onClick={() => {
                this.handleOnCloseRequest()
                onDownload(option.value, fields, query, reports.total, user)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )

    const saveButton2 = (
      <IconButton
        onClick={onOpenSaveDialog}
      >
        <SaveIcon />
      </IconButton>
    )

    const loadButton2 = (
      <IconButton
        onClick={() => this.props.onOpenSearches()}
      >
        <FolderOpenIcon />
      </IconButton>
    )

    return (
      <div>
        <div className={classes.desktop} >
          {refreshButton}
          {downloadButton}
          {saveButton}
          {loadButton}
        </div>
        <div className={classes.tablet}>
          {refreshButton2}
          {downloadButton2}
          {saveButton2}
          {loadButton2}
        </div>
        {saveDialog(saveDialogOpen, this.props.location.query, user._id, onSaveSearch, onCloseSaveDialog, saveInput, onChangeSaveInput)}
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    user: state.user,
    query: state.report.query,
    reports: state.report,
    save: state.report.save,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDownload: (format, fields, query, quantity, user) => {
      if (format === 'pdf' && quantity >= maxReports) {
        dispatch(showNotification('Too many reports to export: ' + quantity + '. Maximum in PDF is ' + maxReports + '.'));
      }
      else {
        // dispatch(showNotification('Exporting reports in ' + capitalize(format) + ' format'))
        const interval = setInterval(() => {
          dispatch(showNotification(null))
          setTimeout(() => dispatch(showNotification('Exporting reports in ' + capitalize(format) + ' format')), 0)
        }, 2000)
        dispatch(showNotification(null))

        if (format === 'pdf' && user.organization === 'KINE') {
          query.$includeDuplicates = true;
        }

        reports.download(format, fields, query)
          .then(res => {
            clearInterval(interval)
            dispatch(showNotification(null))
            setTimeout(() => dispatch(showNotification('Exported reports in ' + capitalize(format) + ' format')), 0)
            return res
          })
          .catch(err => {
            clearInterval(interval)
            dispatch(showNotification(null))
            dispatch(showNotification(err.message))
          })
      }
    },
    onLoadSearches: (userId) => {
      searches.list(userId)
        .then(res => {
          dispatch(setSearches(res));
        })
        .catch(err => {
          dispatch(showNotification(err.message))
        });
    },
    onOpenSaveDialog: () => {
      dispatch(openSaveDialog());
    },
    onCloseSaveDialog: () => {
      dispatch(closeSaveDialog());
    },
    onChangeSaveInput: (saveInput) => {
      dispatch(changeSaveInput(saveInput));
    },
    onSaveSearch: (saveInput, query, userId) => {
      if (!saveInput) {
        dispatch(showNotification("Search name cannot be blank"));
      }
      else {
        searches.create({ userId: userId, name: saveInput, search: query })
          .then(() => {
            dispatch(showNotification('Search saved with name "' + saveInput + '"'));
            dispatch(closeSaveDialog());
            searches.list(userId)
              .then(res => {
                dispatch(setSearches(res));
                dispatch(selectSearch(res[res.length - 1]._id));
              })
              .catch(err => {
                dispatch(showNotification(err.message))
              });
          })
          .catch(err => {
            dispatch(showNotification(err.message));
          });
      }
    },
  }
}

const styles = theme => ({
  download: theme.spacing(14),
  desktop: theme.desktop,
  tablet: theme.tablet,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportHeader))

//////////////////////////////////////////////////////////////////////////////
