import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

export default ({ columns, data, onSelect, disabledColumns, tableCellClassName }) => {
  return (
    <TableBody>
      {data.map((d, i) => {
        return (
          <TableRow key={i} hover >
            {columns.map((column, j) =>
              <TableCell 
                key={j}
                align={tableCellClassName && j !== 0 ? "center" : "inherit"}
                className={tableCellClassName || ''}
                onClick={() => { if (onSelect != null && (!disabledColumns || !disabledColumns.includes(j))) onSelect(d, i) }}
                >
                {d[Array.isArray(d) ? j : column]}
              </TableCell>
            )}
          </TableRow>
        )
      })}
    </TableBody>
  )
}