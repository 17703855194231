import React from 'react'

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles'

import ActionHome from '@material-ui/icons/Home'
import ActionSettings from '@material-ui/icons/Settings'
import BarChartIcon from '@material-ui/icons/BarChart';
import ActionBuild from '@material-ui/icons/Build'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FlagIcon from '@material-ui/icons/Flag';
import HelpIcon from '@material-ui/icons/Help';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { closeDrawer, openDrawer } from './AppActions'


const Nav = ({ drawerOpen, user, onOpenDrawer, onCloseDrawer, onNavigate, classes }) => {

  const menuItems = []

  if (user._id) {
    menuItems.push(
      <Divider key='divider1' className={classes.menuDivider} />
    )

    menuItems.push(
      <ListItem button key='home' onClick={() => onNavigate('/home')}>
        <ListItemIcon><ActionHome /></ListItemIcon>
        <ListItemText primary='Home' />
      </ListItem>
    )

    if (user.roles) {
      if (user.roles.includes('user') || user.roles.includes('superadmin')) {
        menuItems.push(
          <ListItem button key='reports' onClick={() => onNavigate('/reports')}>
            <ListItemIcon><BarChartIcon /></ListItemIcon>
            <ListItemText primary='Reports' />
          </ListItem>
        )
        if (user.organization === 'KINE') {
          menuItems.push(
            <ListItem button key='samples' onClick={() => onNavigate('/samples')}>
              <ListItemIcon><FlagIcon /></ListItemIcon>
              <ListItemText primary='Samples' />
            </ListItem>
          )
        }
        menuItems.push(
          <ListItem button key='triggers' onClick={() => onNavigate('/triggers')}>
            <ListItemIcon><NotificationsActiveIcon /></ListItemIcon>
            <ListItemText primary='Triggers' />
          </ListItem>
        )
      }
      if (user.roles.includes('technician') || user.roles.includes('superadmin')) {
        menuItems.push(
          <ListItem button key='maintenance' onClick={() => onNavigate('/maintenance')}>
            <ListItemIcon><ActionBuild /></ListItemIcon>
            <ListItemText primary='Maintenance' />
          </ListItem>
        )
      }

      if (user.roles.includes('admin') || user.roles.includes('superadmin')) {
        menuItems.push(
          <ListItem button key='admin' onClick={() => onNavigate('/admin')}>
            <ListItemIcon><ActionSettings /></ListItemIcon>
            <ListItemText primary='Admin' />
          </ListItem>
        )
      }
    }

    menuItems.push(<ListItem button key='help' onClick={() => onNavigate('/help')}>
      <ListItemIcon><HelpIcon /></ListItemIcon>
      <ListItemText primary='Help' />
    </ListItem>
    )

    menuItems.push(
      <Divider key='divider2' className={classes.menuDivider} />
    )

    menuItems.push(
      <Divider key='divider3' className={classes.menuDivider} />
    )

    return (
      <Drawer open={drawerOpen} className={`${classes.drawer} ${classes.tablet}`}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={open => open ? onCloseDrawer() : onOpenDrawer()}>
        <img src='/assets/airnow_b1.png' className={classes.logo} alt=" " />
        <List>
          {menuItems}
        </List>
      </Drawer>
    )
  }
  return <div />
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.drawer.open,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenDrawer: () => {
      dispatch(openDrawer())
    },
    onCloseDrawer: () => {
      dispatch(closeDrawer())
    },
    onNavigate: pathname => {
      browserHistory.push(pathname)
      dispatch(closeDrawer())
    }
  }
}

const styles = theme => ({
  drawer: theme.navBar.drawer,
  menuDivider: theme.navBar.menuDivider,
  drawerPaper: theme.navBar.drawerPaper.mobile,
  logo: theme.icons.mobileNavBarIcon,
  tablet: theme.tablet
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Nav))

//////////////////////////////////////////////////////////////////////////////
