import React from 'react'

import Paper from '@material-ui/core/Paper'
import { CookieDisclaimer } from '../home/CookieDisclaimer'

import moment from 'moment-timezone'

import FlagIcon from '@material-ui/icons/Flag'
import StarIcon from '@material-ui/icons/Star'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import CreateIcon from '@material-ui/icons/Create'
import { withStyles } from '@material-ui/core/styles'

import Chart from './ReportChart'
import Search from './ReportSearch'
import Table from '../core/Table'
import Toolbar from '../core/Toolbar'
import ReportHeader from './ReportHeader'
import ReportDialog from './ReportDialog'
import ReportLoad from './ReportLoad'
import { sampleTypes, typeToLabel, typeToSQL } from '../samples/Samples'

import { chain } from 'lodash'

import Filter from '../core/Filter'
import { getMmsi, getFscColor } from '../core/reportUtils'
import getShipType from '../core/getShipType'

import base64 from '../core/base64'

//////////////////////////////////////////////////////////////////////////////

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { showNotification, showError } from '../app/AppActions'
import { setReports, setQuery, setFilters, setSort, setReport, setHistory, setReportSamples } from './ReportActions'
import { setShip, setSamples, setEditSample, setNavigateBack } from '../samples/SampleActions'
import { reports, searches, samples, ships } from '../api'

import { setShowSearches, selectSearch, setSearches, setSearchDelete } from './ReportActions';

//////////////////////////////////////////////////////////////////////////////

import { $lt, $gt, $in, $nin } from '../core/Filter/FilterOperators'

const columns = [<span>Time<br />Station</span>, <span>MMSI<br />IMO<br />Name</span>, <span>FSC (from emission)<br />Reliability<br />Uncertainty</span>, <span>Destination<br />ETA</span>, <span>Nationality<br />Type<br />Length × Beam</span>, <span>Wind Speed<br />Wind Direction<br />Distance</span>, <span>Samples</span>]
const stations = ['Sniffer-Buoy', 'Mobile', 'Turku', 'Hanko', 'Vuosaari', 'Nordvalen', 'Kilpilahti', 'Kotka', 'Maarianhamina', 'Helsinki']
const nationalities = ['AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'HR', 'CU', 'CW', 'CY', 'CZ', 'CI', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RO', 'RU', 'RW', 'RE', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW', 'AX'].sort()
const types = ['Anti-Pollution', 'Cargo', 'Dive Vessel', 'Dredger', 'Fishing', 'HSC', 'Law Enforcement', 'Local Vessel', 'Medical Transport', 'Military Ops', 'Other', 'Passenger', 'Pilot Vessel', 'Port Tender', 'Pleasure Craft', 'Sailing Vessel', 'Search and Rescue', 'Special Craft', 'Tanker', 'Tug', 'Unspecified', 'Wing In Groud'].sort()

function formatDateTime(datetime) {
  const date = moment.tz(datetime, "Europe/Helsinki").format()
  const year = date.substr(0, 4)
  const month = date.substr(5, 2)
  const day = date.substr(8, 2)
  const time = date.substr(11, 5)
  const res = `${day}.${month}.${year} ${time}`
  return res;
}

/**
 * Converts scrubber ratios into comparable form (if needed) and
 * returns the max fsc of the samples array
 * @param {Array} arr 
 */
const getMaxFsc = (arr) => {
  if (arr.length && arr[0].type === "scrubber") {
    return Math.max(...arr.map(x => x.sample * 0.023))
  }
  return Math.max(...arr.map(x => x.sample))
}

const filterFields = [
  { key: 'time', type: 'date', values: [], onValidate: v => true },
  { key: 'station', type: 'string', values: stations, onValidate: v => stations.includes(v) },
  // {key: 'mmsi', type: 'string', values: mmsis, onValidate: v => mmsis.includes(v) },
  // {key: 'imo', type: 'string', values: imos, onValidate: v => imos.includes(v) },
  // {key: 'name', type: 'string', values: names, onValidate: v => names.includes(v) },
  { key: 'fsc', type: 'number', unit: '%', values: [0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2], onValidate: v => v !== '' && v >= 0 && v <= 100 },
  { key: '$samples', type: 'string', values: ["Any", "None", "Any Fuel"].concat(sampleTypes.map(type => type.label)), onValidate: v => true },
  // {key: 'fscDeviation', type: 'number', unit: '%', values: [0.01,0.02,0.05,0.1,0.15,0.2,0.3,0.5,1,1.5], onValidate: v => v !== '' && v >= 0 && v <= 100 },
  { key: 'uncertaintyV2', type: 'number', unit: '%', values: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100], onValidate: v => v !== '' && v >= 0 },
  // {key: 'destination', type: 'string', values: destinations, onValidate: v => destinations.includes(v) },
  // {key: 'etaDate', type: 'number', unit: 'h', values: [1,2,3,4,6,8,12,24,48,96], onValidate: v => v !== '' && v >= 0 },
  { key: 'nationality', type: 'string', values: nationalities, onValidate: v => nationalities.includes(v) },
  { key: 'shipType', type: 'string', values: types, onValidate: v => types.includes(v) },
  { key: 'length', type: 'number', unit: 'm', values: [5, 10, 15, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200], onValidate: v => v !== '' && v >= 0 },
  { key: 'beam', type: 'number', unit: 'm', values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50], onValidate: v => v !== '' && v >= 0 },
  // {key: 'longitude', type: 'number', unit: '°', values: [], onValidate: v => v !== '' && v >= -180 && v <= 180 },
  // {key: 'latitude', type: 'number', unit: '°', values: [], onValidate: v => v !== '' && v >= -90 && v <= 90 },
  { key: 'fmiWindSpeed', type: 'number', unit: 'm/s', values: [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20], onValidate: v => v !== '' && v >= 0 && v <= 50 },
  { key: 'fmiWindDirection', type: 'number', unit: '°', values: [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 320, 340], onValidate: v => v !== '' && v >= 0 && v < 360 },
  { key: 'distance', type: 'number', unit: 'm', values: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000], onValidate: v => v !== '' && v >= 0 },
  // {key: 'fscUndetermined', type: 'boolean', unit: '', values: ['true', 'false'], onValidate: v => v !== '' },
  { key: 'bypass', type: 'boolean', unit: '', values: ['true', 'false'], onValidate: v => ['true', 'false'].includes(v.toLowerCase()) },
  { key: 'reliabilityCategory', type: 'string', unit: '', values: ['Good', 'Fair', 'Poor'], onValidate: v => ['good', 'fair', 'poor'].includes(v.toLowerCase()) },
  { key: 'flag', type: 'string', unit: '', values: ['Red', 'Orange', 'Green'], onValidate: v => ['red', 'orange', 'green'].includes(v.toLowerCase()) },
]

function parseQueryFilters(query) {
  const filters = []
  Object.keys(query).filter(key => key === "$samples" || !key.startsWith('$')).forEach(key => {
    const item = query[key]
    const field = filterFields.filter(n => n.key === key)[0]
    const convertValue = value => key === "$samples" ? typeToLabel(value.split("'").join("")) : value
    if (item.$in) {
      item.$in.forEach(n => filters.push({ field, operator: $in, value: convertValue(n) }))
    }
    if (item.$nin) {
      item.$nin.forEach(n => filters.push({ field, operator: $nin, value: convertValue(n) }))
    }
    if (item.$lt) {
      let value = item.$lt
      filters.push({ field, operator: $lt, value })
    }
    if (item.$gt) {
      let value = item.$gt
      filters.push({ field, operator: $gt, value })
    }
  })
  return filters
}

export class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersChanged: false,
      search: this.search(),
      filters: this.filters(),
      sort: this.sort()
    }
    this.page = () => {
      const { query } = this.props.location
      return query && query.page ? parseInt(query.page) : 1
    }
  }

  query = () => {
    const { query } = this.props.location;
    return query && query.q ? JSON.parse(base64.decode(query.q)) : { $sort: { time: -1 } };
  }

  search = () => {
    const { query } = this.props.location
    return query && query.s ? base64.decode(query.s) : ''
  }

  filters = () => {
    return parseQueryFilters(this.query())
  }

  sort = () => {
    const sort = this.query().$sort
    return sort ? sort.time : -1
  }

  componentDidMount() {
    this.props.onInitialize()
    this.props.onChangeQuery(this.page(), this.filters(), this.sort(), this.search())
  }
  render() {
    const { user, reports, onChangeQuery, onSetReport, onSetEditSample, classes } = this.props;
    const { searches, searchId, searchDelete } = this.props;
    const { onSelectSearch, onSetSearchDelete, onDeleteSearch } = this.props;
    const { history, samples } = this.props

    const sampleButton = (report, color, text) => (
      <div className={classes.sampleContainer}>
        <div >
          <CreateIcon onClick={() => onSetEditSample(report)} >
            <FlagIcon />
          </CreateIcon>
          <FlagIcon className={color} />
        </div>
        <div >
          {text.map(t => (
            <span key={t}><span>{t}</span><br /></span>
          ))}
        </div>
      </div>

    );

    const sampleField = report => {
      const values = chain(report.samples)
        .groupBy("type")
        .map(x => x)
        .value()

      let color = classes.green
      if (!values.length) { // No samples
        return (
          <span>
            <CreateIcon onClick={() => onSetEditSample(report)} />
          </span>
        )
      } else if (values.length === 1) { // Only one sample
        const max = getMaxFsc(values[0])
        if (max > 0.1)
          color = classes.red

        return sampleButton(report, color, [typeToLabel(values[0][0].type).replace(" (Unknown source)", "")])
      } else if (values.length < 4) { // 2-3 samples in own lines
        const maxValues = values.map(x => getMaxFsc(x))
        const max = Math.max(...maxValues)
        if (max > 0.1) {
          if (maxValues.find(x => x < 0.1)) {
            color = classes.yellow
          } else {
            color = classes.red
          }
        }

        return sampleButton(report, color, values.map(x => typeToLabel(x[0].type).replace(" (Unknown source)", "")))
      } else { // Mixed
        const maxValues = values.map(x => getMaxFsc(x))
        const max = Math.max(...maxValues)
        if (max > 0.1) {
          if (maxValues.find(x => x < 0.1)) {
            color = classes.yellow
          } else {
            color = color.red
          }
        }

        return sampleButton(report, color, ["Mixed"])
      }
    }

    const data = reports.data.map(n => {
      return [
        <span>
          {formatDateTime(new Date(n.time))}
          <br />
          {n.station}
        </span>,
        <span>
          {getMmsi(n.mmsi)}
          <br />
          {n.imo == null ? '-------' : n.imo}
          <br />
          {n.name || '-------'}
        </span>,
        n.bypass
          ? <span style={{ lineHeight: 1.2 }}>Bypass<br />-<br />-</span>
          : <span style={{ lineHeight: 1.2 }}>
            {n.flag ? <FlagIcon className={getFscColor(n.flag, classes)} /> : '--'}
            <span> {(n.fsc != null && n.fsc >= 0.05 ? n.fsc.toFixed(2) : '<0.05')} %</span>
            <br />
            {n.reliabilityCategory === 'Good'
              ? <StarIcon className={classes.icon} />
              : n.reliabilityCategory === 'Fair'
                ? <StarHalfIcon className={classes.icon} />
                : n.reliabilityCategory === 'Poor'
                  ? <StarBorderIcon className={classes.icon} />
                  : <span />}
            <span> {n.reliabilityCategory}</span>
            <br />
            {n.fscUndetermined || n.uncertaintyV2 == null ? '--- %' : n.uncertaintyV2 + ' %'}
          </span>,
        <span>
          {n.destination || '-------'}
          <br />
          {n.etaDate != null && n.etaDate.length >= 11 ? n.etaDate.substr(0, 11) : '----- --:--'}
        </span>,
        <span>
          {n.nationality || '--'}
          <br />
          {n.shipType != null ? getShipType(n.shipType).replace("HSC", "High Speed Craft") : "Unknown"}
          <br />
          {n.length || '--'} m × {n.beam || '--'} m
        </span>,
        <span>
          {Math.round(n.fmiWindSpeed, 1)} m/s
          <br />
          {Math.round(n.fmiWindDirection)}°
          <br />
          {Math.round(n.distance)} m
        </span>,
        <span>
          {sampleField(n)}
        </span>,
      ]
    })
    const page = this.page()
    const total = reports.total
    const limit = reports.limit
    const onSelect = (report) => onSetReport(report)

    let kineStats;
    if (user.organization === 'KINE' && (!user.hasOwnProperty('showExtraInfo') || user.showExtraInfo)) {
      kineStats = (
        <Paper elevation={4} className={classes.paper}>
          <span>Total: </span>{reports.stats.total},
          <span> Invoiced: </span>{reports.stats.invoiced},
          <span> Duplicate: </span>{reports.stats.duplicate},
          <span> Bypass: </span>{reports.stats.bypass},
          <span> Measured: </span>{reports.stats.measured},
          <span> Undetermined: </span>{reports.stats.undetermined}

        </Paper>
      );
    };
    return (
      <div>
        <CookieDisclaimer />
        {user._id ?
          <Toolbar title='Reports' actions={
            <ReportHeader
              onRefresh={() => onChangeQuery(page, this.filters(), this.sort(), this.search())}
              location={this.props.location}
              user={this.props.user}
              onOpenSearches={() => this.props.onSetShowSearches(true)}
            />}
          />
          : null}
        <ReportLoad
          searches={searches}
          searchId={searchId}
          searchDelete={searchDelete}
          onSelectSearch={(q, s, id) => {
            this.setState({
              filters: parseQueryFilters(q),
              sort: -1,
              search: s,
              filtersChanged: true
            })
            onSelectSearch(id);
          }
          }
          onOpenDeleteDialog={(id, name) => onSetSearchDelete(id, name)}
          onCloseDeleteDialog={() => onSetSearchDelete(null, '')}
          onDeleteSearch={(id, name) => onDeleteSearch(id, name, user._id, searchId)} visible={this.props.showSearches} onClose={() => this.props.onSetShowSearches(false)}
        />
        <Search
          search={this.state.search}
          onChange={(search) => {
            this.setState({ search: search, filtersChanged: true });
            onSelectSearch(null);
          }}
          onSubmit={event => {
            event.preventDefault()
            this.setState({ filtersChanged: false })
            onChangeQuery(1, this.state.filters, this.state.sort, this.state.search);
            onSelectSearch(null);
          }}
        />
        <Filter
          fields={filterFields}
          filters={this.state.filters}
          sort={this.state.sort}
          search={this.state.search}
          filtersChanged={this.state.filtersChanged}
          onChange={(filters, sort, search) => {
            this.setState({
              filters: filters,
              sort: sort,
              search: search,
              filtersChanged: true
            })
            onSelectSearch(null);
          }}
          onApplyFilters={(filters, sort, search) => {
            this.setState({ filtersChanged: false })
            onChangeQuery(1, filters, sort, search);
            onSelectSearch(null);
          }}
          controlledApplying={true}
        />
        {reports.data.length > 0 ? <Chart reports={reports} onSelect={onSelect} /> : <span />}
        {kineStats}
        <Table
          columns={columns}
          disabledColumns={[6]}
          depth={4}
          data={data}
          page={page}
          total={total}
          limit={limit}
          onChangePage={page => onChangeQuery(page, this.filters(), this.sort(), this.search())}
          onSelect={(_, i) => { onSelect(reports.data[i]) }}
        />
        <ReportDialog
          data={reports.report}
          history={history}
          onClose={() => onSetReport(null)}
          onSelect={onSelect}
          isKine={user.organization === 'KINE' && user.showExtraInfo}
          samples={samples}
          onEditSample={onSetEditSample}
        />
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const transformFilters = (filters) => {
  const q = {}
  filters.forEach(f => {
    let { field, operator, value } = Object.assign({}, f)

    if (q[field.key] == null) {
      q[field.key] = {}
    }

    if (field.key === "$samples") {
      value = `'${typeToSQL(value)}'`
    }

    if (operator.key === '$in' || operator.key === '$nin') {
      if (!q[field.key][operator.key]) {
        q[field.key][operator.key] = [value]
      } else {
        q[field.key][operator.key].push(value)
      }
    } else {
      q[field.key][operator.key] = value
    }
  })
  return q
}

const transformSort = sort => {
  const q = { $sort: {} }
  q.$sort[sort.field] = sort.direction
  return q
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    reports: state.report,
    showSearches: state.report.showSearches,
    searches: state.report.searches,
    searchId: state.report.searchId,
    searchDelete: state.report.searchDelete,
    sampleShip: state.report.sampleShip,
    history: state.report.history,
    samples: state.report.reportSamples
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitialize: () => {
      dispatch(setReports({
        total: 0,
        limit: 0,
        skip: 0,
        data: [],
      }));
      dispatch(selectSearch(null));
    },
    onChangeQuery: (page, filters, sort, search) => {
      let query = {}
      if (filters) {
        dispatch(setFilters(filters))
        query = Object.assign(query, transformFilters(filters))
      }
      if (sort) {
        sort = { field: 'time', direction: sort }
        dispatch(setSort(sort))
        query = Object.assign(query, transformSort(sort))
      }
      dispatch(setQuery(query))
      browserHistory.push({
        pathname: '/reports',
        query: { page, q: base64.encode(JSON.stringify(query)), s: base64.encode(search) },
      })
      reports.list(page, query, search)
        .then(result => {
          if (result.data.length === 0) {
            browserHistory.push({
              pathname: '/reports',
              query: { page: 1, q: base64.encode(JSON.stringify(query)), s: base64.encode(search) },
            })
            reports.list(1, query, search).then(result => {
              dispatch(setReports(result))
            })
          } else {
            dispatch(setReports(result))
          }
        })
        .catch(err => dispatch(showNotification(err.message)))
    },
    onSetReport: (report) => {
      dispatch(setReport(report))
      if (report) {
        const { mmsi, time } = report
        const last30days = new Date(new Date(time).getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        const currentTime = new Date(time).toISOString();
        const search = `<${currentTime} >${last30days}`
        let query = {
          mmsi: { $in: [mmsi] },
          $sort: { time: -1 },
          $limit: 1000,
        }
        samples.list(mmsi)
          .then(res => {
            dispatch(setReportSamples(res.data))
          })
          .catch(err => dispatch(showNotification(err.message)))

        reports.list(1, query, search)
          .then(res => {
            dispatch(setHistory(res))
          })
          .catch(err => dispatch(showNotification(err.message)))
      }
    },
    onSetEditSample: (sampleShip, sample = null) => {
      const mmsi = sampleShip.mmsi;
      browserHistory.push('/samples')
      ships.list({ mmsi: mmsi })
        .then(shipResult => {
          const ship = shipResult.data[0]
          dispatch(setShip(ship))
          samples.list(mmsi, {
            $sort: {
              timestamp: -1
            }
          })
            .then(sampleResult => {
              dispatch(setSamples(sampleResult.data))
              dispatch(setNavigateBack({ title: "Back To Reports", url: '/reports' }))
              if (sample) {
                dispatch(setEditSample(sample))
              }
            })
            .catch(err => dispatch(showError(err.message)))
        })
        .catch(err => dispatch(showError(err.message)))
    },
    onFetchSamples: (mmsi, query = {}) => {
      return samples.list(mmsi, query)
        .catch(err => dispatch(showNotification(err.message)))
    },
    onSetShowSearches: show => {
      dispatch(setShowSearches(show));
    },
    onSelectSearch: searchId => {
      dispatch(selectSearch(searchId));
      dispatch(setShowSearches(false));
    },
    onSetSearchDelete: (deleteId, deleteName) => {
      dispatch(setSearchDelete(deleteId, deleteName));
    },
    onDeleteSearch: (deleteId, deleteName, userId, searchId) => {
      dispatch(setSearchDelete(null, ''));
      searches.remove(deleteId)
        .then(() => {
          if (deleteId === searchId) {
            dispatch(selectSearch(null));
          }
          dispatch(showNotification('Search "' + deleteName + '" deleted'));
          searches.list(userId)
            .then(res => {
              dispatch(setSearches(res));
            })
            .catch(err => {
              dispatch(showNotification(err.message))
            });
        })
        .catch(err => {
          dispatch(showNotification(err.message));
        });
    },
    onShowNotification: message => {
      dispatch(showNotification(message));
    },
  }
}

const styles = theme => ({
  icon: theme.icons.reportsIcon,
  red: theme.icons.red,
  yellow: theme.icons.yellow,
  green: theme.icons.green,
  paper: {
    padding: theme.spacing(1) * 1.25
  },
  sampleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Report))

  //////////////////////////////////////////////////////////////////////////////
