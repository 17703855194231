import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DoneIcon from '@material-ui/icons/Done'

import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showError, showNotification } from '../app/AppActions'
import { openDeleteDialog, closeDeleteDialog } from './MaintenanceActions'
import { browserHistory } from 'react-router'
import { maintenance } from '../api'

//////////////////////////////////////////////////////////////////////////////

const deleteDialog = (id, deleteDialogOpen, onDeleteRecord, onCloseDeleteDialog) => {
  return (
    <Dialog open={deleteDialogOpen} onClose={onCloseDeleteDialog}>
      <DialogTitle>{'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will completely remove the maintenance record.
      </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDeleteDialog}>Cancel</Button>
        <Button onClick={() => onDeleteRecord(id)}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}

export class MaintenanceEditHeader extends React.Component {
  render() {
    const { id, record, deleteDialogOpen, classes } = this.props
    const { onCreateRecord, onUpdateRecord, onDeleteRecord } = this.props
    const { onOpenDeleteDialog, onCloseDeleteDialog } = this.props

    let deleteButton, storeButton, deleteButton2, storeButton2
    if (id == null) {
      storeButton = (
        <Button startIcon={<DoneIcon />} onClick={() =>
          onCreateRecord(record)}
        >
          Create
        </Button>
      )
      storeButton2 = (
        <IconButton onClick={() => onCreateRecord(record)} >
          <DoneIcon />
        </IconButton>
      )
    } else {
      deleteButton = (
        <Button startIcon={<DeleteForeverIcon />} onClick={onOpenDeleteDialog} >
          Delete
        </Button>
      )
      deleteButton2 = (
        <IconButton onClick={onOpenDeleteDialog} >
          <DeleteForeverIcon />
        </IconButton>
      )
      storeButton = (
        <Button startIcon={<DoneIcon />} onClick={() => onUpdateRecord(id, record)} >
          Save
        </Button>
      )
      storeButton2 = (
        <IconButton onClick={() => onUpdateRecord(id, record)} >
          <DoneIcon />
        </IconButton>
      )
    }

    return (
      <Toolbar>
        <div className={classes.desktop} >
          {deleteButton}
          {storeButton}
        </div>
        <div className={classes.tablet}>
          {deleteButton2}
          {storeButton2}
        </div>
        {deleteDialog(id, deleteDialogOpen, onDeleteRecord, onCloseDeleteDialog)}
      </Toolbar>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    deleteDialogOpen: state.maintenance.deleteDialogOpen,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRecord: (record) => {
      if (record.modified) {
        delete record.modified
      }
      maintenance.create(record)
        .then(() => {
          dispatch(showNotification(`Maintenance record created`))
          browserHistory.push('/maintenance#0')
        })
        .catch(err => {
          dispatch(showError(err.message))
        })
    },
    onUpdateRecord: (id, record) => {
      if (record.modified) {
        delete record.modified
      }
      maintenance.patch(id, record)
        .then(() => {
          dispatch(showNotification(`Maintenance record updated`))
          browserHistory.push('/maintenance#0')
        })
        .catch(err => {
          dispatch(showError(err.message))
        })
    },
    onDeleteRecord: (id) => {
      maintenance.remove(id)
        .then(() => {
          dispatch(closeDeleteDialog())
          dispatch(showNotification(`Maintenance record deleted`))
          browserHistory.push('/maintenance#0')
        })
        .catch(err => {
          dispatch(closeDeleteDialog())
          dispatch(showError(err.message))
        })
    },
    onOpenDeleteDialog: () => {
      dispatch(openDeleteDialog())
    },
    onCloseDeleteDialog: () => {
      dispatch(closeDeleteDialog())
    },
  }
}

const styles = theme => ({
  desktop: theme.desktop,
  tablet: theme.tablet
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MaintenanceEditHeader))

//////////////////////////////////////////////////////////////////////////////
