const maintenanceState = {
  deleteDialogOpen: false,
  backDialogOpen: false,
  editRecord: {},
  records: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
}

export default (state = maintenanceState, action) => {
  switch (action.type) {
    case 'OPEN_MAINTENANCE_DELETE_DIALOG':
      return Object.assign({}, state, { deleteDialogOpen: true })
    case 'CLOSE_MAINTENANCE_DELETE_DIALOG':
      return Object.assign({}, state, { deleteDialogOpen: false })
    case 'OPEN_MAINTENANCE_BACK_DIALOG':
      return Object.assign({}, state, { backDialogOpen: true })
    case 'CLOSE_MAINTENANCE_BACK_DIALOG':
      return Object.assign({}, state, { backDialogOpen: false })
    case 'SET_EDIT_MAINTENANCE_RECORD':
      return Object.assign({}, state, { editRecord: Object.assign({}, action.record) })
    case 'SET_MAINTENANCE_RECORDS':
      return Object.assign({}, state, { records: action.records })
    default:
      return state
  } 
}
