import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DoneIcon from '@material-ui/icons/Done'
import LockIcon from '@material-ui/icons/Lock';

import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showError, showNotification, authUser } from '../app/AppActions'
import { openDeleteDialog, closeDeleteDialog } from './AdminActions'
import { browserHistory } from 'react-router'
import { users } from '../api'

//////////////////////////////////////////////////////////////////////////////

const deleteDialog = (editUser, deleteDialogOpen, onDeleteUser, onCloseDeleteDialog) => {
  let hasTriggers = editUser.triggers.length !== 0

  return (
    <Dialog open={deleteDialogOpen} onClose={onCloseDeleteDialog}>
      <DialogTitle>{'Delete user'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will completely delete the user.
        </DialogContentText>
        {hasTriggers ? <DialogContentText>{triggerNotification(editUser)}</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDeleteDialog}>Cancel</Button>
        <Button onClick={() => onDeleteUser(editUser)}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}

const triggerNotification = (editUser) => {
  let triggerText = "NOTE: This user has Triggers. Deleting the user will delete the triggers aswell."

  var emails = []
  var phones = []
  editUser.triggers.forEach(d => {
    d.action.send.forEach(send => {
      if (send.includes("mailto")) {
        emails.push(send.replace("mailto:", ""))
      } else {
        phones.push(send.replace("tel:", ""))
      }
    })
  });
  var emailText = null
  var phonesText = null
  var media
  if (emails.length !== 0) {
    media = "emails"
    emailText = <React.Fragment>{[...new Set(emails)].join(", ")}<br /></React.Fragment>
  }
  if (phones.length !== 0) {
    if (emails.length !== 0) media = "emails and phone numbers"
    else media = "phone numbers"
    phonesText = <React.Fragment>{[...new Set(phones)].join(", ")}<br /></React.Fragment>
  }
  return (
    <React.Fragment>
      <strong>{triggerText}</strong>
      <br />
      {emails.length !== 0 || phones.length !== 0 ? <React.Fragment>{`Following ${media} will no longer receive alerts from this user's triggers`}<br /><br /></React.Fragment> : null}
      {emailText}
      {phonesText}
    </React.Fragment>
  )
}


export class UsersEditHeader extends React.Component {
  render() {
    const { user, editUser, deleteDialogOpen, classes } = this.props
    const { onCreateUser, onUpdateUser, onResetPass, onDeleteUser } = this.props
    const { onOpenDeleteDialog, onCloseDeleteDialog } = this.props

    if (editUser.email == null) {
      return (
        <div />
      )
    }

    let deleteButton, storeButton, deleteButton2, storeButton2, resetButton, resetButton2
    if (editUser._id == null) {
      storeButton = (
        <Button startIcon={<DoneIcon />} onClick={() => onCreateUser(editUser)} >Create</Button>
      )
      storeButton2 = (
        <IconButton onClick={() => onCreateUser(editUser)} >
          <DoneIcon />
        </IconButton>
      )
    } else {
      deleteButton = (
        <Button startIcon={<DeleteForeverIcon />} onClick={onOpenDeleteDialog} disabled={user.email === editUser.email || editUser.roles.includes('superadmin')}  >
          Delete
        </Button>
      )
      deleteButton2 = (
        <IconButton onClick={onOpenDeleteDialog} disabled={user.email === editUser.email || editUser.roles.includes('superadmin')} >
          <DeleteForeverIcon />
        </IconButton>
      )
      resetButton = (
        <Button startIcon={<LockIcon />} onClick={() => onResetPass(editUser)} disabled={editUser.oauth} >
          Reset password
        </Button>
      )
      resetButton2 = (
        <IconButton onClick={() => onResetPass(editUser)} disabled={editUser.oauth} >
          <LockIcon />
        </IconButton>
      )
      storeButton = (
        <Button startIcon={<DoneIcon />} onClick={() => onUpdateUser(editUser)}>
          Save
        </Button>
      )
      storeButton2 = (
        <IconButton onClick={() => onUpdateUser(editUser)}>
          <DoneIcon />
        </IconButton>
      )
    }

    return (
      <Toolbar>
        <div className={classes.desktop}>
          {deleteButton}
          {resetButton}
          {storeButton}
        </div>
        <div className={classes.tablet}>
          {deleteButton2}
          {resetButton2}
          {storeButton2}
        </div>
        {deleteDialog(editUser, deleteDialogOpen, onDeleteUser, onCloseDeleteDialog)}
      </Toolbar>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    user: state.user,
    editUser: state.admin.editUser,
    deleteDialogOpen: state.admin.deleteDialogOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateUser: (user) => {
      delete user.modified;
      const query = { email: user.email };
      users.query(query)
        .then(result => {
          if (result.data.length === 0) {
            users.create(user)
              .then(() => {
                dispatch(showNotification(`${user.email} created`));
                browserHistory.push('/admin/users#0');
              })
              .catch(err => {
                dispatch(showError(err.message));
              });
          }
          else {
            dispatch(showNotification("A user with an identical e-mail address already exists"));
          }
        })
        .catch(err => {
          dispatch(showError(err.message));
        });
    },
    onUpdateUser: (user) => {
      delete user.modified
      users.patch(user._id, user)
        .then(() => {
          dispatch(showNotification(`${user.email} updated`))
          users.authenticate()
            .then(auth => dispatch(authUser(auth.user)))
          browserHistory.push('/admin/users#0')
        })
        .catch(err => {
          dispatch(showError(err.message))
        })
    },
    onResetPass: (user) => {
      users.requestPasswordResetAdmin(user.email)
        .then(() => {
          dispatch(showNotification(`${user.email} password reset requested`))
        })
        .catch(err => {
          dispatch(showError(err.message))
        })
    },
    onDeleteUser: (user) => {
      users.remove(user._id)
        .then(() => {
          dispatch(closeDeleteDialog())
          dispatch(showNotification(`${user.email} deleted`))
          browserHistory.push('/admin/users#0')
        })
        .catch(err => {
          dispatch(closeDeleteDialog())
          dispatch(showError(err.message))
        });
    },
    onOpenDeleteDialog: () => {
      dispatch(openDeleteDialog())
    },
    onCloseDeleteDialog: () => {
      dispatch(closeDeleteDialog())
    },
  }
}

const styles = theme => ({
  desktop: theme.desktop,
  tablet: theme.tablet
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersEditHeader))

//////////////////////////////////////////////////////////////////////////////
