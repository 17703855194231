import React from 'react'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

export default ({ columns, tableCellClassName }) => {
  return (
    <TableHead >
      <TableRow enabled="true" >
        {columns.map((column, i) =>
          <TableCell key={i} className={tableCellClassName || ''} align={tableCellClassName && i !== 0 ? "center" : "inherit"}>
            {column}
          </TableCell>
          )}
      </TableRow>
    </TableHead>
  )
}
