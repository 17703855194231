import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import Toolbar from '../core/Toolbar'

import AddIcon from '@material-ui/icons/Add'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

import { CookieDisclaimer } from '../home/CookieDisclaimer'

//////////////////////////////////////////////////////////////////////////////

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { showNotification } from '../app/AppActions'
import { setRecords } from './MaintenanceActions'
import { maintenance } from '../api'

//////////////////////////////////////////////////////////////////////////////

const recordsList = (records, onSelectRecord) => {
  return (
    <List >
      {records === undefined || records.length === 0 ? <ListItem disabled={true} primarytext='No records.' /> : null}
      {records === undefined ? null : records.sort((a, b) => a.startedAt < b.startedAt ? 1 : a.startedAt > b.startedAt ? -1 : 0).map(record => {
        let leftIcon, primaryText, secondaryText

        leftIcon = <AccessTimeIcon />
        primaryText = `${record.startedAt} — ${record.endedAt} / ${record.title} / ${record.userName} (${record.userEmail})`
        secondaryText =
          <React.Fragment>
            {record.description}
            <br />
            {record.tags.filter(n => n.length > 0).map(n => '#' + n).join(' ')}
          </React.Fragment>

        return (
          <ListItem button key={record._id} onClick={() => onSelectRecord(record)}>
            <ListItemIcon>{leftIcon}</ListItemIcon>
            <ListItemText primary={primaryText} secondary={secondaryText} />
          </ListItem>
        )
      })}
    </List>
  )
}

class Maintenance extends React.Component {
  constructor(props) {
    super(props)
    this.page = () => {
      const { query } = this.props.location
      return query && query.page ? parseInt(query.page) : 1
    }
  }
  componentDidMount() {
    this.props.onFetchRecords(this.page())
  }
  render() {
    const { records, onSelectRecord, onAddRecord } = this.props
    return (
      <div>
        <CookieDisclaimer />
        <Toolbar title='Maintenance'>
          <Button onClick={onAddRecord} startIcon={<AddIcon />}>
            Add record
          </Button>
        </Toolbar>
        <Paper elevation={4}>
          {recordsList(records.data, onSelectRecord)}
        </Paper>
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    records: state.maintenance ? state.maintenance.records : [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectRecord: (maintenance) => {
      browserHistory.push('/maintenance/' + maintenance._id)
    },
    onFetchRecords: (page) => {
      maintenance.list(page)
        .then(result => dispatch(setRecords(result)))
        .catch(err => dispatch(showNotification(err.message)))
    },
    onAddRecord: () => {
      browserHistory.push('/maintenance/new')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance)

//////////////////////////////////////////////////////////////////////////////
