import React from 'react'

import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles'
import CollapsePaper from '../core/CollapsePaper'
import { getSearchType, renderShipSelect } from '../samples/Samples'

import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { ships } from '../api'
import { IconButton } from '@material-ui/core';

//////////////////////////////////////////////////////////////////////////////

class ThenExclude extends React.Component {
    constructor(props) {
        super(props)
        const filter = this.props.trigger.action.filter
        let selectedVessels = []
        if (filter.mmsi && filter.mmsi.$nin[0].imo) {
            selectedVessels = filter.mmsi.$nin.map(x => x);
            filter.mmsi.$nin = selectedVessels.map(v => v.mmsi);
        }
        this.state = {
            selectedVessels: selectedVessels,
            options: [],
            currSearch: "",
        }
    }

    render() {
        const { onDistinctSearch, onChange, classes } = this.props
        var { selectedVessels, currSearch, options } = this.state

        const searchType = getSearchType(currSearch);

        const onSearchChange = (value) => {
            if (value === "") {
                this.setState({ currSearch: value, options: [] })
                return
            }
            this.setState({ currSearch: value })
            const type = getSearchType(value)
            onDistinctSearch(value, type)
                .then(res => {
                    if (res) {
                        this.setState({ options: res.data })
                    }
                })
                .catch(err => { })
        }

        var noOptionsText;
        if (currSearch === "") {
            noOptionsText = "Start typing MMSI, IMO or Name to search";
        } else if (searchType !== "Name" && currSearch.length < 3) {
            noOptionsText = "Type at least 3 numbers to search with MMSI or IMO"
        } else {
            noOptionsText = `No ships found with ${searchType === "Name" ? "Name" : "MMSI or IMO"} '${currSearch}'`
        }

        const selectedList = selectedVessels.map(vessel => {
            return (
                <Paper elevation={4} key={vessel.mmsi} className={classes.vesselCard}>
                    {renderShipSelect(vessel)}
                    <IconButton onClick={() => {
                        selectedVessels = selectedVessels.filter(v => v.mmsi !== vessel.mmsi)
                        onChange(selectedVessels.map(s => s.mmsi))
                        this.setState({ selectedVessels })
                    }}>
                        <CancelIcon />
                    </IconButton>
                </Paper>
            )
        })
        return (
            <CollapsePaper
                icon={<BlockIcon />}
                text={"Exclude Vessels"}
            >
                <Box display="flex" flexWrap="wrap" >
                    {selectedList}
                </Box>
                <Autocomplete
                    options={options}
                    inputValue={currSearch}
                    getOptionLabel={option => currSearch}
                    getOptionDisabled={(option) => {
                        const mmsis = selectedVessels.map(v => v.mmsi)
                        return mmsis.includes(option.mmsi)
                    }}
                    renderOption={option => (
                        <React.Fragment>
                            {renderShipSelect(option, currSearch)}
                        </React.Fragment>
                    )}
                    noOptionsText={noOptionsText}
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                label="Search Vessel"
                                fullWidth={true}
                                onChange={e => onSearchChange(e.target.value)}
                            />
                        )
                    }}
                    onChange={(e, value) => {
                        if (value) selectedVessels.push(value)
                        onChange(selectedVessels.map(s => s.mmsi))
                        this.setState({ selectedVessels, currSearch: '', options: [] })
                    }}
                    className={classes.autocomplete}
                />
            </CollapsePaper>
        )
    }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        onDistinctSearch: (value, type) => {
            const neededLength = type === "Name" ? 0 : 3;
            if (value.length < neededLength) return new Promise((resolve, reject) => {
                resolve(null)
            });
            return ships.distinct(value, type)
        },
        onFetchShips: (mmsis) => {
            const query = { mmsi: { $in: mmsis } }
            return ships.list(query)
        }
    }
}

const styles = theme => {
    return {
        vesselCard: {
            display: "flex",
            width: "200px",
            maxWidth: "200px",
            padding: "11px",
            marginRight: "0px"
        },
        autocomplete: {
            padding: theme.spacing(1),
            paddingRight: theme.spacing(2)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ThenExclude))

//////////////////////////////////////////////////////////////////////////////