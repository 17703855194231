import React from 'react'

import IfBlock from './TriggerIfBlock'
import ThenTakeBlock from './TriggerThenTakeBlock'
import ThenFilterBlock from './TriggerThenFilterBlock'
import ThenSendBlock from './TriggerThenSendBlock'
import ThenExclude from './TriggerThenExclude'
import TriggerEditHeader from './TriggerEditHeader'
import { getTriggerType } from './Trigger'

import Toolbar from '../core/Toolbar'
import BackDialog from '../core/BackDialog'
import { CookieDisclaimer } from '../home/CookieDisclaimer'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotification } from '../app/AppActions'
import { setEditTrigger, closeBackDialog, openBackDialog } from './TriggerActions'
import { browserHistory } from 'react-router'
import { triggers, ships } from '../api'

import base64 from '../core/base64'
import { Typography } from '@material-ui/core'

//////////////////////////////////////////////////////////////////////////////

class TriggerEdit extends React.Component {

  componentDidMount() {
    this.props.onSetTrigger(this.props.params.id)
  }
  render() {
    const { editTrigger, backDialogOpen, onUpdateTrigger, onConfirmGoBack, onCloseBackDialog, onGoBack, user } = this.props

    let list = <div />
    if (editTrigger != null && editTrigger.condition != null && editTrigger.action != null) {
      let takeBlock, filterBlock
      const type = getTriggerType(editTrigger)
      if (type !== 'match') {
        takeBlock =
          <ThenTakeBlock trigger={editTrigger} onChange={n => {
            editTrigger.action.take = n
            editTrigger.modified = true
            onUpdateTrigger(editTrigger)
          }} />

      }
      filterBlock =
        <ThenFilterBlock trigger={editTrigger} onChange={n => {
          editTrigger.action.filter = n
          editTrigger.modified = true
          onUpdateTrigger(editTrigger)
        }} />


      list =
        <div>
          <IfBlock trigger={editTrigger} user={user} onChange={(n, take) => {
            if (n.match) {
              let { mmsi } = editTrigger.action.filter
              editTrigger.action.filter = n.match;
              if (mmsi) editTrigger.action.filter.mmsi = mmsi;
              delete n.match
            }
            if (take) {
              editTrigger.action.take = take
            }
            editTrigger.condition = n
            editTrigger.modified = true
            onUpdateTrigger(editTrigger)
          }} />
          {takeBlock}
          {filterBlock}
          <ThenExclude trigger={editTrigger} onChange={exclude => {
            if (exclude.length) {
              if (!editTrigger.action.filter) editTrigger.action.filter = {}
              editTrigger.action.filter.mmsi = { $nin: exclude }
            } else {
              if (editTrigger.action.filter) delete editTrigger.action.filter.mmsi
            }
            editTrigger.modified = true
            onUpdateTrigger(editTrigger)
          }} />
          <ThenSendBlock trigger={editTrigger} onChange={n => {
            editTrigger.action.send = n
            editTrigger.modified = true
            onUpdateTrigger(editTrigger)
          }} />
        </div>
    }

    return (
      <div>
        <CookieDisclaimer />
        <Toolbar title={this.props.params.id == null ? 'Create trigger' : 'Edit trigger'} onGoBack={() => onGoBack(editTrigger.modified)} actions={
          <TriggerEditHeader id={this.props.params.id} />
        } >
          {editTrigger.user ?
            <React.Fragment>
              <Typography variant={"h6"} style={{ fontSize: "16px" }} >{`Owner: ${editTrigger.user.name || editTrigger.user.google.fullName}`}</Typography>
            </React.Fragment>
            : null}
        </Toolbar>
        <BackDialog
          backDialogOpen={backDialogOpen}
          onConfirmGoBack={onConfirmGoBack}
          onCloseBackDialog={onCloseBackDialog}
        />
        {list}
      </div>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    editTrigger: state.trigger.editTrigger,
    backDialogOpen: state.trigger.backDialogOpen,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetTrigger: id => {
      dispatch(setEditTrigger({}))
      if (id == null) {
        setTimeout(() => {
          let newTrigger = {
            condition: {
              enabled: true,
              includeDuplicates: false,
            },
            action: {
              take: 'last-match',
              filter: {
                fsc: { $gt: 0.1 },
              },
              send: [],
            },
          }
          dispatch(setEditTrigger(newTrigger))
        }, 0)
      } else {
        triggers.get(id)
          .then(editTrigger => {
            if (editTrigger.action.filter) {
              editTrigger.action.filter = JSON.parse(base64.decode(editTrigger.action.filter))
            }
            const exclude = editTrigger.action.filter.mmsi
            if (exclude) {
              const query = { mmsi: { $in: exclude.$nin } }
              ships.list(query)
                .then(shipRes => {
                  editTrigger.action.filter.mmsi.$nin = shipRes.data
                  dispatch(setEditTrigger(editTrigger))
                })
            } else {
              dispatch(setEditTrigger(editTrigger))
            }
          })
          .catch(err => dispatch(showNotification(err.message)))
      }
    },
    onUpdateTrigger: trigger => {
      dispatch(setEditTrigger(trigger))
    },
    onConfirmGoBack: () => {
      dispatch(closeBackDialog())
      browserHistory.push('/triggers')
    },
    onCloseBackDialog: () => {
      dispatch(closeBackDialog())
    },
    onGoBack: (modified) => {
      if (modified) {
        dispatch(openBackDialog())
      } else {
        browserHistory.push('/triggers')
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerEdit)

//////////////////////////////////////////////////////////////////////////////
