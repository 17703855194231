import React from 'react';

import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import ActionLock from '@material-ui/icons/Lock'

import { users } from '../api'
import { ListItem, ListItemText } from '@material-ui/core';
import { browserHistory } from 'react-router'

class PasswordChange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: '',
      passwordConfirmation: '',
      resetRequest: null,
    }
  }

  render() {
    const { newPassword, passwordConfirmation, resetRequest } = this.state
    const { id, token, onPasswordChange, classes } = this.props

    if (!resetRequest) {
      users.getResetRequest(id).then(res => {
        this.setState({ resetRequest: res })
      })
        .catch(err => {
          this.setState({ resetRequest: { notFound: true } })
        })
      return (
        <div />
      )
    }


    const submitHandler = event => {
      event.preventDefault()
      onPasswordChange(id, token, this.state.newPassword, this.state.passwordConfirmation)
        .then(() => this.setState({
          newPassword: '',
          passwordConfirmation: '',
        }))
    }

    const changePassword = (
      <List subheader={<ListSubheader>{`Password reset for ${resetRequest.email}`}</ListSubheader>}>
        <form onSubmit={submitHandler}>
          <div className={classes.textFieldWrapper}>
            <TextField
              id='password'
              label='New password'
              fullWidth={true}
              value={newPassword}
              onChange={(event, value) => this.setState({ newPassword: event.target.value })}
              type='password'
            // InputLabelProps={{ shrink: true, }}
            />
            <TextField
              id='passwordConfirmation'
              label='Password confirmation'
              fullWidth={true}
              value={passwordConfirmation}
              onChange={(event, value) => this.setState({ passwordConfirmation: event.target.value })}
              type='password'
            // InputLabelProps={{ shrink: true, }}
            />
          </div>
          <div className={classes.bottomDivider} />
          <div className={classes.textFieldWrapper}>
            <Button variant="contained" color='primary' startIcon={<ActionLock />} onClick={submitHandler} >Change password</Button>
          </div>
          <div className={classes.bottomDivider} />
          <input type='submit' style={{ display: 'none' }} />
        </form>
      </List>
    )

    const signinLink = (
      <Link style={{ fontFamily: "inherit" }} onClick={() => browserHistory.push('/signin')}>Sign In</Link>
    )

    const resetLink = (
      <Link style={{ fontFamily: "inherit" }} onClick={() => {
        browserHistory.push('/signin')
        setImmediate(() => {
          browserHistory.push({
            pathname: '/passwordreset',
            search: ''
          })
        })
      }}>new password reset link</Link>
    )

    const invalidLink = (title) => (
      <List subheader={<ListSubheader>{title}</ListSubheader>}>
        <ListItem>
          <ListItemText primary={
            <React.Fragment>
              Please {signinLink} or request a {resetLink}
            </React.Fragment>
          } />
        </ListItem>
      </List>
    )

    let content;
    if (resetRequest.notFound) {
      content = invalidLink("Invalid Link")
    } else if (new Date() > new Date(resetRequest.expiresAt)) {
      content = invalidLink("Link has expired")
    } else if (resetRequest.used) {
      content = invalidLink("Link already used")
    } else {
      content = changePassword
    }

    return (
      <React.Fragment>
        {content}
      </React.Fragment>
    )
  }
}

const styles2 = theme => ({
  textFieldWrapper: theme.textFieldWrapper,
  bottomDivider: theme.app.bottomDivider
})

export default withStyles(styles2)(PasswordChange)
