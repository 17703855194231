import { combineReducers } from 'redux'
import admin from './admin/adminReducer'
import user from './app/userReducer'
import drawer from './app/drawerReducer'
import error from './app/errorReducer'
import header from './app/headerReducer'
import notification from './app/notificationReducer'
import report from './report/reportReducer'
import trigger from './trigger/triggerReducer'
import maintenance from './maintenance/maintenanceReducer'
import profileMenu from './app/profileMenuReducer'
import samples from './samples/sampleReducer'
import summary from './home/SummaryReducer'

export default combineReducers({
  admin,
  user,
  drawer,
  error,
  header,
  notification,
  report,
  trigger,
  maintenance,
  profileMenu,
  samples,
  summary
})
