import React from 'react';

import moment from 'moment-timezone'

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import FlagIcon from '@material-ui/icons/Flag'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import CloseIcon from '@material-ui/icons/Close'

import SamplesEdit from './SamplesEdit'
import Toolbar from '../core/Toolbar'
import Table from '../core/Table'
import getShipType from '../core/getShipType'
import BackDialog from '../core/BackDialog'
import { getMmsi } from '../core/reportUtils'

import { CookieDisclaimer } from '../home/CookieDisclaimer';

//////////////////////////////////////////////////////////////////////////////

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { showNotification, showError } from '../app/AppActions'
import { setSamples, setShip, setEditSample, setNavigateBack, setDeleteDialogOpen } from './SampleActions'
import { samples, ships } from '../api'
import { TextField, Typography } from '@material-ui/core';

//////////////////////////////////////////////////////////////////////////////

export const sampleTypes = [
    { type: "laboratory", label: "Laboratory sample" },
    { type: "handheld", label: "Handheld sample" },
    { type: "independent", label: "Independent analyze" },
    { type: "scrubber", label: "Scrubber ratio" },
    { type: "fsc", label: "Fsc fuel (Unknown source)" },
]

export const typeToLabel = (type) => {
    var toLabel = sampleTypes.find(t => t.type === type || t.label === type)
    if (toLabel) return toLabel.label
    return type
}

export const typeToSQL = (type) => {
    var toLabel = sampleTypes.find(t => t.type === type || t.label === type)
    if (toLabel) return toLabel.type
    return type
}

export const getSearchType = (search) => {
    const regex = /[0-9]/;
    const type = !regex.test(search) ? "Name" : "Number"
    return type;
}

export const renderShipSelect = (ship, search) => {
    const type = getSearchType(search)
    var shipName, shipMmsi, shipImo;
    if (type === "Name") {
        shipName = boldSearched(ship.name, search)
        shipMmsi = getMmsi(ship.mmsi)
        shipImo = ship.imo
    } else {
        shipName = ship.name
        shipMmsi = boldSearched(getMmsi(ship.mmsi), search)
        shipImo = boldSearched(ship.imo, search)
    }

    return (
        <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ width: "50%" }}>{shipName}</span><span>{ship.nationality}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "start" }}>
                <span style={{ width: "40px" }}><small>mmsi</small></span>
                <span>{shipMmsi}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "start" }}>
                <span style={{ width: "40px" }}><small>imo</small></span>
                <span>{shipImo}</span>
            </div>
        </div>
    )
}

const boldSearched = (text, search) => {
    if (!text || !search) return text;
    search = search.toUpperCase()
    text = text.toString()
    const split = text.split(search);
    const bolded = split.map((x, i) => {
        if (i === split.length - 1) return x;
        return (<span key={i}>{x}<b>{search}</b></span>)
    })
    return (
        <span>
            {bolded.map(x => x)}
        </span>
    )
}

function formatDateTime(datetime) {
    const date = moment.tz(datetime, "Europe/Helsinki").format()
    const year = date.substr(0, 4)
    const month = date.substr(5, 2)
    const day = date.substr(8, 2)
    const time = date.substr(11, 5)
    const res = `${day}.${month}.${year} ${time}`
    return res;
  }

class Samples extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: [],
            deleteId: null,
            currSearch: "",
        }
    }

    render() {
        const { backNavigation, samples, selectedShip, deleteDialogOpen, classes } = this.props
        const { onSetEditSample, onSetShip, onClearShip, onSetDeleteDialogOpen, onSetNavigateBack, onDistinctSearch, onDeleteSample } = this.props
        var { options, deleteId, currSearch } = this.state;

        const searchType = getSearchType(currSearch);
        let selectVessel, sampleTableContent, data, noOptionsText;

        const getFlag = (sample) => {
            if (sample.type === "scrubber") return <FlagIcon className={`${classes.icon} ${sample.sample > 4.3 ? classes.red : classes.green}`} />
            return <FlagIcon className={`${classes.icon} ${sample.sample > 0.1 ? classes.red : classes.green}`} />
        }

        const onChange = (value) => {
            this.setState({ currSearch: value })
            const type = getSearchType(value)
            onDistinctSearch(value, type)
                .then(res => {
                    if (res) {
                        this.setState({ options: res.data })
                    }
                })
                .catch(err => { })
        }

        if (currSearch === "") {
            noOptionsText = "Start typing MMSI, IMO or Name to search";
        } else if (searchType !== "Name" && currSearch.length < 3) {
            noOptionsText = "Type at least 3 numbers to search with MMSI or IMO"
        } else {
            noOptionsText = `No ships found with ${searchType === "Name" ? "Name" : "MMSI or IMO"} '${currSearch}'`
        }

        selectVessel = (
            <React.Fragment>
                <List style={{ maxWidth: '500px' }} subheader={<ListSubheader>Vessel selection</ListSubheader>}>
                    <ListItem>
                        <Autocomplete
                            options={options}
                            disableClearable={true}
                            noOptionsText={noOptionsText}
                            inputValue={currSearch}
                            filterOptions={(op, state) => op}
                            renderOption={option => renderShipSelect(option, currSearch)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Search Vessel"
                                    fullWidth={true}
                                    onChange={e => onChange(e.target.value)}
                                />
                            )}
                            onChange={(e, value) => {
                                // this.setState({ options: [], currSearch: "" })
                                if (!value) return
                                onSetShip(value)
                            }}
                            className={classes.autoComplete}
                        />
                    </ListItem>
                </List>
            </React.Fragment>
        )

        const columns = [<span>Time</span>, <span>Type</span>, <span>Sample</span>, <div className={classes.textRowDivider}><span style={{ width: "40%", maxWidth: "50px" }}>Edit</span><span>Delete</span></div>]
        if (samples.length) {
            data = samples.map(n => {
                const percent = n.type !== "scrubber" ? " %" : `(FSC ${((n.sample * 0.23) / 10).toFixed(2)} %)`;
                return [
                    <span>{formatDateTime(new Date(n.timestamp))}</span>,
                    <span>{typeToLabel(n.type)}</span>,
                    <span>{getFlag(n)} {n.sample} {percent}</span>,
                    <div className={classes.textRowDivider} >
                        <span style={{ width: "60%", maxWidth: "63px" }}>
                            <IconButton className={classes.editbutton} onClick={() => onSetEditSample(n.id, n.shipMmsi, new Date(n.timestamp))}>
                                <CreateIcon />
                            </IconButton>
                        </span>
                        <IconButton className={classes.editbutton} onClick={() => {
                            onSetDeleteDialogOpen(true)
                            this.setState({ deleteId: n.id })
                        }}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </div>
                ]
            })
        }

        var shipType;
        if (selectedShip)
            shipType = getShipType(selectedShip.shipType).replace("HSC", "High Speed Craft");

        const formRow = (arr) => {
            const row = (label, value) => (
                <div className={classes.splitColumnContainer} style={{ width: "150px" }} key={label}>
                    <span><small>{`${label}: `}</small></span><small>{value}</small>
                </div>
            )
            return (
                <React.Fragment>
                    <div className={classes.column}>
                        {arr.map(item => {
                            var value = selectedShip ? selectedShip[item.value] : "";
                            if (item.value === "type") value = shipType
                            else if (item.label === "MMSI" && value !== "") value = getMmsi(value)
                            return row(item.label, value)
                        })}
                    </div>
                </React.Fragment>
            )
        }

        const tableHeader = (
            <React.Fragment>
                <List className={classes.samplesHeader}>
                    <ListItem className={classes.samplesHeader}>
                        <ListItemText primary="Samples" className={classes.samplesHeaderText} />
                        <Tooltip title={!selectedShip ? "Select vessel first" : ""}>
                            <span>
                                <Button
                                    variant={selectedShip ? "contained" : "text"}
                                    color={selectedShip ? "primary" : "default"}
                                    disabled={!selectedShip}
                                    startIcon={<AddIcon />}
                                    onClick={() => onSetEditSample(null, selectedShip.mmsi, null)}>
                                    Add Sample
                            </Button>
                            </span>
                        </Tooltip>
                    </ListItem>
                </List>
            </React.Fragment>
        )

        const tableContent = (
            samples.length ?
                <Table
                    columns={columns}
                    depth={4}
                    hidePager={true}
                    data={data}
                />
                :
                <ListItem>
                    <ListItemText secondary="No samples" />
                </ListItem>
        )

        sampleTableContent = (
            selectedShip ?
                <React.Fragment>
                    <div className={classes.defaultPadding} >
                        <div className={classes.column} >
                            <div style={{ maxWidth: "80%" }}>
                                <div>
                                    <span className={classes.namefont}>{`${selectedShip ? selectedShip.name : "Name"}  /  ${selectedShip ? selectedShip.imo : "IMO"}`}</span>
                                </div>
                                {formRow([{ label: "MMSI", value: "mmsi" }, { label: "ShipType", value: "type" }])}
                                {formRow([{ label: "Beam", value: "beam" }, { label: "Length", value: "length" }])}
                                {formRow([{ label: "Nationality", value: "nationality" }])}
                            </div>
                            <div className={classes.clearButton}>
                                <Tooltip title="Clear selected vessel">
                                    <CloseIcon onClick={() => onClearShip()} />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className={classes.defaultBottomPadding} >
                        {tableHeader}
                        {tableContent}
                    </div>
                </React.Fragment> :
                    <div className={classes.centered} style={{ padding: '1rem' }}>
                        <Typography color="textSecondary" variant="h6">
                            Select a vessel first
                    </Typography>
                    </div>
        )

        var toolbar = backNavigation ?
            <Toolbar title={backNavigation.title} onGoBack={() => {
                browserHistory.push(backNavigation.url)
                onSetNavigateBack(null)
            }} />
            : <Toolbar title="Samples" />
        return (
            <div>
                <CookieDisclaimer />
                {toolbar}
                <Paper elevation={4} >{selectVessel}</Paper>
                <Paper elevation={4} >{sampleTableContent}</Paper>
                <SamplesEdit refresh={() => onSetShip(selectedShip)} />
                <BackDialog
                    backDialogOpen={deleteDialogOpen}
                    onConfirmGoBack={() => onDeleteSample(deleteId, () => onSetShip(selectedShip))}
                    onCloseBackDialog={() => onSetDeleteDialogOpen(false)}
                    title="Delete Sample"
                    contentText="Are you sure?"
                    confirmButtonText="Delete"
                />
            </div>
        )
    }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        samples: state.samples.samples,
        selectedShip: state.samples.selectedShip,
        editSample: state.samples.editSample,
        backNavigation: state.samples.backNavigation,
        deleteDialogOpen: state.samples.deleteDialogOpen,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClearShip: () => {
            dispatch(setShip(null))
            dispatch(setSamples({ total: 0, limit: 0, skip: 0, data: [] }))
        },
        onSetShip: (currentShip) => {
            dispatch(setShip(currentShip))
            const shipMmsi = currentShip.mmsi;
            samples.list(shipMmsi)
                .then(sampleResult => {
                    dispatch(setSamples(sampleResult.data))
                })
                .catch(err => dispatch(showError(err.message)))
        },
        onSetEditSample: (id, mmsi, timestamp) => {
            if (id == null) {
                setTimeout(() => {
                    const newSample = {
                        sample: '',
                        type: "laboratory",
                        shipMmsi: mmsi,
                        timestamp: timestamp,
                        startDate: timestamp,
                        endDate: timestamp,
                    }
                    dispatch(setEditSample(newSample))
                }, 0)
            } else {
                samples.get(id)
                    .then(editSample => {
                        dispatch(setEditSample(editSample))
                    })
                    .catch(err => dispatch(showNotification(err.message)))
            }
        },
        onSetNavigateBack: value => {
            dispatch(setNavigateBack(value))
        },
        onSetDeleteDialogOpen: value => {
            dispatch(setDeleteDialogOpen(value))
        },
        onDistinctSearch: (value, type) => {
            const neededLength = type === "Name" ? 0 : 3;
            if (value.length < neededLength) return new Promise((resolve, reject) => {
                resolve(null)
            });
            return ships.distinct(value, type)
        },
        onDeleteSample: (id, refresh) => {
            samples.remove(id)
                .then((v) => {
                    dispatch(showNotification("Sample Deleted"))
                    dispatch(setDeleteDialogOpen(false))
                    refresh()
                })
                .catch(err => dispatch(showError(err.message)))
        },

    }
}

const styles = theme => {
    const samplesHeader = Object.assign({}, theme.textFieldWrapper)
    samplesHeader.marginLeft = "0px"
    return {
        textFieldWrapper: theme.textFieldWrapper,
        bottomDivider: theme.app.bottomDivider,
        icon: theme.icons.reportsIcon,
        red: theme.icons.red,
        yellow: theme.icons.yellow,
        green: theme.icons.green,
        column: theme.containers.column,
        tablecontainer: theme.textFieldWrapper,
        editbutton: { margin: "0", padding: "0" },
        namefont: { fontSize: "medium" },
        infoContainer: theme.containers.reportDialog,
        samplesHeader: samplesHeader,
        defaultPadding: {
            padding: theme.spacing(2)
        },
        defaultBottomPadding: {
            paddingBottom: theme.spacing(3)
        },
        autoComplete: {
            width: "90%",
            '& > span': {
                marginRight: 10,
                fontSize: 18
            }
        },
        textSearchContainer: theme.containers.reportDialog,
        textRowDivider: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
        },
        centered: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        },
        clearButton: {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%"
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Samples))

  //////////////////////////////////////////////////////////////////////////////
