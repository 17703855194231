import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DoneIcon from '@material-ui/icons/Done'

import { withStyles } from '@material-ui/core/styles'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showError, showNotification } from '../app/AppActions'
import { openDeleteDialog, closeDeleteDialog } from './TriggerActions'
import { browserHistory } from 'react-router'
import { triggers } from '../api'

import base64 from '../core/base64'

//////////////////////////////////////////////////////////////////////////////

const deleteDialog = (editTrigger, deleteDialogOpen, onDeleteTrigger, onCloseDeleteDialog, user) => {
  return (
    <Dialog open={deleteDialogOpen} onClose={onCloseDeleteDialog}>
      <DialogTitle>{'Are you sure'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {editTrigger.user && user._id !== editTrigger.user._id ?
            <React.Fragment>
              {`This trigger's owner is ${editTrigger.user.name || editTrigger.user.google.fullName}.`}<br />
            </React.Fragment>
            : null
          }
          {`This will completely remove the trigger.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDeleteDialog}>Cancel</Button>
        <Button onClick={() => onDeleteTrigger(editTrigger)}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}

export class TriggersEditHeader extends React.Component {
  render() {
    const { id, editTrigger, deleteDialogOpen, user, classes } = this.props
    const { onCreateTrigger, onUpdateTrigger, onDeleteTrigger } = this.props
    const { onOpenDeleteDialog, onCloseDeleteDialog } = this.props

    let deleteButton, storeButton, deleteButton2, storeButton2
    if (id == null) {
      storeButton = (
        <Button startIcon={<DoneIcon />} onClick={() => onCreateTrigger(editTrigger)} >
          Create
        </Button>
      )
      storeButton2 = (
        <IconButton onClick={() => onCreateTrigger(editTrigger)} >
          <DoneIcon />
        </IconButton>
      )
    } else {
      deleteButton = (
        <Button startIcon={<DeleteForeverIcon />} onClick={onOpenDeleteDialog}  >
          Delete
          </Button>
      )
      deleteButton2 = (
        <IconButton onClick={onOpenDeleteDialog} >
          <DeleteForeverIcon />
        </IconButton>
      )
      storeButton = (
        <Button startIcon={<DoneIcon />} onClick={() => onUpdateTrigger(editTrigger)}  >
          Save
        </Button>
      )
      storeButton2 = (
        <IconButton onClick={() => onUpdateTrigger(editTrigger)} >
          <DoneIcon />
        </IconButton>
      )
    }

    return (
      <Toolbar>
        <div className={classes.desktop} >
          {deleteButton}
          {storeButton}
        </div>
        <div className={classes.tablet}>
          {deleteButton2}
          {storeButton2}
        </div>
        {deleteDialog(editTrigger, deleteDialogOpen, onDeleteTrigger, onCloseDeleteDialog, user)}
      </Toolbar>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {
    editTrigger: state.trigger.editTrigger,
    deleteDialogOpen: state.trigger.deleteDialogOpen,
    user: state.user,
  }
}

const triggerReceiversAreValid = (trigger, dispatch) => {
  const invalidPhoneNumbers = trigger.action.send.filter(i => {
    const split = i.split(":")
    if (split[0] === "tel" && split[1].search(/[^+,\s,-,.,0-9]/) !== -1) return true
    return false
  })
  const invalidEmails = trigger.action.send.filter(i => {
    const split = i.split(":")
    if (split[0] === "mailto" && split[1].search(/@/) === -1) return true
    return false
  })

  if (invalidPhoneNumbers.length)
    dispatch(showError("Telephone number should contain only numbers:\r\n " + invalidPhoneNumbers.map(i => i.split(":")[1]).join(", ")))
  if (invalidEmails.length)
    dispatch(showError("Email is missing '@' symbol:\r\n" + invalidEmails.map(i => i.split(":")[1]).join(", ")))

  return invalidPhoneNumbers.length === 0 && invalidEmails.length === 0
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateTrigger: (trigger) => {
      if (triggerReceiversAreValid(trigger, dispatch)) {
        delete trigger.modified
        if (trigger.action.filter) {
          trigger.action.filter = base64.encode(JSON.stringify(trigger.action.filter))
        }

        triggers.create(trigger)
          .then(() => {
            dispatch(showNotification(`Trigger created`))
            browserHistory.push('/triggers#0')
          })
          .catch(err => {
            dispatch(showError(err.message))
          })
      }
    },
    onUpdateTrigger: (trigger) => {
      if (triggerReceiversAreValid(trigger, dispatch)) {
        delete trigger.modified
        if (trigger.action.filter) {
          trigger.action.filter = base64.encode(JSON.stringify(trigger.action.filter))
        }

        triggers.patch(trigger._id, trigger)
          .then(() => {
            dispatch(showNotification(`Trigger updated`))
            browserHistory.push('/triggers#0')
          })
          .catch(err => {
            dispatch(showError('Error updating trigger'))
          })
      }
    },
    onDeleteTrigger: (trigger) => {
      triggers.remove(trigger._id)
        .then(() => {
          dispatch(closeDeleteDialog())
          dispatch(showNotification(`Trigger deleted`))
          browserHistory.push('/triggers#0')
        })
        .catch(err => {
          dispatch(closeDeleteDialog())
          dispatch(showError(err.message))
        })
    },
    onOpenDeleteDialog: () => {
      dispatch(openDeleteDialog())
    },
    onCloseDeleteDialog: () => {
      dispatch(closeDeleteDialog())
    },
  }
}

const styles = theme => ({
  desktop: theme.desktop,
  tablet: theme.tablet
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TriggersEditHeader))

//////////////////////////////////////////////////////////////////////////////
