import React from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Divider from '@material-ui/core/Divider'

import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import DoneIcon from '@material-ui/icons/Done'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import base64 from '../core/base64'


const deleteDialog = (searchId, searchName, onCloseDeleteDialog, onDeleteSearch) => {
  return (
    <Dialog open={searchId ? true : false} onClose={() => onCloseDeleteDialog()}>
      <DialogTitle>{'Delete search "' + searchName + '"?'}</DialogTitle>
      <DialogActions>
        <Button onClick={() => onCloseDeleteDialog()}>Cancel</Button>
        <Button onClick={() => onDeleteSearch(searchId, searchName)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
};

const searchAsString = (search) => {
  let s = "";
  for (let i in search) {
    if (i[0] === "$") {
      continue;
    }
    s += i;
    for (let j in search[i]) {
      switch (j) {
        case "$gt":
          s += " > ";
          break;
        case "$lt":
          s += " < ";
          break;
        case "$in":
          s += " = ";
          break;
        case "$nin":
          s += " ≠ ";
          break;
        default:
          s += "error";
          break;
      }
      s += search[i][j];
    }
    s += ", ";
  }
  return s.slice(0, s.length - 2);
};

export default class ReportLoad extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    const { searches, searchId, searchDelete } = this.props;
    const { onSelectSearch, onOpenDeleteDialog, onCloseDeleteDialog, onDeleteSearch } = this.props;

    var noSearchesBody = <TableRow>
      <TableCell />
      <TableCell>(no searches)</TableCell>
      <TableCell />
    </TableRow>


    var searchList = searches.length === 0
      ?
      noSearchesBody
      : searches.map((n, i) => {
        let q = JSON.parse(base64.decode(n.search.q));
        let qs = searchAsString(q);
        let s = base64.decode(n.search.s);
        return [<TableRow hover key={i}>
          <TableCell key={i} onClick={() => onSelectSearch(q, s, n._id)}>
            {searchId === n._id ?
              <DoneIcon /> : null}
          </TableCell>
          <TableCell onClick={() => onSelectSearch(q, s, n._id)}>
            <strong>{n.name}</strong><br />
            {qs +
              ((!!qs && !!s) ? ', ' : '') +
              (!!s ? 'text: "' + s + '"' : '')
            }
          </TableCell>
          <TableCell>
            <IconButton onClick={() => onOpenDeleteDialog(n._id, n.name)}>
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        </TableRow>];
      });

    const body =
      <React.Fragment>
        <TableRow hover={true} onClick={() => onSelectSearch(JSON.parse('{}'), '', null)}>
          <TableCell key={0} />
          <TableCell>
            <strong>Reset search</strong>
          </TableCell>
          <TableCell />
        </TableRow>
        <React.Fragment>{searchList}</React.Fragment>
      </React.Fragment>


    return (
      <Dialog open={this.props.visible} onClose={this.props.onClose} >
        <DialogTitle>{'Saved searches'}</DialogTitle>
        <Divider />
        <DialogContent>
          <Table >
            <TableBody >
              {body}
            </TableBody>
          </Table>
          {deleteDialog(searchDelete.deleteId, searchDelete.deleteName, onCloseDeleteDialog, onDeleteSearch)}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}