import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { hideError } from './AppActions'

//////////////////////////////////////////////////////////////////////////////

export const Error = ({ text, onHide }) => {
  return (
    <Dialog
      open={text ? text.length > 0 : false}
      keepMounted
      onClose={onHide}
    >
      <DialogTitle>{'Error'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color='primary'
        >{'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    text: state.error ? state.error.text : "",
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideError()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error)

//////////////////////////////////////////////////////////////////////////////
