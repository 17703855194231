import { startCase } from 'lodash';
import React from 'react';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import PasswordChange from './PasswordChange'
import { CookieDisclaimer } from '../home/CookieDisclaimer';

import Toolbar from '../core/Toolbar'

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux';
import { showNotification, showError, updateViewMode } from '../app/AppActions';
import { users } from '../api';
import { ListItemText, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';


//////////////////////////////////////////////////////////////////////////////

const generalItems = ['email', 'name', 'organization']

const roles = (user) => {
    const primaryText = user.roles.length < 2 ? "Role" : "Roles";
    const secondayText = user.roles.join(", ");
    return (
        <ListItem key={"roles"}>
            <ListItemText primary={primaryText} secondary={secondayText} />
        </ListItem>
    )
}

const general = (user) => {
    return (
        <List >
            {generalItems.map(key =>
                <ListItem key={key}>
                    <ListItemText primary={startCase(key)} secondary={user[key]} />
                </ListItem>
            )}
            {roles(user)}
        </List>
    )
}

const generalGoogle = (user) => {
    return (
        <List >
            <ListItem key={'email'}>
                <ListItemText primary={startCase('email')} secondary={user.email} />
            </ListItem>
            <ListItem key={'name'}>
                <ListItemText primary={startCase('name')} secondary={user.google.displayName} />
            </ListItem>
            <ListItem key={'organization'}>
                <ListItemText primary={startCase('organization')} secondary={user.organization} />
            </ListItem>
            {roles(user)}
        </List>
    )
}
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.ToggleButton = this.ToggleButton.bind(this);
    }

    ToggleButton(user, onViewModeChange) {
        const sw =
            <Switch
                checked={user.showExtraInfo}
                color="primary"
                value='showExtra'
                onChange={onViewModeChange}
            />

        return (
            user.organization === 'KINE' && user.hasOwnProperty('showExtraInfo') &&
            <FormGroup >
                <FormLabel component="legend" >Show Extra Info</FormLabel>
                <FormControlLabel control={sw} label={user.showExtraInfo ? 'Show' : 'Hide'} />
            </FormGroup>
        )
    }

    render() {
        const { user, onPasswordChange, onViewModeChange, classes } = this.props

        let left, right
        if (user.googleId != null) {
            left =
                <Paper elevation={4} >
                    {generalGoogle(user)}
                    {this.ToggleButton(user, () => onViewModeChange(user))}
                </Paper>
        } else if (user.email != null) {
            left =
                <Paper elevation={4} >
                    {general(user)}
                    {this.ToggleButton(user, () => onViewModeChange(user))}
                </Paper>
            right =
                <Paper elevation={4} >
                    <PasswordChange onPasswordChange={onPasswordChange} />
                </Paper>
        }

        return (
            <div>
                <CookieDisclaimer />
                <Toolbar title='Profile'></Toolbar>
                <div className={classes.container}>
                    {left}
                    {right}
                </div>
            </div>

        )
    }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onViewModeChange: (user) => {
            if (user.hasOwnProperty('showExtraInfo')) {
                const upDatedUser = {
                    showExtraInfo: !user.showExtraInfo
                }
                users.patch(user._id, upDatedUser)
                    .then((u) => {
                        dispatch(updateViewMode(u.showExtraInfo))
                        if (upDatedUser.showExtraInfo) {
                            dispatch(showNotification('Showing extra info'))
                        } else {
                            dispatch(showNotification('Viewing as Customer'))
                        }
                    })
                    .catch(err => {
                        dispatch(showError(err.message))
                    })
            }
        },
        onPasswordChange: (oldPassword, newPassword, passwordConfirmation) => {
            if (oldPassword.length === 0) {
                dispatch(showNotification('Old password is required'))
                return Promise.resolve(null)
            }
            if (newPassword.length === 0) {
                dispatch(showNotification('New password is required'))
                return Promise.resolve(null)
            }
            if (passwordConfirmation.length === 0) {
                dispatch(showNotification('Password confirmation is required'))
                return Promise.resolve(null)
            }
            if (newPassword !== passwordConfirmation) {
                dispatch(showNotification('New password and password confirmation do not match'))
                return Promise.resolve(null)
            }
            if (oldPassword === newPassword) {
                dispatch(showNotification('Old password and new password are the same'))
                return Promise.resolve(null)
            }
            return users.changePassword(oldPassword, newPassword)
                .then(() => {
                    dispatch(showNotification('Password changed'))
                })
                .catch(err => {
                    dispatch(showNotification(err.message))
                })
        },
    }
}

const styles = theme => ({
    container: theme.containers.twoPaperFlex,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))

//////////////////////////////////////////////////////////////////////////////
