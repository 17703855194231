export const getMmsi = mmsi => {
    if (mmsi.toString().length === 9) return mmsi;
    const missing = 9 - mmsi.toString().length
    const zeros = [...Array(missing).keys()].map(x => '0').join('');
    return zeros + mmsi.toString();
  }

  export const getFscColor = (flag, classes) => {
    switch (flag) {
      case "Red":
        return `${classes.icon} ${classes.red}`;
      case "Orange":
        return `${classes.icon} ${classes.yellow}`;
      case "Green":
        return `${classes.icon} ${classes.green}`;
      default:
        return `${classes.icon}`;
    }
  }