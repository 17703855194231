import React from 'react'
import IconButton from '@material-ui/core/IconButton'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const styles = {
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paginationCenter: {
    flex: 1,
    flexBasis: 'auto',
    textAlign: 'center',
    alignSelf: 'center',
  },
}

export default ({ page, total, limit, onChangePage }) => {
  const first = (page - 1) * limit + 1
  const last = page * limit < total ? page * limit : total
  const pages = Math.ceil(total / limit)
  const text = isNaN(pages) || pages === 0 ? 'No data' : `${first}-${last} / ${total}`
  return (
    <div style={styles.pagination}>
      <IconButton disabled={page <= 1} touch="true" onClick={() => onChangePage(page - 1)}>
        <ArrowBackIcon />
      </IconButton>
      <div style={styles.paginationCenter}>
        {text}
      </div>
      <IconButton disabled={page >= pages || isNaN(pages) || pages === 0} touch="true" onClick={() => onChangePage(page + 1)}>
        <ArrowForwardIcon />
      </IconButton>
    </div>
  )
}
