import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, red, amber, lightGreen } from '@material-ui/core/colors'


const green900 = green[900];

export const AppStyles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#e2e2e2',
  },
  main: {
    display: 'flex',
  },
  content: {
    display: 'block',
    flex: 1,
    flexGrow: 1,
    flexBasis: 'auto',
    width: '100%',
    maxWidth: '100%',
    marginTop: '4rem',
  },
  end: {
    width: '1rem',
    height: '1rem',
  },
}

const defaultTheme = createMuiTheme();
// TODO should desktop -> tablet change be done in 'md' (960px) breakpoint instead of 'lg' (1280px)
// uncommenting the following line will mimic the 'md' setup
// defaultTheme.breakpoints.values.lg = 960;

const defaultMargin = defaultTheme.spacing(1) * 0.6125; // '0.35rem'
const drawerWidth = 200
const mobileDrawerWidth = 274

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: green900
    },
    error: red
  },
  breakpoints: defaultTheme.breakpoints,
  desktop: {
    [defaultTheme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  tablet: {
    [defaultTheme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        margin: defaultMargin
      },
      contained: {
        margin: '0.175rem'
      }
    },
    MuiSelect: {
      select: {
        minWidth: '4.28rem'
      }
    },
    MuiTableCell: {
      head: {
        color: defaultTheme.palette.grey[500]
      },
      sizeSmall: {
        lineHeight: '1rem',
        padding: '0.14rem 1.7rem 0.14rem 1.14rem'
      }
    },
    MuiTypography: {
      h6: {
        margin: defaultMargin
      }
    },
    MuiPaper: {
      elevation1: {
        flex: 1,
        flexBasis: 'auto',
        paddingTop: defaultMargin
      },
      elevation4: {
        flex: 1,
        flexBasis: 'auto',
        margin: '0.35rem 1rem',
        [defaultTheme.breakpoints.down('xs')]: {
          marginLeft: '0px',
          marginRight: '0px'
        }
      }
    },
    MuiFormControl: {
      root: {
        margin: defaultMargin
      }
    },
    MuiToggleButtonGroup: {
      root: {
        margin: defaultMargin,
        flex: 1,
        flexBasis: "auto",
        display: "block"
      }
    },
    MuiToggleButton: {
      root: {
        width: '5rem',
        height: '2.3rem',
        backgroundColor: defaultTheme.palette.grey[300],
        color: defaultTheme.palette.text.primary,
        '&$selected': {
          backgroundColor: green900,
          color: "#fff",
          "&:hover": {
            backgroundColor: "rgb(18, 65, 22)"
          }
        },
      },
    },
    MuiDivider: {
      vertical: {
        marginLeft: defaultTheme.spacing(1) * 3.75,
        width: defaultTheme.spacing(1) / 8,
        height: defaultTheme.spacing(1) * 4.35
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        marginLeft: '50%',
        marginTop: defaultMargin
      }
    },
    MuiFormGroup: {
      root: {
        margin: '8px 16px 8px',
      }
    },
    MuiFormLabel: {
      root: {
        color: defaultTheme.palette.text.primary
      }
    },
    MuiFormControlLabel: {
      root: {
        color: defaultTheme.palette.text.secondary
      }
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: '760px'
      },
      paperWidthXs: {
        maxWidth: '350px'
      }
    }
  },
  containers: {
    app: {
      display: 'flex',
      minHeight: '100vh',
      backgroundColor: '#e2e2e2',
    },
    twoPaperFlex: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    flexPaper: {
      margin: '8px 8px',
      minWidth: '16rem',
      maxWidth: '40rem'
    },
    logo: {
      padding: '2rem',
      paddingTop: '4rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    reportDialog: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    column: {
      flex: 1,
      flexBasis: 'auto',
      display: 'flex',
      lineHeight: '1rem'
    },
    splitColumnContainer: {
      flex: 1,
      flexBasis: 'auto',
      maxWidth: '50%',
    }
  },
  icons: {
    signInLogo: {
      margin: 'auto',
      maxWidth: '100%',
      maxHeight: '8rem'
    },
    mobileNavBarIcon: {
      margin: '0.5rem 0.8rem',
      padding: '9px 6px 3px 6px',
      height: '3.0rem',
    },
    reportsIcon: {
      width: defaultTheme.spacing(1) * 1.75,
      height: defaultTheme.spacing(1) * 1.75,
      verticalAlign: 'text-top'
    },
    red: {
      color: red[900],
      fontSize: "1.25rem"
    },
    yellow: {
      color: amber[500],
      fontSize: "1.25rem"
    },
    green: {
      color: lightGreen[500],
      fontSize: "1.25rem"
    }
  },
  app: {
    bottomDivider: {
      height: defaultTheme.spacing(1)
    }
  },
  navBar: {
    appBar: {
      zIndex: defaultTheme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    content: {
      flexGrow: 1,
      padding: defaultTheme.spacing(3),
    },
    toolbar: defaultTheme.mixins.toolbar,
    menuDivider: {
      background: 'transparent',
      marginTop: '1em'
    },
    drawerPaper: {
      desktop: {
        width: drawerWidth,
        background: 'transparent',
        border: 'none'
      },
      mobile: {
        width: mobileDrawerWidth,
        display: 'block'
      }
    }
  },
  textFieldWrapper: {
    margin: defaultTheme.spacing(1),
    padding: '0 0.5rem',
  },
})

export const AirnowTheme = (props) => {

  return (
    <ThemeProvider theme={muiTheme}>
      <div>
        {props.children}
      </div>
    </ThemeProvider>
  )
}
