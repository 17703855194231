import React from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box';

export default class extends React.Component {
  render() {
    const { title, onGoBack, actions, children } = this.props

    let goBack
    if (onGoBack) {
      goBack =
        <IconButton onClick={onGoBack}>
          <ArrowBack />
        </IconButton>
    }

    let actionSeparator, childrenSeparator

    if (actions) actionSeparator = <Divider variant="middle" orientation="vertical" />
    if (children) childrenSeparator = <Divider variant="middle" orientation="vertical" />

    return (
      <div>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          p={1}
          m={1}
        >
          <Box display="flex" alignItems="center" p={1}>
            {goBack}
            <Typography variant={"h6"} >{title}</Typography>
            {actionSeparator}
          </Box>
          <Box display="flex" alignItems="center" p={1} >
            {actions}
            {childrenSeparator}
          </Box>
          <Box display="flex" alignItems="center" p={1} >
            {children}
          </Box>
        </Box>
      </div>
    )
  }
}
