import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles';

import clsx from "clsx";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import IconButton from "@material-ui/core/IconButton";

import { sampleTypes, typeToLabel, typeToSQL } from './Samples'
import BackDialog from '../core/BackDialog'

import FlagIcon from '@material-ui/icons/Flag'
import EventIcon from '@material-ui/icons/Event';

//////////////////////////////////////////////////////////////////////////////

import { connect } from 'react-redux'
import { showNotification, showError } from '../app/AppActions'
import { setBackDialogOpen, setEditDialogOpen, setEditSample } from './SampleActions'
import { samples } from '../api'

//////////////////////////////////////////////////////////////////////////////


const validateSample = (value, sample) => {
    const regex = /^((\d+(\.|,)\d*)|\d*)$/;
    value = value.replace(" ", "")
    if (regex.test(value)) return value.replace(",", ".");
    if (!value) value = 0;
    if (isNaN(value)) return sample
    return value
}

const cloneFromDatePart = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

class SamplesEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: 0
        }
    }

    renderStartDate = (date, selectedDate, dayInCurrentMonth) => {
        const { editSample } = this.props;

        const start = cloneFromDatePart(selectedDate._d);
        const end = editSample.endDate;
        const isForbiddenDate = cloneFromDatePart(date._d) > editSample.endDate;

        return this.renderWrappedWeekDay(date, selectedDate, dayInCurrentMonth, start, end, isForbiddenDate)
    }

    renderEndDate = (date, selectedDate, dayInCurrentMonth) => {
        const { editSample } = this.props;

        const start = editSample.startDate;
        const end = cloneFromDatePart(selectedDate._d)
        const isForbiddenDate = cloneFromDatePart(date._d) < editSample.startDate;

        return this.renderWrappedWeekDay(date, selectedDate, dayInCurrentMonth, start, end, isForbiddenDate)
    }

    renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth, start, end, isForbiddenDate) => {
        const { classes, editSample } = this.props;

        let dateClone = cloneFromDatePart(date._d) // date of the calndar to render;

        const dayIsBetween = isWithinInterval(dateClone, { start, end });
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);
        const isSelectedDate = isSameDay(dateClone, editSample.timestamp)

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
            [classes.nonCurrentMonthDay]: isForbiddenDate,
            [classes.selectedDateBetween]: isSelectedDate && dayIsBetween,
            [classes.selectedDateOutSide]: isSelectedDate && !dayIsBetween
        });

        const fontClassName = clsx(classes.calendarFont, {
            [classes.selectedDateFont]: isSelectedDate
        })

        const formatted = dayInCurrentMonth ? format(dateClone, 'd') : "";

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName} disabled={!dayInCurrentMonth || isForbiddenDate}>
                    <span className={fontClassName}> {formatted} </span>
                </IconButton>
            </div>
        );
    };

    render() {
        const { refresh, editDialogOpen, onCloseDialog, backDialogOpen, onUpdateOrCreateSample, onUpdateSample, classes } = this.props
        const { onSetBackDialogOpen } = this.props
        const { error } = this.state;

        var { editSample } = this.props

        if (!editSample.id) { // New sample, init dates for today
            editSample.timestamp = cloneFromDatePart(new Date())
            editSample.startDate = cloneFromDatePart(new Date())
            editSample.endDate = cloneFromDatePart(new Date())
            editSample.id = -1
        }

        if (typeof editSample.timestamp === "string") { // Fetched from database, edit strings into date format
            editSample.timestamp = cloneFromDatePart(new Date(editSample.timestamp))
            editSample.startDate = cloneFromDatePart(new Date(editSample.startDate))
            editSample.endDate = cloneFromDatePart(new Date(editSample.endDate))
        }

        const { type, sample, id, startDate, endDate, timestamp } = editSample

        let startAdornment, endAdornment, flagItem, helperText;

        if (typeToLabel(type) !== "Scrubber ratio") {
            startAdornment = <InputAdornment position="start">%</InputAdornment>
            flagItem = sample !== 0 ? <FlagIcon edge='start' className={`${classes.icon} ${sample > 0.1 ? classes.red : classes.green}`} /> : null
            endAdornment = flagItem ? <InputAdornment position="end">
                {flagItem}
            </InputAdornment> : null;
            helperText = " "
        } else { // Type = scrubber
            endAdornment = <InputAdornment position="end">ppm/vol%</InputAdornment>
            flagItem = <FlagIcon className={`${classes.icon} ${sample > 4.3 ? classes.red : classes.green}`} />
            helperText = sample !== 0 ?
                <span>{`= ${(sample * 0.23 / 10).toFixed(2)}% FSC`}{flagItem}</span>
                : "= -.-- % FSC   "
        }

        const sampleIsValid = (sample) => {
            const sampleFloat = parseFloat(sample)
            if (isNaN(sampleFloat)) {
                this.setState({ error: 1 })
                return false;
            }
            if (sampleFloat > 100) {
                this.setState({ error: 2 })
                return false;
            }
            return true;
        }

        const title = id ? "Edit Sample" : "New Sample";

        return (
            <div>
                <Dialog
                    open={editDialogOpen}
                    onClose={() => onCloseDialog(editSample)}
                    maxWidth='xs'
                    fullWidth={true}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <List>
                            <ListItem>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        id='startedAtDate'
                                        className={classes.datePicker}
                                        views={["year", "month", "date"]}
                                        showTodayButton
                                        fullWidth={true}
                                        format="DD.MM.YYYY"
                                        minDate={new Date(2016, 0, 1)}
                                        maxDate={new Date()}
                                        value={timestamp}
                                        onChange={(date) => {
                                            editSample.timestamp = cloneFromDatePart(date._d);
                                            onUpdateSample(editSample)
                                        }}
                                        TextFieldComponent={(props) => (
                                            <TextField
                                                label="Sample Date"
                                                onClick={props.onClick}
                                                value={props.value}
                                                fullWidth={true}
                                                InputProps={{
                                                    endAdornment: <EventIcon />
                                                }}
                                            />
                                        )}
                                    />
                                </MuiPickersUtilsProvider>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    select
                                    fullWidth={true}
                                    label="Sample Type"
                                    value={typeToLabel(type)}
                                    onChange={e => {
                                        editSample.type = e.target.value
                                        onUpdateSample(editSample)
                                    }}
                                >
                                    {sampleTypes.map(item => {
                                        if (item.type === "fsc" && (!id || editSample.type !== "fsc")) {
                                            return null         // 'Old fsc' not shown for new samples or old samples that are != 'fsc'
                                        }
                                        return (
                                            <MenuItem key={item.type} value={item.label} disabled={item.type === "fsc"}>
                                                {item.label}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    error={!!error}
                                    label="Sample"
                                    value={sample}
                                    fullWidth={true}
                                    placeholder="0"
                                    onChange={e => {
                                        editSample.sample = validateSample(e.target.value, sample)
                                        onUpdateSample(editSample)
                                    }}
                                    InputProps={{
                                        endAdornment: endAdornment,
                                        startAdornment: startAdornment
                                    }}
                                    helperText={error === 0 ? helperText : (error === 1 ? "Sample value can not be empty" : "Sample value can not be over 100%")}
                                />
                            </ListItem>
                            <ListItem>
                                <div className={classes.validDateContainer}>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <DatePicker
                                            value={startDate}
                                            views={["year", "month", "date"]}
                                            fullWidth={true}
                                            format="DD.MM.YYYY"
                                            maxDate={endDate}
                                            onChange={(date) => {
                                                editSample.startDate = cloneFromDatePart(date._d)
                                                onUpdateSample(editSample)
                                            }}
                                            TextFieldComponent={(props) => (
                                                <TextField
                                                    className={classes.timePicker}
                                                    label='Valid From'
                                                    onClick={props.onClick}
                                                    value={props.value}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: <EventIcon />
                                                    }}
                                                />
                                            )}
                                            renderDay={this.renderStartDate}
                                        />
                                        <DatePicker
                                            value={endDate}
                                            views={["year", "month", "date"]}
                                            fullWidth={true}
                                            format="DD.MM.YYYY"
                                            minDate={startDate}
                                            onChange={(date) => {
                                                editSample.endDate = cloneFromDatePart(date._d)
                                                onUpdateSample(editSample)
                                            }}
                                            TextFieldComponent={(props) => (
                                                <TextField
                                                    className={classes.timePicker}
                                                    label='To'
                                                    onClick={props.onClick}
                                                    value={props.value}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: <EventIcon />
                                                    }}
                                                />
                                            )}
                                            renderDay={this.renderEndDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            onCloseDialog(editSample)
                            this.setState({ error: 0 })
                        }}>Cancel</Button>
                        <Button onClick={() => {
                            if (sampleIsValid(editSample.sample)) {
                                onUpdateOrCreateSample(editSample, refresh)
                                this.setState({ error: 0 })
                            }
                        }}>Save</Button>
                    </DialogActions>
                </Dialog>
                <BackDialog
                    backDialogOpen={backDialogOpen}
                    onConfirmGoBack={() => {
                        onSetBackDialogOpen(false)
                        delete editSample.edited
                        onCloseDialog(editSample)
                    }}
                    onCloseBackDialog={() => onSetBackDialogOpen(false)}
                />
            </div>
        )
    }
}

//////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state) => {
    return {
        editDialogOpen: state.samples.editDialogOpen,
        editSample: state.samples.editSample,
        backDialogOpen: state.samples.backDialogOpen,
        deleteDialogOpen: state.samples.deleteDialogOpen,
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseDialog: sample => {
            if (!sample.id || !sample.edited) {
                dispatch(setEditDialogOpen(false))
            }
            else if (sample.edited) {
                dispatch(setBackDialogOpen(true))
            }
        },
        onUpdateSample: sample => {
            sample.edited = true
            dispatch(setEditSample(sample))
        },
        onUpdateOrCreateSample: (sample, refresh) => {
            const newSample = Object.assign({}, sample)
            newSample.type = typeToSQL(sample.type)
            delete newSample.edited
            if (newSample.id > 0) {
                dispatch(setEditDialogOpen(false))
                samples.patch(newSample.id, newSample)
                    .then(() => {
                        dispatch(showNotification("Sample edited"))
                        refresh()
                    })
                    .catch(err => dispatch(showError(err.message)))
            } else {
                delete newSample.id
                dispatch(setEditDialogOpen(false))
                samples.create(newSample)
                    .then(() => {
                        dispatch(showNotification("New sample added"))
                        refresh()
                    })
                    .catch(err => dispatch(showError(err.message)))
            }
        },
        onSetBackDialogOpen: value => {
            dispatch(setBackDialogOpen(value))
        }
    }
}

const styles = theme => ({
    timePicker: {
        maxWidth: "115px"
    },
    icon: theme.icons.reportsIcon,
    red: theme.icons.red,
    yellow: theme.icons.yellow,
    green: theme.icons.green,
    dayWrapper: {
        position: "relative",
    },
    validDateContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
    selectedDateBetween: {
        border: `3px solid ${theme.palette.common.white}`,
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: "2px",
        maxHeight: "32px"
    },
    selectedDateOutSide: {
        border: `3px solid ${theme.palette.primary.main}`,
        background: theme.palette.common.white,
        // color: theme.palette.common.white,
        margin: "2px",
        maxHeight: "32px"
    },
    calendarFont: {
        fontSize: "0.875rem",
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        fontWeight: 400,
        lineHeight: 0.43,
        letterSpacing: "0.01071em",
    },
    selectedDateFont: {
        marginTop: "-2px"
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SamplesEdit))

//////////////////////////////////////////////////////////////////////////////