import React from 'react'

import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import ActionLock from '@material-ui/icons/Lock'


class RequestReset extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  render() {
    const { email } = this.state
    const { onRequestReset, classes } = this.props

    const submitHandler = event => {
      event.preventDefault()
      onRequestReset(this.state.email)
        .then(() => this.setState({
          email: '',
        }))
    }

    return (
      <List subheader={<ListSubheader>Request Password Reset</ListSubheader>}>
        <form onSubmit={submitHandler}>
          <div className={classes.textFieldWrapper}>
            <TextField
              id='email'
              label='E-mail'
              fullWidth={true}
              value={email}
              onChange={(event, value) => this.setState({ email: event.target.value })}
              type='email'
            />
          </div>
          <div className={classes.bottomDivider} />
          <div className={classes.textFieldWrapper}>
            <Button variant="contained" color="primary" startIcon={<ActionLock />} onClick={submitHandler}>Request reset</Button>
          </div>
          <div className={classes.bottomDivider} />
          <input type='submit' style={{ display: 'none' }} />
        </form>
      </List>
    )
  }
}

const styles = theme => ({
  bottomDivider: theme.app.bottomDivider,
  textFieldWrapper: theme.textFieldWrapper
})

export default withStyles(styles)(RequestReset)
