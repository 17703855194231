export const showError = text => {
  return { type: 'SHOW_ERROR', text }
}

export const hideError = () => {
  return { type: 'HIDE_ERROR' }
}

//////////////////////////////////////////////////////////////////////////////

export const showNotification = text => {
  return { type: 'SHOW_NOTIFICATION', text }
}

export const hideNotification = () => {
  return { type: 'HIDE_NOTIFICATION' }
}

//////////////////////////////////////////////////////////////////////////////

export const openDrawer = () => {
  return { type: 'OPEN_DRAWER' }
}

export const closeDrawer = () => {
  return { type: 'CLOSE_DRAWER' }
}

export const openProfileMenu = (target) => {
  return { type: "OPEN_PROFILE_MENU", target }
}

export const closeProfileMenu = () => {
  return { type: "CLOSE_PROFILE_MENU" }
}

export const authUser = user => {
  return { type: 'AUTH', user }
}

export const deauthUser = () => {
  return { type: 'DEAUTH' }
}

export const updateViewMode = showExtraInfo => {
  return { type: 'UPDATE_VIEW_MODE', showExtraInfo }
}

//////////////////////////////////////////////////////////////////////////////
