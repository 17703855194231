import React from 'react'
import CookieConsent from 'react-cookie-consent'

export class CookieDisclaimer extends React.Component {

  render() {
    return (
      <div>
        {/* {Cookies.remove("kineCookieDisclaimerCookie")} */}
        <CookieConsent
          cookieName="kineCookieDisclaimerCookie"
          buttonText="Close disclaimer"
          style={{ 'backgroundColor': 'black', 'opacity': 0.85 }}
          buttonStyle={{
            'background-color': 'white', 'color': 'black', 'fontWeight': 'bold',
            'fontSize': '1.025em', 'paddingLeft': '1.5em', 'paddingRight': '1.5em'
          }}>
          AirNow uses cookies to provide smooth and seamless user experience. By using the service, you accept the use of cookies.
      </CookieConsent>
      </div>
    )
  }
}