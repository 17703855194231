import React from 'react'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

export default class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0
        }
    }

    render() {
        const { tabContent } = this.props
        const { value } = this.state

        const content = tabContent.filter((c, i) => i === value)[0].content
        return (
            <React.Fragment>
                <Tabs
                    indicatorColor="primary"
                    variant="fullWidth"
                    value={value}
                >
                    {tabContent.map((c,i) => (
                        <Tab
                            key={i}
                            label={c.label}
                            onClick={() => { this.setState({ value: i }) }}    
                        />
                    ))}
                </Tabs>
                <React.Fragment>
                    {content}
                </React.Fragment>
            </React.Fragment>
        )
    }
}