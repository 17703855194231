import React from 'react'

import Paper from '@material-ui/core/Paper'
import moment from 'moment-timezone'
import { Chart, Bar } from 'react-chartjs-2'
import { red, amber, lightGreen } from '@material-ui/core/colors'
import { AppStyles } from '../theme'
// import { getMmsi } from './Report'
import { getMmsi } from '../core/reportUtils'

const reverse = require('lodash.reverse')

const red900 = red[900]
const amber500 = amber[500]
const lightGreen500 = lightGreen[500]

Chart.defaults.global.legend.display = false

Chart.defaults.global.elements.rectangle.borderWidth = 1
Chart.defaults.global.elements.rectangle.borderColor = 'rgba(64,64,64,0.9)'
Chart.defaults.global.elements.rectangle.backgroundColor = 'rgba(64,64,64,0.45)'

// Chart.defaults.global.animation = false
Chart.defaults.global.animation.duration = 200
Chart.defaults.global.animation.easing = 'easeOutElastic'
Chart.defaults.global.scaleBeginAtZero = true
Chart.defaults.global.scaleStartValue = 0

const flagColors = { red: red900, yellow: amber500, green: lightGreen500 };

function formatDate(datetime) {
  const date = moment.tz(datetime.toISOString(), "Europe/Helsinki").format()
  const year = date.substr(0, 4)
  const month = date.substr(5, 2)
  const day = date.substr(8, 2)
  const time = date.substr(11, 5)
  const res = `${day}.${month}.${year} ${time}`
  return res
}

const ChartValue = function (value, metadata) {
  this.value = value
  this.metadata = metadata
}

ChartValue.prototype.toString = function () {
  return this.value
}

export default class extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      width: 300,
      tooltip: {},
      interval: null,
    }

    this.resize = () => {
      if (this.refs.chart) {
        const w = window.innerWidth
        this.setState({ width: w > 1240 ? w - 240 : w - 30 })
        // this.refs.chart.updater.enqueueForceUpdate()
        // this.refs.chart.update()
      }
    }
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
    const interval = setInterval(this.resize, 10000)
    this.setState({ interval })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    clearInterval(this.state.interval)
  }

  render() {
    const getReportData = () => {
      let reportData = this.props.reports.data.filter(n => !n.fscUndetermined).slice()
      if (reportData.length >= 2) {
        const t1 = new Date(reportData[0].time)
        const t2 = new Date(reportData[1].time)
        if (t1 > t2) {
          reverse(reportData)
        }
      }
      return reportData
    }

    let reportData = getReportData()

    let timespan = 0

    let data
    if (reportData.length > 0) {
      const labels = []
      if (reportData.length >= 2) {
        const t1 = new Date(reportData[0].time)
        const t2 = new Date(reportData[1].time)
        const tx = new Date(reportData[reportData.length - 1].time)
        if (t2 > t1) {
          timespan = (tx.getTime() - t1.getTime()) / 1000 / 60 / 60 / 24
        } else {
          timespan = (t1.getTime() - tx.getTime()) / 1000 / 60 / 60 / 24
        }
      }
      reportData.forEach(n => {
        const date = moment.tz(n.time, "Europe/Helsinki")
        labels.push(date)
      })
      const isRed = x => x.fsc > 0.05 && x.fsc - x.uncertaintyV2 / 1000 > 0.1 && x.reliabilityCategory === 'Good';
      const isYellow = x => !isRed(x) && x.fsc > 0.05 && x.fsc + x.uncertaintyV2 / 1000 > 0.1 && (x.reliabilityCategory === 'Fair' || x.reliabilityCategory === 'Good');

      data = {
        labels: labels,
        datasets: [
          {
            label: "0",
            backgroundColor: flagColors.red,
            source: reportData.map(n => isRed(n) ? n : null),
            data: reportData.map(n => isRed(n) ? n.fsc : null),
            timespan: timespan,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            barThickness: 10,
          },
          {
            label: "1",
            backgroundColor: flagColors.yellow,
            source: reportData.map(n => isYellow(n) ? n : null),
            data: reportData.map(n => isYellow(n) ? n.fsc : null),
            timespan: timespan,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            barThickness: 10,
          },
          {
            label: "2",
            backgroundColor: flagColors.green,
            source: reportData.map(n => !isRed(n) && !isYellow(n) ? n : null),
            data: reportData.map(n => !isRed(n) && !isYellow(n) ? n.fsc : null),
            timespan: timespan,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            barThickness: 10,
          },
        ],
      }
    } else {
      data = {
        labels: [],
        datasets: [
          {
            label: "0",
            backgroundColor: 'rgba(255,0,0,0.5)',
            source: [],
            data: [],
            timespan: 0,
          },
          {
            label: "1",
            backgroundColor: 'rgba(0,0,0,0.5)',
            source: [],
            data: [],
            timespan: 0,
          },
        ],
      }
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        xAxes: [{
          type: 'time',
          ticks: {
            fontSize: 10,
            minRotation: 20,
            maxRotation: 20,
          },
          time: {
            // unit: 'hour',
            // unitStepSize: 1,
            displayFormats: {
              // 'millisecond': 'SSS [ms]',
              // 'second': 'HH:mm:ss',
              'minute': 'MMM D, HH:mm',
              'hour': 'MMM D, HH:mm',
              'day': 'll',
              'week': 'll',
              'month': 'MMM YYYY',
              'quarter': '[Q]Q - YYYY',
              'year': 'YYYY',
            },
          },
        }],
        yAxes: [{
          type: 'linear',
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            suggestedMax: 0.3,
          },
          scaleLabel: {
            display: true,
            labelString: 'FSC [%]',
          },
        }],
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        titleFontSize: 10,
        bodyFontSize: 10,
        cornerRadius: 2,
        titleSpacing: 0,
        titleMarginBottom: 0,
        xPadding: 5,
        yPadding: 5,
        callbacks: {
          title: tooltipItems => {
            return ''
          },
          label: (tooltipItem, data) => {
            const report = data.datasets[tooltipItem.datasetIndex].source[tooltipItem.index]
            if (report) {
              const time = formatDate(new Date(report.time))
              return time + ': ' + report.station + ' ' + getMmsi(report.mmsi) + ' / ' + report.imo + ' / ' + report.name + ' FSC=' + (report.fscUndetermined || isNaN(report.fsc) ? '-.----' : report.fsc.toFixed(2)) + '% U=' + report.uncertaintyV2 + '% D=' + report.distance + 'm'
            }
            return ''
          },
        },
      },
    }

    return (
      <Paper elevation={4}
        style={{ padding: '0.714rem' }}
      >
        <div style={{
          flex: AppStyles.content.flex,
          flexGrow: AppStyles.content.flexGrow,
          flexBasis: AppStyles.content.flexBasis,
          width: AppStyles.content.width,
          maxWidth: AppStyles.content.maxWidth,
        }}>
          <Bar
            ref='chart'
            data={data}
            options={options}
            getElementAtEvent={e => {
              const report = this.refs.chart.props.data.datasets[e[0]._datasetIndex].source[e[0]._index]
              this.props.onSelect(report)
            }}
          />
        </div>
      </Paper>
    )
  }
}
