export const openDeleteDialog = () => {
  return { type: 'OPEN_MAINTENANCE_DELETE_DIALOG' }
}

export const closeDeleteDialog = () => {
  return { type: 'CLOSE_MAINTENANCE_DELETE_DIALOG' }
}

export const openBackDialog = () => {
  return { type: 'OPEN_MAINTENANCE_BACK_DIALOG' }
}

export const closeBackDialog = () => {
  return { type: 'CLOSE_MAINTENANCE_BACK_DIALOG' }
}

export const setEditRecord = record => {
  return { type: 'SET_EDIT_MAINTENANCE_RECORD', record }
}

export const setRecords = records => {
  return { type: 'SET_MAINTENANCE_RECORDS', records }
}
