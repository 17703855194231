import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Redirect, browserHistory } from 'react-router'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import feathers from '@feathersjs/client'
import rest from '@feathersjs/rest-client'
import authentication from '@feathersjs/authentication-client'
import superagent from 'superagent'

import './index.css';
import App from './app/App';
import SignIn from './signin/SignIn'
import Home from './home/Home'
import Profile from './profile/Profile'
import Maintenance from './maintenance/Maintenance'
import MaintenanceEdit from './maintenance/MaintenanceEdit'
import Admin from './admin/Admin'
import AdminUsers from './admin/AdminUsers'
import AdminUserEdit from './admin/AdminUserEdit'
import Trigger from './trigger/Trigger'
import PasswordReset from './passwordreset/PasswordReset'
import TriggerEdit from './trigger/TriggerEdit'
import Report from './report/Report'
import Samples from './samples/Samples'
import SamplesEdit from './samples/SamplesEdit'
import Help from './help/Help';

import reducers from './reducers'
import { authUser, deauthUser } from './app/AppActions'

const store = createStore(reducers)

if (process.env.NODE_ENV === "development") {
  console.log('This app is using DEVELOPMENT environment');
  console.log('REACT_APP_API_URL = ' + process.env.REACT_APP_API_URL);
  console.log('REACT_APP_OAUTH_URL = ' + process.env.REACT_APP_OAUTH_URL);
}
if (process.env.NODE_ENV === "production") {
  if (!process.env.REACT_APP_API_URL)
    console.error('REACT_APP_API_URL is not set!')
  if (!process.env.REACT_APP_OAUTH_URL)
    console.error('REACT_APP_OAUTH_URL is not set!')
}

const app = feathers()
  .configure(rest(process.env.REACT_APP_API_URL).superagent(superagent))
  .configure(authentication({ storage: window.localStorage }))

function handlePath(pathname) {
  if (pathname === '/signin' || pathname === '/passwordreset') {
    return;
  }

  //Get user from Redux state
  const user = store.getState().user;
  //If we have no user, none is logged in.
  if (!user || !user._id) {
    browserHistory.push('/signin');
    return;
  }

  try {
    if (pathname.startsWith('/auth')) {
      browserHistory.push('/')
    }
    if (user.roles) {
      if (pathname.startsWith('/maintenance') && !(user.roles.includes('technician') || user.roles.includes('superadmin'))) {
        browserHistory.push('/home')
      }
      if (pathname.startsWith('/triggers') && !(user.roles.includes('user') || user.roles.includes('superadmin'))) {
        browserHistory.push('/home')
      }
      if (pathname.startsWith('/reports') && !(user.roles.includes('user') || user.roles.includes('superadmin'))) {
        browserHistory.push('/home')
      }
      if (pathname.startsWith('/samples') && !(user.organization === 'KINE' && (user.roles.includes('user') || user.roles.includes('superadmin')))) {
        browserHistory.push('/home')
      }
      if (pathname.startsWith('/admin') && !user.roles.includes('admin') && !user.roles.includes('superadmin')) {
        browserHistory.push('/home')
      }
    }
    if (pathname === '/signin' || pathname === '/' || pathname === '') {
      // if (pathname === '/signin' || pathname === '/passwordreset' ||pathname === '/' || pathname === '') {
      // console.log("Redirectin to /home")
      browserHistory.push('/home')
    }
  }
  catch (err) {
    //Get user from Redux state
    const user = store.getState().user;
    //If we have no user, none is logged in.
    if (user && user._id) {
      store.dispatch(deauthUser());
      app.logout();
    }
    browserHistory.push('/signin');
  }
}

function enterHandler() {
  const pathname = window.location.pathname
  handlePath(pathname)
}


app.reAuthenticate()
  .then(r => {
    //Populate Redux state with user information if authenticated.
    store.dispatch(authUser(r.user))
    initRender()
  })
  .catch(err => {
    initRender()
  })

function initRender() {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="/" component={App} onEnter={enterHandler}>
          <Route path="signin" component={SignIn} />
          <Route path="passwordreset" component={PasswordReset} />
          <Route path="home" component={Home} />
          <Route path="profile" component={Profile} />
          <Route path="maintenance" component={Maintenance} />
          <Route path="maintenance/new" component={MaintenanceEdit} />
          <Route path="maintenance/:id" component={MaintenanceEdit} />
          <Route path="admin" component={Admin} />
          <Route path="admin/users" component={AdminUsers} />
          <Route path="admin/users/new" component={AdminUserEdit} />
          <Route path="admin/users/:id" component={AdminUserEdit} />
          <Route path="triggers" component={Trigger} />
          <Route path="triggers/new" component={TriggerEdit} />
          <Route path="triggers/:id" component={TriggerEdit} />
          <Route path="reports" component={Report} />
          <Route path="samples" component={Samples} />
          <Route path="samples/new" component={SamplesEdit} />
          <Route path="samples/:id" component={SamplesEdit} />
          <Route path="help" component={Help} />
          <Redirect from="*" to="/" />
        </Route>
      </Router>
    </Provider>,
    document.getElementById('root'))
}

export default app
