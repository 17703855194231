import React from 'react'
import config from '../config.json'

import moment from 'moment-timezone'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Map, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import Table from '../core/Table'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

import TabPanel from '../core/TabPanel'
import { getMmsi, getFscColor } from '../core/reportUtils'
import getShipType from '../core/getShipType'
import { typeToLabel } from '../samples/Samples'

import CreateIcon from '@material-ui/icons/Create'
import FlagIcon from '@material-ui/icons/Flag'
import StarIcon from '@material-ui/icons/Star'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import StarBorderIcon from '@material-ui/icons/StarBorder'

//////////////////////////////////////////////////////////////////////////////

import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { ships, samples } from '../api'
import { showError } from '../app/AppActions'
import { setReport } from './ReportActions'
import { setSamples, setNavigateBack, setShip } from '../samples/SampleActions'

//////////////////////////////////////////////////////////////////////////////

const FSC_FUEL_DECIMALS = 2;

function formatDateTime(datetime) {
  const date = moment.tz(datetime, "Europe/Helsinki").format()
  const year = date.substr(0, 4)
  const month = date.substr(5, 2)
  const day = date.substr(8, 2)
  const time = date.substr(11, 5)
  const res = `${day}.${month}.${year} ${time}`
  return res;
}

function formatSampleDateTime(datetime) {
  return formatDateTime(datetime).split(" ")[0]
}

const columns = [<span>Time<br />Station</span>, <span>FSC<br />Reliability<br />Uncertainty</span>, <span>Wind Speed<br />Wind Direction<br />Distance</span>]

const defaultIconSmall = L.icon({
  iconUrl: '/assets/marker-icon.png',
  iconRetinaUrl: '/assets/marker-icon.png',
  shadowUrl: '/assets/marker-shadow.png',
  iconSize: [25 * 0.5, 41 * 0.5],
  iconAnchor: [12 * 0.5, 41 * 0.5],
  popupAnchor: [1 * 0.5, -34 * 0.5],
  tooltipAnchor: [16 * 0.5, -28 * 0.5],
  shadowSize: [41 * 0.5, 41 * 0.5],
})
const defaultIcon = L.icon({
  iconUrl: '/assets/marker-icon.png',
  iconRetinaUrl: '/assets/marker-icon.png',
  shadowUrl: '/assets/marker-shadow.png',
  iconSize: [25 * 0.8, 41 * 0.8],
  iconAnchor: [12 * 0.8, 41 * 0.8],
  popupAnchor: [1 * 0.8, -34 * 0.8],
  tooltipAnchor: [16 * 0.8, -28 * 0.8],
  shadowSize: [41 * 0.8, 41 * 0.8],
})

function median(values) {
  const arr = values.slice()
  arr.sort(function (a, b) { return a - b; });
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2)
    return arr[half];
  else
    return (arr[half - 1] + arr[half]) / 2.0;
}

function percentile(vals, ptile) {
  if (vals.length === 0 || ptile == null || ptile < 0) return NaN
  // Fudge anything over 100 to 1.0
  if (ptile > 1) ptile = 1
  vals = vals.sort(function numericSort(a, b) { return a - b })
  const i = (vals.length * ptile) - 0.5
  if ((i | 0) === i) return vals[i]
  // interpolated percentile -- using Estimation method
  const int_part = i | 0
  const fract = i - int_part
  return (1 - fract) * vals[int_part] + fract * vals[Math.min(int_part + 1, vals.length - 1)]
}


function grafanaUrl(station, timeMiddle) {
  const baseUrl = config.GRAFANA_URL;
  const stationToDashboard = config.GRAFANA_STATION_TO_DASHBOARD;

  const timeBuffer = 30 * 60 * 1000;

  const timeBegin = new Date(timeMiddle).getTime() - timeBuffer;
  const timeEnd = new Date(timeMiddle).getTime() + timeBuffer;

  return (
    <a
      href={`${baseUrl}/d/${stationToDashboard[station.toLowerCase()]}/?from=${timeBegin}&to=${timeEnd}`}
      target="_target"
    >
      to Grafana
    </a>
  );
}

const replaceAll = function (target, search, replacement) {
  return target.replace(new RegExp(search, 'g'), replacement)
};

const ReportDialog = ({ data, history, samples, onClose, onSelect, isKine, onToNavigateSamples, onEditSample, classes }) => {
  let title, content, reliability, bypass, fscAndUncertainty;
  let historyContent, samplesContent;

  if (data != null) {
    let notes
    if (data.notes && data.notes.length > 0) {
      var hexNotes = Buffer.from(data.notes).toString('hex')
      hexNotes = replaceAll(hexNotes, 'efbfbd', '646567')
      var dataNotes = Buffer.from(hexNotes, 'hex').toString('utf8')
      notes = <span><br />QA: <small style={{ wordWrap: 'break-word' }}>{dataNotes}</small></span>
    }
    let historyMarkers;
    if (history != null) {
      const historyData = history.data.filter(n => n.time !== data.time)
      if (historyData.length > 0) {
        const historyDataFormated = historyData.map(n => {
          return [
            <span>
              {formatDateTime(new Date(n.time))}
              <br />
              {n.station}
            </span>,
            n.bypass
              ? <span style={{ lineHeight: 1.2 }}>Bypass<br />-<br />-</span>
              : n.fsc == null
                ? <span style={{ lineHeight: 1.2 }}>-<br />-<br />-</span>
                : <span style={{ lineHeight: 1.2 }}>
                  {n.flag ? <FlagIcon className={getFscColor(n.flag, classes)} /> : '--'}
                  <span> {n.fsc != null ? n.fsc.toFixed(2) : '0.00'} %</span>
                  <br />
                  {n.reliabilityCategory === 'Good'
                    ? <StarIcon className={classes.icon} />
                    : n.reliabilityCategory === 'Fair'
                      ? <StarHalfIcon className={classes.icon} />
                      : n.reliabilityCategory === 'Poor'
                        ? <StarBorderIcon className={classes.icon} />
                        : <span />}
                  <span> {n.reliabilityCategory}</span>
                  <br />
                  {n.fscUndetermined || n.uncertaintyV2 == null ? '--- %' : n.uncertaintyV2 + ' %'}
                </span>,
            <span>
              {Math.round(n.fmiWindSpeed, 1)} m/s
              <br />
              {Math.round(n.fmiWindDirection)}°
              <br />
              {Math.round(n.distance)} m
            </span>,
          ]
        })
        const onSelectHistory = i => onSelect(historyData[i])
        const fscArr = history.data.filter(n => !n.fscUndetermined && !isNaN(n.fsc) && n.fsc != null).map(n => n.fsc)
        const distArr = history.data.filter(n => !n.fscUndetermined && !isNaN(n.distance) && n.distance != null).map(n => n.distance)
        historyContent = (
          <span>
            <br />
            <strong>History (30 days)</strong>
            <br />
            <br />
            <span>FSC: </span><small>Median={median(fscArr).toFixed(2)} %, 90th-percentile={percentile(fscArr, 0.90).toFixed(2)} %</small>
            <br />
            <span>Distance: </span><small>Median={Math.round(median(distArr))} m</small>
            <br />
            <br />
            <Table columns={columns} data={historyDataFormated} page={1} total={historyData.length} limit={historyData.length} onSelect={(_, i) => onSelectHistory(i)} depth={0} hidePager />
          </span>
        )
        historyMarkers = historyData.map((n, i) => {
          return <Marker key={i} position={[n.latitude, n.longitude]} opacity={0.5} icon={defaultIconSmall} title={formatDateTime(new Date(n.time)) + ' ' + n.station + ' FSC=' + (n.fscUndetermined || isNaN(n.fsc) || n.fsc == null ? '-.----' : n.fsc.toFixed(2) + '% U=' + (n.uncertaintyV2) + '%') + ' D=' + n.distance + 'm'} />
        })
      }
    } else {
      historyContent = (
        <div>
          <CircularProgress color="primary" />
        </div>
      )
    }

    const formatSamples = (samples) => {
      let vs = [];
      if (samples && samples.length > 0) {
        samples.forEach(x => {
          let unit, color
          if (x.type === "scrubber") {
            //
            const toFsc = x.sample * 0.23 / 10
            unit = `(${toFsc.toFixed(FSC_FUEL_DECIMALS)}% FSC)`
            color = toFsc >= 0.1 ? classes.red : classes.green
          } else {
            unit = "%"
            color = x.sample >= 0.1 ? classes.red : classes.green
          }
          vs.push(
            [
              <span>
                {formatSampleDateTime(new Date(x.timestamp))}
              </span>,
              <span>
                {typeToLabel(x.type)}
              </span>,
              <span>
                <FlagIcon className={`${classes.icon} ${color} `} />
                <span> {x.sample.toFixed(FSC_FUEL_DECIMALS)} {unit}</span>
              </span>,
              <span>
                <CreateIcon onClick={() => onEditSample(data, x)} />
              </span>
            ]
          )
        });
      }
      return vs;
    };
    const processedSamples = formatSamples(samples);

    const redirectButton = (
      <React.Fragment>
        <br />
        <Button variant="contained" color="primary" startIcon={<FlagIcon />} onClick={() => { onToNavigateSamples(data) }} size="small">Go to samples</Button>
        <br />
      </React.Fragment>
    )

    samplesContent = (
      <div style={{ width: '100%' }} >
        {processedSamples.length > 0 ?
          <div style={{ margin: "16px" }}>
            <Table columns={[<span>Time</span>, <span>Type</span>, <span>Samples</span>, <span></span>]} data={processedSamples}
              total={processedSamples.length} limit={processedSamples.length} hidePager />
            {redirectButton}
          </div>
          :
          <div style={{ margin: "16px" }}>
            <Paper >
              <div style={{ padding: "16px" }}>
                No reported fuel samples or recorded scrubber ratios.
            </div>
            </Paper>
            {redirectButton}
          </div>
        }
      </div>
    );

    title = <div>{formatDateTime(new Date(data.time))} <small>{data.station}</small></div>
    reliability =
      <span>
        <br />
        Reliability: <small>
          {data.reliabilityCategory === 'Good'
            ? <StarIcon className={classes.icon} />
            : data.reliabilityCategory === 'Fair'
              ? <StarHalfIcon className={classes.icon} />
              : data.reliabilityCategory === 'Poor'
                ? <StarBorderIcon className={classes.icon} />
                : <span />}
          <span> {data.reliabilityCategory}</span>
        </small>
      </span>
    if (data.fsc != null) {
      fscAndUncertainty =
        <span>
          <br />
          <span>FSC:</span> <small>
            {data.flag ? <FlagIcon className={getFscColor(data.flag, classes)} /> : '--'}
            <span> {data.fsc != null ? data.fsc.toFixed(2) : '0.00'} %</span>
          </small>
          {reliability}
          <br />
          <span>Uncertainty:</span> <small>{data.fscUndetermined || data.uncertaintyV2 == null ? '---' : data.uncertaintyV2} %</small>
        </span>
    }
    bypass = <span><br />Bypass</span>
    content = (
      <div className={classes.dialogContainer}>
        <div className={classes.columnContainer}>
          <div className={classes.splitColumnContainer}>
            <span>MMSI:</span> <small>{getMmsi(data.mmsi)}</small>
            <br />
            <span>IMO:</span> <small>{data.imo == null ? '-------' : data.imo}</small>
            <br />
            <span>Name:</span> <small>{data.name}</small>
            {data.bypass ? <span /> : fscAndUncertainty}
            {data.bypass ? bypass : <span />}
            <br />
            <span>Distance:</span> <small>{Math.round(data.distance)} m</small>
          </div>
          <div className={classes.splitColumnContainer}>
            <span>Destination:</span> <small>{data.destination || '-------'}</small>
            <br />
            <span>ETA:</span> <small>{data.etaDate != null && data.etaDate.length >= 11 ? data.etaDate.substr(0, 11) : '----- --:--'}</small>
            <br />
            <span>Nationality:</span> <small>{data.nationality || '--'}</small>
            <br />
            <span>Type:</span> <small>{data.shipType ? getShipType(data.shipType).replace('HSC', 'High Speed Craft') : 'Unknown'}</small>
            <br />
            <span>Length:</span> <small>{data.length || '--'} m</small>
            <br />
            <span>Beam:</span> <small>{data.beam || '--'} m</small>
            {isKine ? <div><br /><span>Reliability info:</span> <small>{data.reliabilityExplanation}</small></div> : null}
            {isKine ? <div><br />{grafanaUrl(data.station, data.time)}</div> : null}
          </div>
        </div>
        <div className={classes.columnContainer}>
          <div className={classes.splitColumnContainer}>
            <br />
            <span>Wind:</span> <small>{Math.round(data.fmiWindSpeed)} m/s ({Math.round(data.fmiWindDirection)}°)</small>
            <br />
            <span>Humidity:</span> <small>{data.relativeHumidity ? Math.round(data.relativeHumidity) : '--'} %</small>
          </div>
          <div className={classes.splitColumnContainer}>
            <br />
            <span>Temperature:</span> <small>{data.airTemperature ? Math.round(data.airTemperature) : '--'}°C</small>
            <br />
            <span>Pressure:</span> <small>{data.ambientPressure ? Math.round(data.ambientPressure) : '----.--'} hPa</small>
          </div>
        </div>
        <div className={classes.columnContainer}>
          <div className={classes.splitColumnContainer}>
            <br />
            <span>Longitude:</span> <small>{data.longitude != null ? data.longitude.toFixed(6) : ''}°</small>
          </div>
          <div className={classes.splitColumnContainer}>
            <br />
            <span>Latitude:</span> <small>{data.latitude != null ? data.latitude.toFixed(6) : ''}°</small>
          </div>
        </div>
        <span>&nbsp;</span>
        <Map
          center={[data.latitude, data.longitude]}
          zoom={data.station === 'Mobile' ? 9 : 13}
          style={{ width: '100%', height: '12rem' }}
          attributionControl={false} zoomControl={false} maxBounds={[
            [data.latitude, data.longitude],
            [data.latitude, data.longitude],
          ]}>
          <TileLayer
            url='//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            position={[data.latitude, data.longitude]}
            icon={defaultIcon}
            riseOnHover={true}
            title={formatDateTime(new Date(data.time)) + ' ' + data.station + ' FSC=' + data.fsc + '% U=' + (data.uncertaintyV2 != null ? data.uncertaintyV2 : data.uncertainty) + '% D=' + data.distance + 'm'}
            opacity={1}
          />
          {historyMarkers}
        </Map>
        {notes}
        <TabPanel
          tabContent={[
            { label: "History", content: historyContent },
            { label: "Samples", content: samplesContent }
          ]}
        />

      </div>
    )
  }
  if (content === undefined) {
    return (
      <div />
    )
  }

  return (
    <Dialog open={data != null} onClose={onClose} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

////////////////////////////////////////////////////

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToNavigateSamples: ship => {
      const mmsi = ship.mmsi;
      browserHistory.push('/samples')
      ships.list({ mmsi: mmsi })
        .then(shipResult => {
          const newShip = shipResult.data[0]
          dispatch(setShip(newShip))
          samples.list(mmsi)
            .then(sampleResult => {
              dispatch(setSamples(sampleResult.data))
              dispatch(setNavigateBack({ title: "Back To Reports", url: '/reports' }))
            })
            .catch(err => dispatch(showError(err.message)))
        })
        .catch(err => dispatch(showError(err.message)))
    },
    onSetReport: () => {
      dispatch(setReport(null))
    }
  }
}

const styles = theme => ({
  icon: theme.icons.reportsIcon,
  red: theme.icons.red,
  yellow: theme.icons.yellow,
  green: theme.icons.green,
  dialogContainer: theme.containers.reportDialog,
  columnContainer: theme.containers.column,
  splitColumnContainer: theme.containers.splitColumnContainer
})

////////////////////////////////////////////////////////

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportDialog))