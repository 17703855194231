import React from 'react'

import TrackChangesIcon from '@material-ui/icons/TrackChanges'

import { getTriggerType } from './Trigger'
import Filter from '../core/Filter'
import CollapsePaper from '../core/CollapsePaper'
import transformKey from '../core/transformKey'
import { $lt, $gt, $in, $nin } from '../core/Filter/FilterOperators'

import { pick } from 'lodash';
import { connect } from 'react-redux'


const stations = ['Sniffer-Buoy', 'Mobile', 'Turku', 'Hanko', 'Vuosaari', 'Nordvalen', 'Kilpilahti', 'Kotka', 'Maarianhamina', 'Helsinki']
const nationalities = ['AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'HR', 'CU', 'CW', 'CY', 'CZ', 'CI', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RO', 'RU', 'RW', 'RE', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW', 'AX'].sort()
const types = ['Anti-Pollution', 'Cargo', 'Dive Vessel', 'Dredger', 'Fishing', 'HSC', 'Law Enforcement', 'Local Vessel', 'Medical Transport', 'Military Ops', 'Other', 'Passenger', 'Pilot Vessel', 'Port Tender', 'Pleasure Craft', 'Sailing Vessel', 'Search and Rescue', 'Special Craft', 'Tanker', 'Tug', 'Unspecified', 'Wing In Groud'].sort()

const filterFields = [
  { key: 'station', type: 'string', values: stations, onValidate: v => stations.includes(v) },
  { key: 'fsc', type: 'number', unit: '%', values: [0.01, 0.02, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2], onValidate: v => v !== '' && v >= 0 && v <= 100 },
  // { key: 'fscDeviation', type: 'number', unit: '%', values: [0.01,0.02,0.05,0.1,0.15,0.2,0.3,0.5,1,1.5], onValidate: v => v !== '' && v >= 0 && v <= 100 },
  { key: 'uncertaintyV2', type: 'number', unit: '%', values: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100], onValidate: v => v !== '' && v >= 0 },
  // { key: 'destination', type: 'string', values: destinations, onValidate: v => destinations.includes(v) },
  { key: 'nationality', type: 'string', values: nationalities, onValidate: v => nationalities.includes(v) },
  { key: 'shipType', type: 'string', values: types, onValidate: v => types.includes(v) },
  { key: 'length', type: 'number', unit: 'm', values: [5, 10, 15, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200], onValidate: v => v !== '' && v >= 0 },
  { key: 'beam', type: 'number', unit: 'm', values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50], onValidate: v => v !== '' && v >= 0 },
  { key: 'windSpeed', type: 'number', unit: 'm/s', values: [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20], onValidate: v => v !== '' && v >= 0 && v <= 50 },
  { key: 'windDirection', type: 'number', unit: '°', values: [20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 320, 340], onValidate: v => v !== '' && v >= 0 && v < 360 },
  { key: 'distance', type: 'number', unit: 'm', values: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000], onValidate: v => v !== '' && v >= 0 },
  { key: 'reliability', type: 'string', unit: '', values: ['Good', 'Fair', 'Poor'], onValidate: v => v !== '' },
  { key: 'flag', type: 'string', unit: '', values: ['Red', 'Orange', 'Green'], onValidate: v => v !== '' },
]

function parseQueryFilters(query) {
  const filters = []

  Object.keys(query).filter(key => !key.startsWith('$')).forEach(key => {
    const item = query[key]
    const field = filterFields.filter(n => n.key === key)[0]
    if (item.$in) {
      item.$in.forEach(n => filters.push({ field, operator: $in, value: n }))
    }
    if (item.$nin) {
      item.$nin.forEach(n => filters.push({ field, operator: $nin, value: n }))
    }
    if (item.$lt) {
      let value = item.$lt
      if (field.type === 'date') {
        value = new Date(value / 1000 / 1000).toISOString().substr(0, 10)
      }
      filters.push({ field, operator: $lt, value })
    }
    if (item.$gt) {
      let value = item.$gt
      if (field.type === 'date') {
        value = new Date(value / 1000 / 1000).toISOString().substr(0, 10)
      }
      filters.push({ field, operator: $gt, value })
    }
  })
  return filters
}

function formatQuery(query) {
  const filters = Object.keys(query).filter(key => !key.startsWith('$')).map(key => {
    const item = query[key]

    let parts = []

    if (item.$in) {
      parts.push(<span>{(item.$in.length > 1 ? <span>in </span> : <span />)}{item.$in.join(' / ')}</span>)
    }
    if (item.$nin) {
      parts.push(<span>{(item.$nin.length > 1 ? <span>not in </span> : <span>not </span>)}{item.$nin.join(' / ')}</span>)
    }
    if (item.$lt) {
      parts.push(<span><span>less than </span>{item.$lt}</span>)
    }
    if (item.$gt) {
      parts.push(<span><span>greater than </span>{item.$gt}</span>)
    }

    return <span>{transformKey(key)}<span> is </span>{parts.length > 1 ? parts.reduce((x, y) => <span>{x}<span> and </span>{y}</span>) : parts[0]}</span>
  })
  return <span>{filters.length === 0 ? '...' : filters.reduce((x, y) => <span>{x}<span> and </span>{y}</span>)}</span>
}

const transformFilters = filters => {
  const q = {}
  filters.forEach(f => {
    let { field, operator, value } = f

    if (q[f.field.key] == null) {
      q[f.field.key] = {}
    }

    if (field.type === 'date') {
      value = new Date(value).getTime() * 1000 * 1000
    }

    if (operator.key === '$in' || operator.key === '$nin') {
      if (!q[f.field.key][operator.key]) {
        q[f.field.key][operator.key] = [value]
      } else {
        q[f.field.key][operator.key].push(value)
      }
    } else {
      q[f.field.key][operator.key] = value
    }
  })
  return q
}

class ThenFilterBlock extends React.Component {

  render() {
    const { onChange, trigger } = this.props
    var { filter } = this.props

    const type = getTriggerType(trigger)
    const condition = trigger.condition
    const conditionType = Object.keys(condition).filter(n => n !== 'enabled' && n !== 'includeDuplicates')[0]

    // 'Store' mmsi filter
    const mmsiFilter = Object.keys(filter).filter(key => key === "mmsi").map(key => filter[key])
    // Exclude mmsi filter from the filters
    filter = pick(filter, Object.keys(filter).filter(key => key !== "mmsi"))
    const filters = parseQueryFilters(filter)

    const icon = <TrackChangesIcon />
    const text = <span><span>{type === 'match' ? 'Filter ' : 'Then filter '}</span>{formatQuery(filter)}</span>
    const body =
      <Filter
        fields={filterFields}
        filters={filters}
        onChange={(filters) => {
          var query = transformFilters(filters)
          if (mmsiFilter.length) {
            query["mmsi"] = mmsiFilter[0]
          }
          onChange(query)
        }}
      />

    return (
      <CollapsePaper
        icon={icon}
        text={text}
        actAsExpander={conditionType !== 'match'}
      >
        {body}
      </CollapsePaper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filter: state.trigger.editTrigger.action.filter || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ThenFilterBlock)
