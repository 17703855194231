const errorState = { text: '' }

export default (state = errorState, action) => {
  switch (action.type) {
    case 'SHOW_ERROR':
      return { text: action.text || '' }
    case 'HIDE_ERROR':
      return { text: '' }
    default:
      return state
  }
}
