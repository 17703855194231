import app from '.'
import base64 from './core/base64'
import superagent from 'superagent'

function save(filename, data, format) {
  const blob = new Blob([data], { type: format })
  saveBlob(filename, blob)
}

function saveBlob(filename, blob) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    elem.target = '_blank'
    document.body.appendChild(elem)
    setTimeout(() => {
      elem.click();
      document.body.removeChild(elem);
    }, 500);
  }
}

function saveFile(filename, href) {
  const elem = window.document.createElement('a')
  elem.href = href
  elem.download = filename
  elem.target = '_blank'
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

let isChrome = navigator.userAgent.indexOf('Chrome') > -1
let isSafari = navigator.userAgent.indexOf("Safari") > -1
let isOpera = navigator.userAgent.toLowerCase().indexOf("op") > -1
if (isChrome && isSafari) isSafari = false
if (isChrome && isOpera) isChrome = false


//////////////////////////////////////////////////////
///     HAX HAX FOR TIMEZONE CORRECTION      /////////
//////////////////////////////////////////////////////
const convertToFinnishTime = (time) => {
  const ms = new Date(time).valueOf() // - 2 * 60 * 60 * 1000
  return new Date(ms).toISOString()
}
const transfromTimeZones = (query) => {
  if (!query.time) {
    return query
  }

  if (query.time.$lt) query.time.$lt = convertToFinnishTime(query.time.$lt)
  if (query.time.$gt) query.time.$gt = convertToFinnishTime(query.time.$gt)
  return query;
}
const parseSearchTimes = (search) => {
  var q = [];
  const pushTime = (n, operator) => {
    n = n.toLowerCase().split("t")[0]
    const time = n.substr(n.indexOf(operator) + 1)
    var ts = []
    time.split(".").forEach(t => {
      if (t.length === 1) t = `0${t}`
      ts.push(t)
    })
    const fiTime = convertToFinnishTime(ts.reverse().join("."))
    q.push(`${operator}${fiTime}`)
  }
  search.split(" ").forEach(n => {
    if (n.startsWith(">")) pushTime(n, ">")
    else if (n.startsWith("<")) pushTime(n, "<")
    else if (n.startsWith("=")) pushTime(n, "=")
    else if (n.startsWith("time")) {
      if (n.includes(">")) pushTime(n, ">")
      else if (n.includes("<")) pushTime(n, "<")
      else if (n.includes("=")) pushTime(n, "=")
    } else {
      q.push(n)
    }
  });
  return q.join(" ")
}
//////////        END OF HAXING           ////////////
//////////////////////////////////////////////////////

const reportService = () => app.service('v1/reports')
export const reports = {
  create: report => reportService().create(report),
  list: (page, query = {}, search = '') => {
    if (query.$limit == null) {
      query.$limit = 20
    }
    if (query.$skip == null) {
      query.$skip = ((page - 1) || 0) * 20
    }
    if (search !== '') {
      query.$search = parseSearchTimes(search)
    }
    query = transfromTimeZones(query)
    return reportService().find({ query })
  },
  summary: (summary) => {
    const query = { $summary: summary }
    return reportService().find({ query })
  },
  download: (format, fields, query = {}) => {
    query.$limit = 100000
    query.$skip = 0
    query.$format = format
    query.$select = fields
    // query.$invoice = true
    // query.duplicate = false
    return reportService().find({ query })
      .then(result => {
        const filename = 'export_' + new Date().toISOString().replace(':', '-')
        switch (format) {
          case 'csv':
            if (isSafari) {
              const url = 'data:text/plain;base64,' + base64.encode(result).replace(/_/g, '=')
              window.open(url, '_self')
            } else {
              save(filename + '.csv', result, 'text/csv')
            }
            break
          case 'json':
            if (isSafari) {
              const url = 'data:application/json;base64,' + base64.encode(result).replace(/_/g, '=')
              window.open(url, '_self')
            } else {
              save(filename + '.json', result, 'application/json')
            }
            break
          case 'xml':
            result = result.replace(/"[^"]*"/g, function (match) {
              return match.replace(/\s*(<>|<->|<=>|<<|>>|<|>)\s*/g, '/')
            })
            if (isSafari) {
              const url = 'data:text/plain;base64,' + base64.encode(result).replace(/_/g, '=')
              window.open(url, '_self')
            } else {
              save(filename + '.xml', result, 'application/xml')
            }
            break
          case 'pdf':
            if (!!result) {
              const filepath = process.env.REACT_APP_API_URL + '/v1/reports/exports/' + result;
              return new Promise((resolve, reject) => {
                let counter = 0;
                function getFile() {
                  counter++;
                  if (counter > 180) return reject();
                  superagent.head(filepath)
                    .responseType('blob')
                    .end(function (err, res) {
                      if (err) return setTimeout(getFile, 5000);
                      if (isSafari) {
                        window.open(filepath, '_self');
                      } else {
                        saveFile(filename + '.pdf', filepath);
                      }
                      resolve();
                    });
                }
                getFile();
              });
            }
            else {
              throw new Error("Trying to export an empty report: please, modify query");
            }
          default:
            break;
        }
      })
  },
}

const searchService = () => app.service('v1/searches');
export const searches = {
  create: search => searchService().create(search),
  list: (userId) => {
    return searchService().find({ search: { userId: userId } });
  },
  remove: searchId => searchService().remove(searchId),
};

const passwordChangeService = () => app.service('v1/password-change')
const passwordResetRequestService = () => app.service('v1/password-requestreset')
const passwordResetRequestAdminService = () => app.service('v1/password-requestreset-admin')
const passwordResetService = () => app.service('v1/password-reset')
const userService = () => app.service('v1/users')
export const users = {
  list: (page, user) => {
    // const user = app.get('user')
    const query = {
      $limit: 25,
      $skip: ((page - 1) || 0) * 25,
    }
    // console.log('API user', user);
    // if (user && user.roles && !user.roles.includes('superadmin')) {
    //   query.organization = user.organization
    // }    
    return userService().find({ query: query })
  },
  query: query => {
    return userService().find({ query: query });
  },
  get: id => {
    if (!id) {
      return app.get('authentication');
    }
    return userService().get(id)
  },
  create: user => userService().create(user),
  patch: (id, user) => userService().patch(id, user),
  remove: id => userService().remove(id),
  authenticate: () => app.authentication.reAuthenticate(true),
  signIn: (email, password) => app.authenticate({ strategy: 'local', email, password }),
  signInGoogle: () => {
    window.location.href = process.env.REACT_APP_OAUTH_URL + '/google';
    return Promise.resolve(null)
  },
  signOut: () => app.logout(),
  changePassword: (oldPassword, newPassword) => passwordChangeService().create({ oldPassword, newPassword }),
  getResetRequest: (id) => passwordResetRequestService().get(id),
  resetPassword: (id, token, password) => passwordResetService().create({ id, token, password }),
  requestPasswordReset: email => passwordResetRequestService().create({ email }),
  requestPasswordResetAdmin: email => passwordResetRequestAdminService().create({ email }),
}

const triggerService = () => app.service('v1/triggers')
export const triggers = {
  list: page => {
    const query = {
      $limit: 1000,
      $skip: ((page - 1) || 0) * 1000,
    }
    return triggerService().find({ query: query })
  },
  query: query => {
    return triggerService().find({ query: query });
  },
  get: id => {
    return triggerService().get(id)
  },
  create: trigger => triggerService().create(trigger),
  patch: (id, trigger) => triggerService().patch(id, trigger),
  remove: id => triggerService().remove(id),
}

const maintenanceService = () => app.service('v1/maintenance')
export const maintenance = {
  list: page => {
    const query = {
      $limit: 10000,
      $skip: ((page - 1) || 0) * 10000,
    }
    return maintenanceService().find({ query: query })
  },
  get: id => {
    return maintenanceService().get(id)
  },
  create: record => maintenanceService().create(record),
  patch: (id, record) => maintenanceService().patch(id, record),
  remove: id => maintenanceService().remove(id),
}

const shipsService = () => app.service('v1/ships')
export const ships = {
  list: (query, include) => {
    if (include)
      query.include = 1
    return shipsService().find({ query: query })
  },
  distinct: (value, type) => {
    const query = { $distinct: { type: type, value: value } }
    return shipsService().find({ query: query })
  }
}

const sampleService = () => app.service('v1/samples')
export const samples = {
  list: (mmsi, query = { $sort: { timestamp: -1 } }) => {
    query.shipMmsi = mmsi;
    return sampleService().find({ query: query })
  },
  get: id => sampleService().get(id),
  create: sample => {
    return sampleService().create(sample)
  },
  patch: (id, sample) => {
    return sampleService().patch(id, sample)
  },
  remove: id => {
    return sampleService().remove(id)
  },
}