const adminState = {
  deleteDialogOpen: false,
  backDialogOpen: false,
  editUser: {},
  users: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
}

export default (state = adminState, action) => {
  switch (action.type) {
    case 'OPEN_DELETE_DIALOG':
      return Object.assign({}, state, { deleteDialogOpen: true })
    case 'CLOSE_DELETE_DIALOG':
      return Object.assign({}, state, { deleteDialogOpen: false })
    case 'OPEN_BACK_DIALOG':
      return Object.assign({}, state, { backDialogOpen: true })
    case 'CLOSE_BACK_DIALOG':
      return Object.assign({}, state, { backDialogOpen: false })
    case 'SET_EDIT_USER':
    if (action.user.google) {
      return Object.assign({}, state, { editUser: Object.assign({
        _id: action.user._id,
        oauth: true,
        email: action.user.email,
        roles: action.user.roles,
        name: action.user.google.displayName,
        organization: action.user.organization,
        triggers: action.user.triggers,
      })})
    } else {
      return Object.assign({}, state, { editUser: Object.assign({}, action.user) })
    }
    case 'SET_USERS':
      return Object.assign({}, state, { users: action.users })
    default:
      return state
  } 
}
