const replaceAll = function(target, search, replacement) {
  return target.split(search).join(replacement);
}

function encode(str) {
  if (typeof str !== 'string') {
    str = '{}'
  }
  return replaceAll(btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode('0x' + p1)
  })), '=', '_')
}

function decode(str) {
  if (typeof str !== 'string') {
    return '{}'
  }
  return decodeURIComponent(Array.prototype.map.call(atob(replaceAll(str, '_', '=')), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const base64 = { encode, decode }

export default base64
