import React from 'react'

import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

class CollapsePaper extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: this.props.open ? this.props.open : true
        }
    }
    // {headerIcon}{header}{icon}
    render() {
        const { open } = this.state
        const { icon, text, children, classes } = this.props
        const actAsExpander = this.props.actAsExpander ? this.props.actAsExpander : true

        const collapseIcon = actAsExpander ? (open ? <ExpandLessIcon /> : <ExpandMoreIcon />) : null

        const headerComponent =
            <ListItem onClick={() => {
                if (actAsExpander) this.setState({ open: !open })
                }}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                <ListItemIcon className={classes.collapseIcon}>{collapseIcon}</ListItemIcon>
            </ListItem>
        return (
            <div>
                <Paper elevation={4}>
                    <List>
                        {headerComponent}
                        <Collapse in={open} unmountOnExit>
                            <List>
                                <div className={classes.div}>
                                    {children}
                                </div>
                            </List>
                        </Collapse>
                    </List>
                </Paper>
            </div>
        )
    }
}

const styles = theme => ({
    div: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    collapseIcon: {
        marginRight: theme.spacing(2)*-1
    }
})

export default withStyles(styles)(CollapsePaper)